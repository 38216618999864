import styled from "styled-components";
// import { SwiperSlide } from "swiper/react";

// Breakpoints
const BREAKPOINTS = {
	mobile: "768px",
	tablet: "996px",
	desktop: "1400px",
	largeDesktop: "1600px",
};

// Design tokens
const COLORS = {
	primary: "#121212",
	white: "#ffffff",
	pink: "#d3b2e8",
	gray: "#eae9eb",
	blue: "#8aaeff",
};

export const Section = styled.section`
	flex: 1;
	min-height: 600px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	max-width: ${BREAKPOINTS.desktop};
	margin: 0 auto;

	@media (min-width: ${BREAKPOINTS.mobile}) and (max-width: ${BREAKPOINTS.tablet}) {
		width: calc(100% + 50px);
		margin-left: -25px;
	}

	@media (max-width: ${BREAKPOINTS.tablet}) {
		flex-direction: column;
		align-items: center;
		gap: 12px;
	}
`;

export const SectionColumn = styled.div`
	height: 100%;
	display: flex;

	&:first-child {
		width: 63.3572%;
	}

	&:nth-child(2) {
		position: relative;
		width: 100%;
		max-width: 512px;
		//max-height: 750px;
		overflow: hidden;

		& > div {
			display: flex;
			position: relative;
			gap: calc(14px + 0.875vw);
			width: 100%;
		}

		& > div:after {
			content: "";
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 1),
				transparent 20%,
				transparent 80%,
				rgba(255, 255, 255, 1)
			);
			z-index: 3;
			pointer-events: none;
		}
	}

	//	@media (max-width: 1500px) {
	//		&:nth-child(2) {
	//			width: 50%;
	//		}
	//	}
	//
	//	@media (max-width: 1400px) {
	//		&:nth-child(2) {
	//			width: 60%;
	//		}
	//	}
	//
	//	@media (max-width: 1300px) {
	//		&:nth-child(2) {
	//			width: 70%;
	//		}
	//	}
	//
	//	@media (max-width: 1200px) {
	//		&:nth-child(2) {
	//			width: 100%;
	//		}
	//	}
	//
	//	@media (max-width: 768px) {
	//		&:first-child {
	//			width: 100%;
	//		}
	//	}
	//
	//@media (min-width: 1600px) {
	//	&:nth-child(2) {
	//		& > div {
	//			gap: 28px;
	//		}
	//	}
	//}

	@media (max-width: ${BREAKPOINTS.tablet}) {
		&:first-child {
			width: 100%;
		}
	}

	@media (max-width: 768px) {
		&:nth-child(2) {
			max-width: 360px;
		}
	}

	@media (min-width: ${BREAKPOINTS.largeDesktop}) {
		&:nth-child(2) > div {
			gap: 28px;
		}
	}
`;

export const SectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 77.2727%;
	margin-top: 160px;

	p {
		font-size: calc(12px + 0.75vw);
		font-weight: 400;
		line-height: calc(16px + 0.875vw);
		letter-spacing: -0.011em;
		text-align: left;
		color: ${COLORS.primary};
	}

	button {
		cursor: pointer;
		padding: calc(9px + 0.563vw) calc(25px + 1.563vw);
		gap: calc(5px + 0.313vw);
		border-radius: 110px;
		background: ${COLORS.primary};

		font-size: calc(12px + 0.75vw);
		font-weight: 600;
		line-height: calc(16px + 1.25vw);
		letter-spacing: -0.011em;
		text-align: left;
		color: ${COLORS.white};
		margin-top: 26px;
	}

	//@media (max-width: 1400px) {
	//	margin-top: 0;
	//	justify-content: center;
	//}
	//
	//@media (max-width: 768px) {
	//	width: 100%;
	//	align-items: center;
	//}
	//
	//@media (min-width: 1600px) {
	//	p {
	//		font-size: 24px;
	//		line-height: 30px;
	//	}
	//
	//	button {
	//		padding: 18px 50px 18px 50px;
	//		gap: 10px;
	//
	//		font-size: 24px;
	//		line-height: 36px;
	//	}
	//}

	@media (max-width: ${BREAKPOINTS.desktop}) {
		margin-top: 0;
		justify-content: center;
	}

	@media (max-width: ${BREAKPOINTS.tablet}) {
		width: 100%;
		align-items: center;
	}
`;

export const CelebritySwiperContainer = styled.div`
	pointer-events: none;
`;

export const SectionHeading = styled.h1`
	display: flex;
	flex-direction: column;
`;

export const TextRow = styled.span`
	font-size: calc(24px + 2.5vw);
	font-weight: 500;
	line-height: calc(46px + 1.966vw);
	letter-spacing: -0.011em;
	text-align: left;
	color: ${COLORS.primary};
	text-transform: uppercase;

	span:first-child {
		font-style: italic;
	}

	@media (max-width: 480px) {
		font-size: 32px;
		line-height: 38.73px;
		letter-spacing: -1.1px;
	}

	@media (min-width: 1600px) {
		font-size: 64px;
		line-height: 77.45px;
	}
`;
