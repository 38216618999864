import { Section, SectionHeading, TextRow, QuestionsWrapper, QuestionContainer, QuestionTitle } from "./styles";
import { useMobileDetector, useTabletDetector } from "../../../../utils/customHooks";

const questions = [
	{
		title: "What is Fameeo?",
		content:
			"Fameeo is a platform where you can request personalized video messages from your " +
			"favorite celebrities. Whether it's for a birthday, special occasion, or just a fun " +
			"surprise, Fameeo makes it easy to connect with stars in a unique and personal way.",
	},
	{
		title: "How does Fameeo work?",
		content:
			"Simply browse through our list of celebrities, select the one you want, and submit " +
			"your request with details about the message you'd like. Once the celebrity records " +
			"your video, you’ll receive it via email.",
	},
	{
		title: "How do I request a personalized video?",
		content:
			"To request a video, follow these steps:\n" +
			"• Search for your favorite celebrity on Fameeo.\n" +
			"• Click on their profile and select “Request Video.”\n" +
			"• Fill in the details, including the message you’d like them to say.\n" +
			"• Complete the payment, and your request will be sent to the celebrity.\n" +
			"• Once your video is ready, you’ll receive a notification to download it.",
	},
	{
		title: "Can I ask for a specific message in my video?",
		content:
			"Yes! When placing your request, you can include specific details about what you " +
			"want the celebrity to say. However, all requests must follow Fameeo’s content guidelines, " +
			"and celebrities have the right to decline requests that don’t align with their preferences.",
	},
	{
		title: "How will I receive my video?",
		content: "Once your video is ready, you will get a notification and an email with a download link.",
	},
];

const FAQ = () => {
	const isMobile = useMobileDetector();
	const isTablet = useTabletDetector();

	return (
		<Section>
			<SectionHeading>
				{isMobile || isTablet ? (
					<TextRow>Frequently Asked Questions</TextRow>
				) : (
					<>
						<TextRow>Frequently </TextRow>
						<TextRow>Asked</TextRow>
						<TextRow>Questions</TextRow>
					</>
				)}
			</SectionHeading>

			<QuestionsWrapper>
				{questions.map((question, index) => (
					<QuestionContainer key={index}>
						<QuestionTitle>{question.title}</QuestionTitle>
						<p>
							{question.content.split("\n").map((line, i) => (
								<span key={i}>
									{line}
									{i < question.content.split("\n").length - 1 && <br />}
								</span>
							))}
						</p>
					</QuestionContainer>
				))}
			</QuestionsWrapper>
		</Section>
	);
};

export default FAQ;
