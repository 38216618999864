import styled from "styled-components";

export const PhotoInput = styled.div`
	width: 100%;

	& > img {
		max-width: 368px;
		//height: 366px;
		height: auto;
		object-fit: contain;
		border-radius: 60px;
	}

	& > label {
		width: calc(27.5px + 1.719vw);
		height: calc(27.5px + 1.719vw);
		position: absolute;
		bottom: 0;
		right: 0;
		cursor: pointer;
	}

	//@media (max-width: 768px) {
	//	width: 179px;
	//	height: 179px;
	//}

	@media (min-width: 1600px) {
		& > label {
			width: 55px;
			height: 55px;
		}
	}
`;
