import styled from "styled-components";

export const PlaceholderWrapper = styled.div`
	width: 100%;
	height: ${(props) => (props.isopen ? "calc(121.5px + 7.594vw)" : "calc(175px + 10.938vw)")}; // Match CelebrityImg
	background-color: #e0e0e0; // Base gray color
	border-radius: 40px; // Match CelebrityImg
	overflow: hidden;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(135deg, #e0e0e0 25%, #d0d0d0 50%, #e0e0e0 75%); // Subtle gradient
		background-size: 200% 200%; // Larger than container for animation
		filter: blur(10px); // Blur effect
		z-index: 1;
		animation: shimmer 2s infinite linear; // Animation applied
	}

	@keyframes shimmer {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: 200% 200%; // Shifts gradient for shimmer effect
		}
	}
`;
