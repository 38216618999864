import { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { SEND_EMAIL_MUTATION } from "../queries/email";
import { SEND_NOTIFICATION_MUTATION } from "../queries/notification";

export function useMobileDetector() {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return isMobile;
}

export function useTabletDetector() {
	const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth <= 996);

	const handleResize = () => {
		setIsTablet(window.innerWidth >= 768 && window.innerWidth <= 996);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return isTablet;
}

export function useSendEmail() {
	const [sendEmailMutation] = useMutation(SEND_EMAIL_MUTATION);
	const [validationErrors, setValidationErrors] = useState({});
	const formRefs = {
		firstName: useRef(null),
		lastName: useRef(null),
		email: useRef(null),
		tel: useRef(null),
		siteURL: useRef(null),
	};

	// const isValidEmail = (email) => {
	// 	return email.match(
	// 		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	// 	);
	// };
	//
	// const isValidUrl = (url) => {
	// 	const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
	// 	return pattern.test(url);
	// };

	// const validate = (formData) => {
	// 	let newErrors = {};
	// 	if (formData.firstName && formData.firstName.split("")[0] === " ")
	// 		newErrors.firstName = "First Name is invalid";
	// 	if (formData.email && !isValidEmail(formData.email)) newErrors.email = "Email is invalid";
	// 	if (formData.siteURL && !isValidUrl(formData.siteURL)) newErrors.siteURL = "URL is invalid";
	// 	if (!formData.firstName) newErrors.firstName = "First Name is required";
	// 	if (!formData.lastName) newErrors.lastName = "Last Name is required";
	// 	if (!formData.email) newErrors.email = "Email is required";
	// 	if (!formData.tel) newErrors.tel = "Phone number is required";
	// 	if (!formData.siteURL) newErrors.siteURL = "Social media link is required";
	//
	// 	setValidationErrors(newErrors);
	// 	return Object.keys(newErrors).length === 0;
	// };

	const sendEmail = async (formData) => {
		// if (validate(formData)) {
		try {
			const { data } = await sendEmailMutation({
				variables: { input: formData },
			});
			if (data.sendEmail === "SUCCESS") {
				for (let key in formRefs) {
					if (formRefs[key].current) {
						formRefs[key].current.value = "";
					}
				}
				setValidationErrors({});
				return "SUCCESS";
			}
		} catch (error) {
			console.error("Error sending email:", error);
			setValidationErrors({ general: "Error sending email" });
			return "ERROR";
		}
		// }
	};

	return { sendEmail, validationErrors, setValidationErrors, formRefs };
}

export function useSendNotification() {
	const [sendNotification] = useMutation(SEND_NOTIFICATION_MUTATION);
	const [error, setError] = useState(null);

	const send = async ({ userType, userId, type, message }) => {
		try {
			console.log("TEST");
			const { data } = await sendNotification({
				variables: { userType, userId, type, message },
			});
			if (data?.sendNotification) {
				setError(null);
				return "SUCCESS";
			}
		} catch (err) {
			setError(err.message);
			console.error("Error sending notification:", err);
			return "ERROR";
		}
	};

	return { send, error };
}
