import styled from "styled-components";

export const EditContainer = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	margin-top: 74px;

	@media (min-width: 996px) and (max-width: 1200px) {
		justify-content: space-evenly;
	}

	@media (max-width: 996px) {
		flex-direction: column;
		gap: 52px;
		margin-top: 52px;
	}
`;

export const EditSidebar = styled.div`
	position: absolute;
	left: 0;
	top: 13px;
	display: flex;
	flex-direction: column;
	gap: calc(10px + 0.625vw);

	@media (max-width: 1200px) {
		position: relative;
		top: 0;
	}

	@media (min-width: 1600px) {
		gap: 20px;
	}
`;

export const SidebarButton = styled.button`
	background: none;
	border: none;
	font-size: calc(12px + 0.75vw);
	font-weight: 500;
	line-height: calc(14.5px + 0.909vw);
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: ${(props) => (props.current ? "#000000" : "#A4A7AE")};
	cursor: pointer;

	@media (max-width: 480px) {
		font-size: 16px;
		line-height: 19.36px;
	}

	@media (min-width: 1600px) {
		font-size: 24px;
		line-height: 29.05px;
	}
`;

export const EditForm = styled.form`
	display: flex;
	flex-direction: column;
	gap: calc(14.5px + 0.906vw);
	width: 100%;
	max-width: 606px;

	& > div {
		display: flex;
		flex-direction: column;
		gap: calc(8px + 0.5vw);

		&:last-child {
			width: 100%;
			flex-direction: row;
			justify-content: flex-end;
			margin-top: ${(props) => (props.current === "profile" ? "45px" : "-9px")};

			button {
				padding: calc(5px + 0.344vw) calc(19px + 1.219vw);
				background-color: #7f56d9;
				border-radius: 80px;
				border: none;
				font-size: calc(9px + 0.563vw);
				font-weight: 600;
				line-height: calc(13.5px + 0.844vw);
				letter-spacing: -0.011em;
				text-align: left;
				text-underline-position: from-font;
				text-decoration-skip-ink: none;
				color: #ffffff;
				cursor: pointer;
			}
		}

		label {
			font-size: calc(12px + 0.75vw);
			font-weight: 500;
			line-height: calc(19.5px + 1.222vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
		}

		input {
			width: 100%;
			padding: calc(5px + 0.313vw) calc(8px + 0.5vw);
			box-sizing: border-box;
			border: 1px solid #a79dad;
			border-radius: 80px;
			color: #000000;
			font-size: calc(8px + 0.5vw);
			font-weight: 500;
			line-height: calc(9.5px + 0.616vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;

			&::placeholder {
				color: #a4a7ae;
			}
		}

		textarea {
			width: 100%;
			max-width: 100%;
			min-width: 100%;
			height: 161px;
			min-height: 161px;
			padding: calc(8px + 0.5vw);
			border: 1px solid #a79dad;
			border-radius: 20px;
			box-sizing: border-box;
			font-family: Inter, sans-serif; //
			font-size: calc(8px + 0.5vw);
			font-weight: 400;
			line-height: calc(9.5px + 0.616vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;

			&::placeholder {
				color: #a4a7ae;
			}
		}

		span {
			font-size: calc(8px + 0.5vw);
			font-weight: 500;
			line-height: calc(9.5px + 0.616vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #a4a7ae;
		}

		p {
			font-size: 12px;
			font-weight: 400;
			line-height: 14.06px;
			text-align: left;
			color: #d00306;
		}
	}

	@media (max-width: 480px) {
		gap: 24px;

		& > div {
			gap: 12px;

			&:last-child {
				button {
					padding: 12px 40px;
					font-size: 14px;
					line-height: 16.94px;
					letter-spacing: -1.1px;
				}
			}

			label {
				font-size: 16px;
				line-height: 19.36px;
			}

			input {
				padding: 16px;
				font-size: 16px;
				line-height: 19.36px;
			}

			textarea {
				padding: 16px;
				font-size: 16px;
				line-height: 19.36px;
			}

			span {
				font-size: 16px;
				line-height: 19.36px;
			}
		}
	}

	@media (max-width: 768px) {
		& > div {
			&:last-child {
				margin-top: 0;
			}
		}
	}

	@media (min-width: 1600px) {
		gap: 29px;

		& > div {
			gap: 16px;

			&:last-child {
				button {
					padding: 10.5px 38.5px;
					font-size: 18px;
					line-height: 27px;
				}
			}

			label {
				font-size: 24px;
				line-height: 29.05px;
			}

			input {
				padding: 10px 16px;
				font-size: 16px;
				line-height: 19.36px;
			}

			textarea {
				padding: 16px;
				font-size: 16px;
				line-height: 19.36px;
			}

			span {
				font-size: 16px;
				line-height: 19.36px;
			}

			p {
				font-size: 12px;
				line-height: 14.06px;
			}
		}
	}
`;

export const AboutInputContainer = styled.div`
	& > div {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:nth-child(2) {
			flex-direction: column;
			align-items: flex-start;
		}

		span {
			font-size: calc(8px + 0.5vw);
			font-weight: 400;
			line-height: calc(9.5px + 0.616vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
		}

		textarea {
			border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
			max-height: 400px;
		}
	}

	@media (min-width: 1600px) {
		& > div {
			span {
				font-size: 16px;
				line-height: 19.36px;
			}
		}
	}
`;

export const NewPasswordInput = styled.div`
	flex-direction: column;
	align-items: flex-start !important;
	gap: calc(8px + 0.5vw);

	span {
		color: #a4a7ae !important;
	}

	& > div {
		display: flex;
		flex-direction: column;
	}

	@media (min-width: 1600px) {
		gap: 16px;
	}
`;

export const InputRowContainer = styled.div`
	input {
		border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
	}
`;

export const InputRow = styled.div`
	display: flex;
	gap: calc(3.5px + 0.219vw);

	button {
		padding: calc(6px + 0.375vw) calc(16px + 1vw);
		border-radius: 30px;
		background-color: transparent;
		font-size: calc(9px + 0.563vw);
		font-weight: 500;
		line-height: calc(10.5px + 0.705vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #7f56d9;
		border: 1px solid #7f56d9;
		cursor: pointer;
		white-space: nowrap;
	}

	@media (max-width: 768px) {
		button {
			padding: 16px 27.5px;
			font-size: 14px;
			line-height: 16.94px;
		}
	}

	@media (min-width: 1600px) {
		gap: 7px;

		button {
			padding: 12px 32px;
			font-size: 18px;
			line-height: 21.78px;
		}
	}
`;

export const SocialButtonContainer = styled.div`
	margin-top: 31px !important;

	button {
		border: none !important;
		background-color: #7f56d9 !important;
		color: #ffffff !important;
	}

	@media (max-width: 768px) {
		margin-top: 0 !important;
	}
`;
