import { useState } from "react";
import { useSendEmail } from "../../../../utils/customHooks";
import {
	EmailInputContainer,
	FirstNameInputContainer,
	InputRow,
	LastNameInputContainer,
	NameInputsRow,
	PartnerButton,
	PartnerForm,
	Section,
	SectionContainer,
	SocialInput,
	SocialInputContainer,
} from "./styles";
import { Checkbox, CheckboxOpen, TelCheckbox, TelInputRow, TelInputContainer } from "../../../Molecules/Auth/SignUp/styles";
import selectArrowIcon from "../../../../Assets/Images/SignUp/chevron-down.svg";
import armeniaIcon from "../../../../Assets/Images/SignUp/armenia-icon.svg";
import russiaIcon from "../../../../Assets/Images/SignUp/russia-icon.svg";
import { validateEmail } from "../../../../utils/email";

const countries = [
	{ code: "+374", name: "Armenia", icon: armeniaIcon, digitsLength: 8, testTel: "99 12 00 14" },
	{ code: "+7", name: "Russia", icon: russiaIcon, digitsLength: 12, testTel: "912 345 67 89" },
];

const ContactDetails = () => {
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		tel: "",
		siteURL: "",
	});
	const [validationErrors, setValidationErrors] = useState({});
	const [isOpen, setIsOpen] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState(countries[0]);
	const { sendEmail, formRefs } = useSendEmail();

	const regexSocialPatterns = {
		facebook: /^https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9._-]+(\/\S*)?$/,
		twitter: /^https?:\/\/(www\.)?(twitter|x)\.com\/[a-zA-Z0-9_]+(\/\S*)?$/,
		instagram: /^https?:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9._]+(\/\S*)?$/,
		linkedin: /^https?:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+(\/\S*)?$/,
		github: /^https?:\/\/(www\.)?github\.com\/[a-zA-Z0-9-]+(\/\S*)?$/,
	};

	const validateLink = (link) => {
		return Object.values(regexSocialPatterns).some((pattern) => pattern.test(link));
	};

	const validate = () => {
		const newErrors = {};
		const { firstName, lastName, email, tel, siteURL } = formData;

		if (!firstName) {
			newErrors.firstName = "First Name is required";
		} else if (firstName && /[^a-zA-Z\s]/.test(firstName)) {
			newErrors.firstName = "First name can only contain letters and spaces";
		}

		// Check if lastName contains only alphabetic characters
		if (lastName && /[^a-zA-Z\s]/.test(lastName)) {
			newErrors.lastName = "Last name can only contain letters and spaces";
		}

		if (!email) {
			newErrors.email = "Email is required";
		} else if (!validateEmail(email)) {
			newErrors.email = "Email is invalid";
		}

		if (!tel) {
			newErrors.tel = "Phone Number is required";
		} else if (tel.length !== selectedCountry.digitsLength) {
			newErrors.tel = "Phone Number is invalid";
		}

		if (!siteURL) {
			newErrors.siteURL = "Social media link is required";
		} else if (!validateLink(siteURL)) {
			newErrors.siteURL = "Invalid Social media link";
		}

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "tel") {
			let newValue = value;

			// Check if the input starts with the country code prefix, e.g., "(+374) "
			if (value.startsWith(`(${selectedCountry.code}) `)) {
				newValue = value.split(")").slice(1)[0].replace(/\s/g, "");
			}

			// Apply character limit based on selectedCountry.digitsLength
			const regex = new RegExp(`^[0-9]{0,${selectedCountry.digitsLength}}$`);
			if (regex.test(newValue)) {
				setFormData((prevData) => ({
					...prevData,
					[name]: newValue,
				}));
			}
		} else if (name === "firstName" || name === "lastName") {
			setFormData((prevData) => ({
				...prevData,
				[name]: value.replace(/[^a-zA-Z\s]/g, ""),
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSelect = (country) => {
		setSelectedCountry(country);
		setIsOpen(false);
		setFormData((prevData) => ({
			...prevData,
			tel: "",
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (validate()) {
			const formData = {
				firstName: formRefs.firstName.current.value,
				lastName: formRefs.lastName.current.value,
				email: formRefs.email.current.value,
				tel: formRefs.tel.current.value,
				siteURL: formRefs.siteURL.current.value,
			};

			const result = await sendEmail(formData);

			console.log("Form Submitted", result);
		}
	};

	return (
		<Section>
			<div />
			<SectionContainer>
				<div>
					<h1>Contact Details</h1>
					<PartnerForm onSubmit={handleSubmit}>
						<NameInputsRow>
							<InputRow>
								<label htmlFor="firstName">
									First name <span>*</span>
								</label>
								<FirstNameInputContainer error={validationErrors.firstName ? 1 : 0}>
									<input
										type="text"
										name="firstName"
										id="firstName"
										placeholder="Lia"
										value={formData.firstName}
										onChange={handleChange}
										ref={formRefs.firstName}
									/>
									{validationErrors.firstName && <p>{validationErrors.firstName}</p>}
								</FirstNameInputContainer>
							</InputRow>
							<InputRow>
								<label htmlFor="lastName">Last name</label>
								<LastNameInputContainer error={validationErrors.lastName ? 1 : 0}>
									<input
										type="text"
										name="lastName"
										id="lastName"
										placeholder="Zakaryan"
										value={formData.lastName}
										onChange={handleChange}
										ref={formRefs.lastName}
									/>
									{validationErrors.lastName && <p>{validationErrors.lastName}</p>}
								</LastNameInputContainer>
							</InputRow>
						</NameInputsRow>
						<InputRow>
							<label htmlFor="email">
								Email <span>*</span>
							</label>
							<EmailInputContainer error={validationErrors.email ? 1 : 0}>
								<input
									type="text"
									name="email"
									id="email"
									placeholder="example@gmail.com"
									value={formData.email}
									onChange={handleChange}
									ref={formRefs.email}
								/>
								{validationErrors.email && <p>{validationErrors.email}</p>}
							</EmailInputContainer>
						</InputRow>
						<TelInputRow error={validationErrors.tel} page={"becomePartner"}>
							<label htmlFor="">
								Phone Number <span>*</span>
							</label>
							<div>
								<TelInputContainer error={validationErrors.tel ? 1 : 0}>
									<TelCheckbox onClick={() => setIsOpen(!isOpen)}>
										<Checkbox>
											<img loading="lazy" src={selectedCountry.icon} alt={selectedCountry.name} />
											<img loading="lazy" src={selectArrowIcon} alt="select arrow" />
										</Checkbox>
										{isOpen && (
											<CheckboxOpen>
												{countries.map((country) => (
													<div key={country.code} onClick={() => handleSelect(country)}>
														<img loading="lazy" src={country.icon} alt={country.name} />
														<div>
															<span>{country.code}</span>
															<img loading="lazy" src={selectArrowIcon} alt="select arrow" />
														</div>
													</div>
												))}
											</CheckboxOpen>
										)}
									</TelCheckbox>
									<div style={{ position: "relative" }}>
										<span
											style={{
												position: "absolute",
												left: "58px", // Adjust based on your layout
												top: "50%",
												transform: "translateY(-50%)",
												color: "#000",
												pointerEvents: "none", // Prevents the span from blocking input interaction
											}}
										>
											({selectedCountry.code}){" "}
										</span>
										<input
											type="text"
											placeholder={selectedCountry.testTel}
											name="tel"
											value={formData.tel}
											onChange={handleChange}
											style={{
												padding: `12px 16px 12px ${45 + (selectedCountry.code.length + 3) * 8}px`, // Dynamic padding-left
											}}
											ref={formRefs.tel}
										/>
									</div>
								</TelInputContainer>
								{validationErrors.tel && <p>{validationErrors.tel}</p>}
							</div>
						</TelInputRow>
						<InputRow>
							<label htmlFor="siteURL">
								Social media links <span>*</span>
							</label>
							<SocialInputContainer error={validationErrors.siteURL ? 1 : 0}>
								<SocialInput>
									<input
										type="text"
										name="siteURL"
										id="siteURL"
										placeholder="www.instagram.com"
										value={formData.siteURL}
										onChange={handleChange}
										ref={formRefs.siteURL}
									/>
								</SocialInput>
								{validationErrors.siteURL && <p>{validationErrors.siteURL}</p>}
							</SocialInputContainer>
						</InputRow>
						<PartnerButton type="submit">Send Request</PartnerButton>
					</PartnerForm>
				</div>
			</SectionContainer>
		</Section>
	);
};

export default ContactDetails;
