import { useEffect, useState, memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMobileDetector, useTabletDetector } from "../../../utils/customHooks";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { setError, signOut } from "../../../reducers/auth";
import { clearUserRole } from "../../../reducers/user";
import { CELEBRITY_SIGN_OUT_MUTATION, GET_CELEBRITY_QUERY } from "../../../queries/celebrity";
import { NOTIFICATION_ADDED_SUBSCRIPTION, SET_NOTIFICATION_IS_READ_MUTATION } from "../../../queries/notification";
import { parseJwt } from "../../../utils/jwtToken";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
	Navbar,
	NavLogo,
	NavLinkContainer,
	NavLink,
	LoginLink,
	DropdownContainer,
	DropdownButton,
	DropdownMenu,
	DropdownItem,
	SelectDivider,
	NavSearch,
	CelebrityDropdownContainer,
	CelebrityDropdownButton,
	CustomerDropdownContainer,
	CustomerDropdownButtons,
	AuthDropdownMenu,
	CartPopupContainer,
	CartPopupBody,
	NavLinkMobile,
	CelebrityNav,
	NotificationButton,
	NotificationsDropdownMenu,
	NotificationsHeading,
	NotificationsDropdownBody,
	NotificationItem,
} from "./styles";
import customerImg from "../../../Assets/Images/Layout/Navbar/user-icon.svg";
import cartImg from "../../../Assets/Images/Layout/Navbar/cart-icon.svg";
import notificationImg from "../../../Assets/Images/Layout/Navbar/notification-icon.svg";
import signOutImg from "../../../Assets/Images/Layout/Navbar/sign-out-icon.svg";
import accountImg from "../../../Assets/Images/Layout/Navbar/account-icon.svg";
import succeededImg from "../../../Assets/Images/CelebrityProfile/succeeded-icon.svg";
import userImg from "../../../Assets/Images/UserProfile/user-img.webp";

const notificationImages = {
	CARD_ATTACHED: succeededImg,
	REQUEST_ACCEPTED: succeededImg,
	REQUEST_DECLINED: succeededImg,
	VIDEO_READY: succeededImg,
	NEW_REQUEST_RECEIVED: succeededImg,
	PAYMENT_RECEIVED: succeededImg,
	PAYOUT_PROCESSED: succeededImg,
};

// Notification types
//
// For users:
// Card Attached – "Your card is attached! You'll be notified when the celebrity accepts your request."
// Request Accepted – "Great news! [Celebrity Name] has accepted your request. Your video will be ready within 3 working days."
// Request Declined – "Unfortunately, [Celebrity Name] has declined your request. You won’t be charged."
// Video Ready – "Your video is ready! Check your mailbox to watch it now."
//
// For celebrities:
// New Request Received – "You have a new video request from [User Name]! Review and accept or decline it now."
// Payment Received – "You’ve received a payment! The amount has been added to your balance."
// Payout Processed – "Your withdrawal is successful! The funds have been sent to your account."

const DefaultNavbar = ({ handleOpenMenu }) => {
	let location = useLocation();
	const isMobile = useMobileDetector();
	const isTablet = useTabletDetector();
	const [categoryIsOpen, setCategoryIsOpen] = useState(false);
	const [celebrityDropdownIsOpen, setCelebrityDropdownIsOpen] = useState(false);
	const [customerDropdownIsOpen, setCustomerDropdownIsOpen] = useState(false);
	const [cartPopup, setCartPopup] = useState(false);
	const [notificationsDropdownIsOpen, setNotificationsDropdownIsOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [unreadCount, setUnreadCount] = useState(0);
	const [celebrityImageUrl, setCelebrityImageUrl] = useState(null);
	const [customerImageUrl, setCustomerImageUrl] = useState(customerImg); // Default to customerImg

	const dispatch = useDispatch();
	const { tokens } = useSelector((state) => state.auth);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const userRole = useSelector((state) => state.user.role);
	const isCelebrity = userRole?.type === "celebrity";
	const [userId, setUserId] = useState("");
	const [searchQuery, setSearchQuery] = useState("");

	const navigate = useNavigate();
	const baseUrl = process.env.NODE_ENV === "production" ? "https://server.fameeo.com" : "http://localhost:8080";

	// Fetch celebrity data (including notifications)
	const { data: celebrityData } = useQuery(GET_CELEBRITY_QUERY, {
		variables: { celebrityId: userId },
		skip: !isCelebrity || !userId, // Skip if not a celebrity or no userId
	});

	// Subscribe to new notifications
	const {
		data: notificationsData,
		loading,
		error,
	} = useSubscription(NOTIFICATION_ADDED_SUBSCRIPTION, {
		variables: { userId },
		skip: !userId,
		onSubscriptionData: ({ subscriptionData }) => {
			const newNotification = subscriptionData.data?.notificationAdded;
			if (newNotification) {
				// Create a new array to ensure React detects the change
				setNotifications((prev) => {
					const updated = [...prev, { ...newNotification }]; // Deep copy to ensure new reference
					console.log("Updated notifications:", updated);
					return updated;
				});
				if (!newNotification.isRead) {
					setUnreadCount((prev) => {
						const newCount = prev + 1;
						console.log("Updated unreadCount:", newCount);
						return newCount;
					});
					toast.info(newNotification.message, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
					});
					// // Auto-open the dropdown when a new notification arrives
					// setNotificationsDropdownIsOpen(true);
					// // Optional: Auto-close after 5 seconds
					// setTimeout(() => {
					// 	setNotificationsDropdownIsOpen(false);
					// }, 5000);
				}
			}
		},
	});

	const [setNotificationIsRead] = useMutation(SET_NOTIFICATION_IS_READ_MUTATION);

	// Function to check if an image URL is valid
	const checkImageExists = (url) => {
		return new Promise((resolve) => {
			const img = new Image();
			img.onload = () => resolve(true);
			img.onerror = () => resolve(false);
			img.src = url;
		});
	};

	// Handler for search input
	const handleSearch = (e) => {
		const query = e.target.value;
		setSearchQuery(query);
		if (e.key === "Enter" && query.trim()) {
			navigate(`/category/all-celebrities?search=${encodeURIComponent(query.trim())}`);
			setSearchQuery("");
		}
	};

	// Validate celebrity profile image
	useEffect(() => {
		if (isCelebrity && celebrityData?.getCelebrity?.profileImg) {
			const imageUrl = `${baseUrl}${celebrityData.getCelebrity.profileImg}`;
			checkImageExists(imageUrl).then((exists) => {
				setCelebrityImageUrl(exists ? imageUrl : userImg);
			});
		} else if (isCelebrity) {
			setCelebrityImageUrl(userImg); // Fallback if no profileImg
		}
	}, [celebrityData, isCelebrity, baseUrl]);

	// Set userId from token
	useEffect(() => {
		const fetchTokenData = async () => {
			try {
				const tokenData = await parseJwt(tokens.access);
				setUserId(tokenData.userId);
			} catch (error) {
				console.log("Error parsing token:", error);
			}
		};
		if (tokens.access) fetchTokenData();
	}, [tokens]);

	// Set initial notifications from celebrity data
	useEffect(() => {
		if (celebrityData?.getCelebrity?.notifications) {
			console.log("Initial notifications from query:", celebrityData.getCelebrity.notifications);
			setNotifications(celebrityData.getCelebrity.notifications);
			setUnreadCount(celebrityData.getCelebrity.notifications.filter((n) => !n.isRead).length);
		}
	}, [celebrityData]);

	useEffect(() => {
		if (error) {
			console.error("Subscription error:", error);
		}
		if (loading) {
			console.log("Subscription loading...");
		}
		if (notificationsData) {
			console.log("Notifications data updated:", notificationsData);
		}
	}, [error, loading, notificationsData]);

	useEffect(() => {
		if (cartPopup) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflowY = "auto";
		}
	}, [cartPopup]);

	// Handle sign out
	const [celebritySignOut] = useMutation(CELEBRITY_SIGN_OUT_MUTATION, {
		onCompleted: () => {
			dispatch(signOut());
			dispatch(clearUserRole());
			navigate("/");
		},
		onError: (error) => {
			dispatch(setError(error.message));
			console.log("Sign out error:", error);
		},
	});

	const handleSignOut = async () => {
		await celebritySignOut({
			variables: { celebrityId: userId },
		});
		setCelebrityDropdownIsOpen(false);
	};

	const handleNotificationsClick = async () => {
		setNotificationsDropdownIsOpen(!notificationsDropdownIsOpen);
		if (!notificationsDropdownIsOpen) {
			setUnreadCount(0);
			await setNotificationIsRead({
				variables: { userType: userRole.type, userId },
			});
		}
	};

	return (
		<Navbar>
			<div>
				<div>
					<NavLinkMobile loc={location.pathname} onClick={handleOpenMenu}>
						<div></div>
					</NavLinkMobile>
					<NavLogo to={"/"}>Fameeo</NavLogo>
				</div>
				<NavLinkContainer>
					{!isMobile && !isTablet && (
						<>
							<DropdownContainer onMouseEnter={() => setCategoryIsOpen(true)} onMouseLeave={() => setCategoryIsOpen(false)}>
								<DropdownButton isopen={categoryIsOpen.toString()} onClick={() => navigate("category/all-celebrities")}>
									Categories
								</DropdownButton>
								{categoryIsOpen && (
									<DropdownMenu isopen={categoryIsOpen.toString()}>
										<div>
											<DropdownItem to={"category/all-celebrities"}>All Celebrities</DropdownItem>
											<DropdownItem to={"category/youtubers"}>YouTubers</DropdownItem>
											<DropdownItem to={"category/reality-tv-stars"}>Reality TV Stars</DropdownItem>
											<DropdownItem to={"category/influencers"}>Influencers</DropdownItem>
										</div>
										<SelectDivider />
										<div>
											<DropdownItem to={"category/comedians"}>Comedians</DropdownItem>
											<DropdownItem to={"category/athletes"}>Athletes</DropdownItem>
											<DropdownItem to={"category/musicians"}>Musicians</DropdownItem>
											<DropdownItem to={"category/actors"}>Actors</DropdownItem>
										</div>
									</DropdownMenu>
								)}
							</DropdownContainer>
							<NavLink to={"/#HowItWorks"}>How it Works</NavLink>
							<NavLink to={"/become-partner"}>Become a partner</NavLink>
							<NavSearch>
								<input
									type="text"
									placeholder="Search Celebrities, Categories..."
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
									onKeyPress={handleSearch}
								/>
							</NavSearch>
						</>
					)}

					{isAuthenticated ? (
						isCelebrity ? (
							<CelebrityDropdownContainer>
								<CelebrityNav>
									<NotificationButton onClick={handleNotificationsClick}>
										<img loading="lazy" src={notificationImg} alt="Notification" />
										{unreadCount > 0 && (
											<span
												style={{
													position: "absolute",
													top: "-5px",
													right: "-5px",
													background: "red",
													color: "white",
													borderRadius: "50%",
													padding: "2px 6px",
													fontSize: "12px",
												}}
											>
												{unreadCount}
											</span>
										)}
									</NotificationButton>
									<CelebrityDropdownButton
										isOpen={celebrityDropdownIsOpen}
										onClick={() => setCelebrityDropdownIsOpen(!celebrityDropdownIsOpen)}
									>
										<img loading="lazy" src={celebrityImageUrl || userImg} alt="Celebrity" />
									</CelebrityDropdownButton>
								</CelebrityNav>
								{notificationsDropdownIsOpen && (
									<NotificationsDropdownMenu>
										<NotificationsHeading>Notifications</NotificationsHeading>
										<NotificationsDropdownBody>
											{notifications.length === 0 ? (
												<p>No notifications yet</p>
											) : (
												notifications.map((notification) => (
													<NotificationItem key={notification.id}>
														<img
															src={notificationImages[notification.type]}
															alt={notification.type}
															style={{
																width: "24px",
																height: "24px",
																marginRight: "10px",
															}}
														/>
														<div>
															<p>{notification.message}</p>
															<span>{new Date(notification.createdAt).toLocaleString()}</span>
														</div>
													</NotificationItem>
												))
											)}
										</NotificationsDropdownBody>
									</NotificationsDropdownMenu>
								)}
								{celebrityDropdownIsOpen && (
									<AuthDropdownMenu isOpen={celebrityDropdownIsOpen}>
										<Link to={`/dashboard/celebrity/${userId}`} onClick={() => setCelebrityDropdownIsOpen(false)}>
											<img loading="lazy" src={accountImg} alt="" />
											Account
										</Link>
										<Link to="/" onClick={handleSignOut}>
											<img loading="lazy" src={signOutImg} alt="" />
											Log out
										</Link>
									</AuthDropdownMenu>
								)}
							</CelebrityDropdownContainer>
						) : (
							<CustomerDropdownContainer>
								<CustomerDropdownButtons isOpen={customerDropdownIsOpen}>
									<button onClick={() => setCartPopup(true)}>
										<img loading="lazy" src={cartImg} alt="Cart" />
									</button>
									<button onClick={() => setCustomerDropdownIsOpen(!customerDropdownIsOpen)}>
										<img loading="lazy" src={customerImageUrl} alt="Customer" />
									</button>
								</CustomerDropdownButtons>
								{customerDropdownIsOpen && (
									<AuthDropdownMenu isOpen={customerDropdownIsOpen}>
										<Link to={`/customer/${userId}`}>Account</Link>
										<Link to="">Logout</Link>
									</AuthDropdownMenu>
								)}
							</CustomerDropdownContainer>
						)
					) : (
						<LoginLink to={"/auth/sign-in"}>Log in</LoginLink>
					)}
				</NavLinkContainer>
			</div>
			{(isMobile || isTablet) && (
				<NavSearch>
					<input
						type="text"
						placeholder="Search Celebrities, Categories..."
						value={searchQuery}
						onChange={(e) => setSearchQuery(e.target.value)}
						onKeyPress={handleSearch}
					/>
				</NavSearch>
			)}

			{cartPopup && (
				<CartPopupContainer>
					<CartPopupBody>
						<h2>Your Cart</h2>
						<p>You have an uncompleted request for James Buckley. Do you want to complete your request?</p>
						<div>
							<button>Remove request</button>
							<button>Continue with request</button>
						</div>
						<button onClick={() => setCartPopup(false)}>
							<span>&times;</span>
						</button>
					</CartPopupBody>
				</CartPopupContainer>
			)}
		</Navbar>
	);
};

export default memo(DefaultNavbar);
