import { useEffect, useRef, useState, memo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_CATEGORIES_QUERY } from "../../../../queries/category";
import { GET_ALL_CELEBRITIES_QUERY } from "../../../../queries/celebrity";
import {
	Section,
	CelebritiesFilterNav,
	FilterButton,
	CelebritiesGrid,
	CelebrityCard,
	CelebrityInfo,
	CelebrityRating,
	BookingButton,
	FilterDropdownWrapper,
	FilterDropdownMenu,
	FilterDropdownMenuItem,
	FilterDropdownContainer,
	FilterDropdownTitle,
	FilterDropdown,
	PriceRangeContainer,
	PriceInputBox,
	PriceMinBox,
	PriceMaxBox,
	RangeSlider,
	SliderTrack,
	MinTooltip,
	MaxTooltip,
	CelebrityImg,
} from "./styles";
import starImg from "../../../../Assets/Images/Category/star-icon.svg";
import { useMobileDetector } from "../../../../utils/customHooks";
import ImagePlaceholder from "../../../Molecules/CategoryCelebrities/ImagePlaceholder";
import celebrityImg1 from "../../../../Assets/Images/UserCelebrity/celebrity1.webp";

const CategoryCelebrities = () => {
	const { data: categoriesData } = useQuery(GET_ALL_CATEGORIES_QUERY, {
		fetchPolicy: "network-only",
	});

	const { data: celebritiesData } = useQuery(GET_ALL_CELEBRITIES_QUERY, {
		fetchPolicy: "network-only",
	});

	const [imagesLoaded, setImagesLoaded] = useState({});
	const [celebrityCardHoveredIndex, setCelebrityCardHoveredIndex] = useState(-1);
	const [filterSidebarIsOpen, setFilterSidebarIsOpen] = useState(false);
	const [isAutoControlled, setIsAutoControlled] = useState(true);
	const gridRef = useRef(null);
	const [filteredCelebrities, setFilteredCelebrities] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState({
		category: "All Celebrities",
		price: "All Prices",
	});
	const [filterOptions, setFilterOptions] = useState([
		{
			id: "category",
			label: "Category",
			options: [],
		},
		{
			id: "price",
			label: "Price",
			options: [],
		},
	]);

	const isMobile = useMobileDetector();
	const initialMinPrice = 0;
	const initialMaxPrice = 100000;
	const [sliderMinValue] = useState(initialMinPrice);
	const [sliderMaxValue] = useState(initialMaxPrice);
	const [minVal, setMinVal] = useState(initialMinPrice);
	const [maxVal, setMaxVal] = useState(initialMaxPrice);
	const [minInput, setMinInput] = useState(initialMinPrice);
	const [maxInput, setMaxInput] = useState(initialMaxPrice);
	const [isDragging, setIsDragging] = useState(false);
	const minGap = 5;
	const sliderTrackRef = useRef(null);

	// Search query from URL
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const searchQuery = searchParams.get("search")?.toLowerCase() || "";

	const { categoryName } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (categoryName) {
			// Convert URL format (e.g., "voice-acting") to display format ("Voice Acting")
			let formattedCategoryName = categoryName
				.split("-")
				.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
				.join(" ");

			// handling "YouTubers" and "Reality TV Stars"
			if (formattedCategoryName === "Youtubers") formattedCategoryName = "YouTubers";
			if (formattedCategoryName === "Reality Tv Stars") formattedCategoryName = "Reality TV Stars";

			setSelectedFilters((prev) => ({
				...prev,
				category: formattedCategoryName,
			}));
		}
	}, [categoryName]);

	useEffect(() => {
		if (categoriesData?.getAllCategories) {
			let formattedCategories = [];
			let formattedCategory = {};

			categoriesData?.getAllCategories.forEach((category) => {
				switch (category.name) {
					case "Youtubers":
						formattedCategory = {
							...category,
							name: "YouTubers",
						};
						formattedCategories.push(formattedCategory);
						break;

					case "Reality Tv Stars":
						formattedCategory = {
							...category,
							name: "Reality TV Stars",
						};
						formattedCategories.push(formattedCategory);
						break;

					default:
						formattedCategories.push(category);
						break;
				}
			});

			setFilterOptions((prevOptions) => [
				{
					...prevOptions[0],
					options: formattedCategories,
				},
				prevOptions[1],
			]);
		}
	}, [categoriesData]);

	// Filtering Celebrities useEffect
	useEffect(() => {
		setFilteredCelebrities(filterCelebrities(celebritiesData?.getAllCelebrities || []));
	}, [celebritiesData, selectedFilters, minVal, maxVal, searchQuery]);

	useEffect(() => {
		if (!gridRef.current) return;

		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const isSmallScreen = entry.contentRect.width <= 970;
				// Update the sidebar state only if it is auto-controlled
				if (isAutoControlled) {
					setFilterSidebarIsOpen(isSmallScreen);
				}
			}
		});

		resizeObserver.observe(gridRef.current);
		return () => resizeObserver.disconnect();
	}, [isAutoControlled]);

	const toggleFilterSidebar = () => {
		setIsAutoControlled(false);
		setFilterSidebarIsOpen((prev) => !prev);
	};

	const getCategoryNameById = (id) => {
		const category = categoriesData?.getAllCategories.find((category) => category.id === id);
		return category ? category.name : "";
	};

	const filterCelebrities = (celebrities) => {
		return celebrities.filter((celebrity) => {
			// Category filter
			if (selectedFilters.category !== "All Celebrities" && getCategoryNameById(celebrity.categoryId) !== selectedFilters.category) {
				return false;
			}

			// Price filter
			const celebrityPrice = Number(celebrity.price);
			const isPriceInRange = celebrityPrice >= minVal && celebrityPrice <= maxVal;
			if (!isPriceInRange) {
				return false;
			}

			// Search filter
			if (searchQuery) {
				const fullName = `${celebrity.firstName} ${celebrity.lastName}`.toLowerCase();
				const categoryName = getCategoryNameById(celebrity.categoryId).toLowerCase();
				return fullName.includes(searchQuery) || categoryName.includes(searchQuery);
			}

			return true;
		});
	};

	const handleFilterSelect = (filterId, value) => {
		setSelectedFilters((prev) => ({
			...prev,
			[filterId]: value,
		}));

		// Handle navigation for category selection
		if (filterId === "category") {
			const urlCategory = value === "All Celebrities" ? "all-celebrities" : value.toLowerCase().replace(/\s+/g, "-");
			navigate(`/category/${urlCategory}`);
		}
	};

	const slideMin = (e) => {
		const value = parseInt(e.target.value, 10);
		if (value >= sliderMinValue && maxVal - value >= minGap) {
			setMinVal(value);
			setMinInput(value);
		}
	};

	const slideMax = (e) => {
		const value = parseInt(e.target.value, 10);
		if (value <= sliderMaxValue && value - minVal >= minGap) {
			setMaxVal(value);
			setMaxInput(value);
		}
	};

	const setSliderTrack = () => {
		// const range = document.querySelector(".slider-track");
		const range = sliderTrackRef.current;

		if (range) {
			const minPercent = ((minVal - sliderMinValue) / (sliderMaxValue - sliderMinValue)) * 100;
			const maxPercent = ((maxVal - sliderMinValue) / (sliderMaxValue - sliderMinValue)) * 100;

			range.style.left = `${minPercent}%`;
			range.style.right = `${100 - maxPercent}%`;
		}
	};

	useEffect(() => {
		setSliderTrack();
	}, [minVal, maxVal]);

	const handleMinInput = (e) => {
		const value = e.target.value === "" ? sliderMinValue : parseInt(e.target.value, 10);
		if (value >= sliderMinValue && value < maxVal - minGap) {
			setMinInput(value);
			setMinVal(value);
		}
	};

	const handleMaxInput = (e) => {
		const value = e.target.value === "" ? sliderMaxValue : parseInt(e.target.value, 10);
		if (value <= sliderMaxValue && value > minVal + minGap) {
			setMaxInput(value);
			setMaxVal(value);
		}
	};

	const handleInputKeyDown = (e, type) => {
		if (e.key === "Enter") {
			const value = parseInt(e.target.value, 10);
			if (type === "min" && value >= sliderMinValue && value < maxVal - minGap) {
				setMinVal(value);
			} else if (type === "max" && value <= sliderMaxValue && value > minVal + minGap) {
				setMaxVal(value);
			}
		}
	};

	const startDrag = () => {
		setIsDragging(true);
	};

	const stopDrag = () => {
		setIsDragging(false);
	};

	const handleImageLoaded = (celebrityId) => {
		setImagesLoaded((prev) => ({
			...prev,
			[celebrityId]: true,
		}));
	};

	useEffect(() => {}, [imagesLoaded]);

	return (
		<Section>
			<div>
				<CelebritiesFilterNav>
					<h2>
						{searchQuery ? (
							<>
								Search results for "{searchQuery}" <strong>/</strong>
							</>
						) : (
							<>
								{selectedFilters.category} <strong>/</strong>
							</>
						)}
						<span> {filteredCelebrities.length} Results</span>
					</h2>
					<div>
						<FilterButton onClick={toggleFilterSidebar}>{filterSidebarIsOpen ? "Hide" : "Show"} Filters</FilterButton>
					</div>
				</CelebritiesFilterNav>
				{filterSidebarIsOpen && isMobile && (
					<FilterDropdown>
						<h2>Filters</h2>
						<FilterDropdownWrapper>
							{filterOptions.map((filter) => (
								<FilterDropdownContainer key={filter.id}>
									<FilterDropdownTitle>
										<span>{filter.label}</span>
										{filter.id === "category" && <span>{selectedFilters.category}</span>}
										{/*{filter.id === "price" && (*/}
										{/*	<span>Up to AMD {Number(priceRange.current).toLocaleString()}</span>*/}
										{/*)}*/}
									</FilterDropdownTitle>
									<FilterDropdownMenu>
										{filter.id === "category" ? (
											<>
												<FilterDropdownMenuItem onClick={() => handleFilterSelect("category", "All Celebrities")}>
													All Celebrities
												</FilterDropdownMenuItem>
												{filter.options?.map((option, idx) => (
													<FilterDropdownMenuItem
														key={idx}
														onClick={() =>
															handleFilterSelect(filter.id, filter.id === "category" ? option.name : option.name)
														}
													>
														{option.name}
													</FilterDropdownMenuItem>
												))}
											</>
										) : (
											<PriceRangeContainer>
												<PriceInputBox>
													<PriceMinBox>
														<input
															type="number"
															value={minInput}
															onChange={handleMinInput}
															onKeyDown={(e) => handleInputKeyDown(e, "min")}
															min={sliderMinValue}
															max={maxVal - minGap}
														/>
													</PriceMinBox>
													<PriceMaxBox>
														<input
															type="number"
															value={maxInput}
															onChange={handleMaxInput}
															onKeyDown={(e) => handleInputKeyDown(e, "max")}
															min={minVal + minGap}
															max={sliderMaxValue}
														/>
													</PriceMaxBox>
												</PriceInputBox>
												<RangeSlider>
													<SliderTrack ref={sliderTrackRef} />
													<input
														type="range"
														min={sliderMinValue}
														max={sliderMaxValue}
														value={minVal}
														onChange={slideMin}
														onMouseDown={startDrag}
														onMouseUp={stopDrag}
														onTouchStart={startDrag}
														onTouchEnd={stopDrag}
													/>
													<input
														type="range"
														min={sliderMinValue}
														max={sliderMaxValue}
														value={maxVal}
														onChange={slideMax}
														onMouseDown={startDrag}
														onMouseUp={stopDrag}
														onTouchStart={startDrag}
														onTouchEnd={stopDrag}
													/>
													{isDragging && <MinTooltip>{minVal}</MinTooltip>}
													{isDragging && <MaxTooltip>{maxVal}</MaxTooltip>}
												</RangeSlider>
											</PriceRangeContainer>
										)}
									</FilterDropdownMenu>
								</FilterDropdownContainer>
							))}
						</FilterDropdownWrapper>
					</FilterDropdown>
				)}
				<CelebritiesGrid ref={gridRef} isopen={filterSidebarIsOpen ? 1 : 0}>
					{filteredCelebrities.map((celebrity, index) => (
						<CelebrityCard
							key={index}
							onMouseEnter={() => setCelebrityCardHoveredIndex(index)}
							onMouseLeave={() => setCelebrityCardHoveredIndex(-1)}
							hovered={celebrityCardHoveredIndex === index ? 1 : 0}
							isopen={filterSidebarIsOpen ? 1 : 0}
						>
							{!imagesLoaded[celebrity.id] && <ImagePlaceholder isopen={filterSidebarIsOpen ? 1 : 0} />}
							<CelebrityImg
								loading="lazy"
								src={celebrityImg1}
								alt={`${celebrity.firstName} ${celebrity.lastName}`}
								onLoad={() => handleImageLoaded(celebrity.id)}
								isopen={filterSidebarIsOpen ? 1 : 0}
								hasloaded={imagesLoaded[celebrity.id] ? 1 : 0}
							/>
							<CelebrityInfo
								hovered={celebrityCardHoveredIndex === index ? 1 : 0}
								isopen={filterSidebarIsOpen ? 1 : 0}
								ismobile={isMobile ? 1 : 0}
							>
								<div>
									<h3>
										{celebrity.firstName} {celebrity.lastName}
									</h3>

									<CelebrityRating isopen={filterSidebarIsOpen ? 1 : 0}>
										<div>
											<img loading="lazy" src={starImg} alt="" />
											<span>
												{celebrity.rating}({celebrity.ratingCount})
											</span>
										</div>
										<span>{getCategoryNameById(celebrity.categoryId)}</span>
									</CelebrityRating>
									<span>AMD {Number(celebrity.price).toLocaleString()}</span>
								</div>
								<BookingButton
									to={`/public/celebrity/${celebrity.id}`}
									hovered={celebrityCardHoveredIndex === index ? 1 : 0}
									isopen={filterSidebarIsOpen ? 1 : 0}
									ismobile={isMobile ? 1 : 0}
								>
									Book Now
								</BookingButton>
							</CelebrityInfo>
						</CelebrityCard>
					))}
				</CelebritiesGrid>
			</div>
			{filterSidebarIsOpen && !isMobile && (
				<FilterDropdown>
					<h2>Filters</h2>
					<FilterDropdownWrapper>
						{filterOptions.map((filter) => (
							<FilterDropdownContainer key={filter.id}>
								<FilterDropdownTitle>
									<span>{filter.label}</span>
									{filter.id === "category" && <span>{selectedFilters.category}</span>}
									{/*{filter.id === "price" && (*/}
									{/*	<span>Up to AMD {Number(priceRange.current).toLocaleString()}</span>*/}
									{/*)}*/}
								</FilterDropdownTitle>
								<FilterDropdownMenu>
									{filter.id === "category" ? (
										<>
											<FilterDropdownMenuItem onClick={() => handleFilterSelect("category", "All Celebrities")}>
												All Celebrities
											</FilterDropdownMenuItem>
											{filter.options?.map((option, idx) => (
												<FilterDropdownMenuItem
													key={idx}
													onClick={() =>
														handleFilterSelect(filter.id, filter.id === "category" ? option.name : option.name)
													}
												>
													{option.name}
												</FilterDropdownMenuItem>
											))}
										</>
									) : (
										<PriceRangeContainer>
											<PriceInputBox>
												<PriceMinBox>
													<input
														type="number"
														value={minInput}
														onChange={handleMinInput}
														onKeyDown={(e) => handleInputKeyDown(e, "min")}
														min={sliderMinValue}
														max={maxVal - minGap}
													/>
												</PriceMinBox>
												<PriceMaxBox>
													<input
														type="number"
														value={maxInput}
														onChange={handleMaxInput}
														onKeyDown={(e) => handleInputKeyDown(e, "max")}
														min={minVal + minGap}
														max={sliderMaxValue}
													/>
												</PriceMaxBox>
											</PriceInputBox>
											<RangeSlider>
												<SliderTrack ref={sliderTrackRef} />
												<input
													type="range"
													min={sliderMinValue}
													max={sliderMaxValue}
													value={minVal}
													onChange={slideMin}
													onMouseDown={startDrag}
													onMouseUp={stopDrag}
													onTouchStart={startDrag}
													onTouchEnd={stopDrag}
												/>
												<input
													type="range"
													min={sliderMinValue}
													max={sliderMaxValue}
													value={maxVal}
													onChange={slideMax}
													onMouseDown={startDrag}
													onMouseUp={stopDrag}
													onTouchStart={startDrag}
													onTouchEnd={stopDrag}
												/>
												{isDragging && <MinTooltip>{minVal}</MinTooltip>}
												{isDragging && <MaxTooltip>{maxVal}</MaxTooltip>}
											</RangeSlider>
										</PriceRangeContainer>
									)}
								</FilterDropdownMenu>
							</FilterDropdownContainer>
						))}
					</FilterDropdownWrapper>
				</FilterDropdown>
			)}
		</Section>
	);
};

export default memo(CategoryCelebrities);
