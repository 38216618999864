import { gql } from "@apollo/client";

export const CUSTOMER_SIGN_OUT_MUTATION = gql`
	mutation CustomerSignOut($customerId: String!) {
		customerSignOut(customerId: $customerId)
	}
`;

export const CUSTOMER_RENEW_TOKEN_MUTATION = gql`
	mutation CustomerRenewToken($refreshState: String!) {
		customerRenewToken(refreshState: $refreshState) {
			access
			refresh
		}
	}
`;
