import { createContext, memo, useContext, useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CELEBRITY_QUERY, UPLOAD_PROFILE_IMAGE_MUTATION } from "../../queries/celebrity";
import ProfilePhotoInput from "../../Components/Molecules/CelebrityProfile/CelebrityProfileImageInput";
import { useMobileDetector, useTabletDetector } from "../../utils/customHooks";
import { CelebrityHeading, ImageCol } from "../../Components/Organisms/SubscribedCelebrity/HeroSection/styles";
import {
	CelebrityProfileContainer,
	InfoCol1,
	InfoCol2,
	CelebrityRating,
	SocialLinks,
	CelebrityProfileNav,
	NavItem,
	CelebrityProfileContent,
} from "./styles";
import { CartPopupBody, CartPopupContainer, ShareCelebrityInfo, ShareInput } from "../Default/Navbar/styles";
import starImg from "../../Assets/Images/Category/star-icon.svg";
import instagramImg from "../../Assets/Images/CelebrityProfile/instagram-icon.svg";
import tiktokImg from "../../Assets/Images/CelebrityProfile/tiktok-icon.svg";
import facebookImg from "../../Assets/Images/CelebrityProfile/facebook-icon.svg";
import youtubeImg from "../../Assets/Images/CelebrityProfile/youtube-icon.svg";
import editImg from "../../Assets/Images/CelebrityProfile/edit-icon.svg";
import shareImg from "../../Assets/Images/CelebrityProfile/share-icon.svg";
import copyLinkImg from "../../Assets/Images/CelebrityProfile/copy-link-icon.svg";
import { getPlatformFromUrl } from "../../utils/image";

const socialLinks = {
	tiktok: tiktokImg,
	instagram: instagramImg,
	facebook: facebookImg,
	youtube: youtubeImg,
};

const socialShareLinks = {
	tiktok: "https://www.tiktok.com/share?url=",
	instagram: "https://www.instagram.com/sharer/sharer.php?u=",
	facebook: "https://www.facebook.com/sharer/sharer.php?u=",
	youtube: "https://www.youtube.com/share?url=",
};

const CelebrityContext = createContext(null);

export const useCelebrity = () => {
	return useContext(CelebrityContext);
};

const CelebrityProfileLayout = () => {
	const [current, setCurrent] = useState("video");
	const [sharePopup, setSharePopup] = useState(false);
	const isMobile = useMobileDetector();
	const isTablet = useTabletDetector();
	let navigate = useNavigate();
	const { celebrityId } = useParams();

	const { data: celebrityData } = useQuery(GET_CELEBRITY_QUERY, {
		fetchPolicy: "network-only",
		variables: { celebrityId },
	});

	// const [celebrityProfileImg, setCelebrityProfileImg] = useState(celebrityData?.getCelebrity?.profileImg);

	const [uploadProfileImage] = useMutation(UPLOAD_PROFILE_IMAGE_MUTATION);

	useEffect(() => {
		console.log("sharePopup", sharePopup);
		if (sharePopup) {
			document.documentElement.style.overflow = "hidden";
		} else {
			document.documentElement.style.overflowY = "unset";
		}
	}, [sharePopup]);

	const handleNavClick = (e) => {
		setCurrent(e.target.name);
		navigate(e.target.name);
	};

	const handleImageUpload = async (file) => {
		try {
			if (!file) {
				throw new Error("No file selected");
			}
			if (!celebrityData?.getCelebrity?.id) {
				throw new Error("No celebrity ID available");
			}
			const response = await uploadProfileImage({
				variables: {
					celebrityId: celebrityData.getCelebrity?.id,
					file, // Pass the raw File object directly
				},
			});

			const newImageUrl = response.data.uploadProfileImage.profileImg;
			console.log("Upload successful:", response.data);
			return newImageUrl;
		} catch (error) {
			console.error("Error uploading profile image:", error);
			// Optionally show an error message to the user
		}
	};

	const baseUrl = process.env.NODE_ENV === "production" ? "https://server.fameeo.com" : "http://localhost:8080";

	return (
		<CelebrityContext.Provider value={celebrityData}>
			<CelebrityProfileContainer>
				<CelebrityHeading style={{ marginTop: "35px" }}>
					<div>
						<ImageCol>
							<ProfilePhotoInput
								currentPhoto={celebrityData?.getCelebrity?.profileImg}
								onUpload={(file) => handleImageUpload(file)}
							/>
						</ImageCol>
						<InfoCol1>
							<div>
								<h2>{celebrityData?.getCelebrity.firstName}</h2>
							</div>
							<div>
								<span>{celebrityData?.getCelebrity.bio}</span>
							</div>
							{!isMobile && !isTablet && (
								<SocialLinks>
									{celebrityData?.getCelebrity.socialLinks.map((socialLink, index) => {
										const platform = getPlatformFromUrl(socialLink); // e.g., "instagram"
										const imageSrc = platform ? socialLinks[platform] : null;
										if (!imageSrc) {
											console.warn(`No image found for platform: ${platform}`);
											return null; // Skip rendering if no image is found
										}
										return (
											<Link to={socialLink} key={index}>
												<img loading="lazy" src={imageSrc} alt={platform} />
											</Link>
										);
									})}
								</SocialLinks>
							)}
						</InfoCol1>
					</div>
					{(isMobile || isTablet) && (
						<SocialLinks>
							{celebrityData?.getCelebrity.socialLinks.map((socialLink, index) => {
								const platform = getPlatformFromUrl(socialLink); // e.g., "instagram"
								const imageSrc = platform ? socialLinks[platform] : null;
								if (!imageSrc) {
									console.warn(`No image found for platform: ${platform}`);
									return null; // Skip rendering if no image is found
								}
								return (
									<Link to={socialLink} key={index}>
										<img loading="lazy" src={imageSrc} alt={`Social Media ${index}`} />
									</Link>
								);
							})}
						</SocialLinks>
					)}
					<InfoCol2>
						<CelebrityRating>
							<div>
								<h4>Price</h4>
								<span>AMD {celebrityData?.getCelebrity.price.toLocaleString()}+</span>
							</div>
							<div>
								<h4>Reviews</h4>
								<span>
									<img loading="lazy" src={starImg} alt="Rating Stars" />
									&nbsp;{celebrityData?.getCelebrity.rating}
								</span>
							</div>
							<div>
								<h4>Available For</h4>
								<span>24hr delivery</span>
							</div>
						</CelebrityRating>
						<div>
							<button name="edit" onClick={handleNavClick}>
								Edit Profile
								<img loading="lazy" src={editImg} alt="Edit" />
							</button>
							<button name="share" onClick={() => setSharePopup(true)}>
								Share
								<img loading="lazy" src={shareImg} alt="Share" />
							</button>
						</div>
					</InfoCol2>
				</CelebrityHeading>

				<CelebrityProfileNav>
					<NavItem name={"video"} onClick={handleNavClick} current={current === "video" ? 1 : 0}>
						Video
					</NavItem>
					<NavItem name={"balance"} onClick={handleNavClick} current={current === "balance" ? 1 : 0}>
						Balance
					</NavItem>
					<NavItem name={"wallet"} onClick={handleNavClick} current={current === "wallet" ? 1 : 0}>
						Wallet
					</NavItem>
					<NavItem name={"requests"} onClick={handleNavClick} current={current === "requests" ? 1 : 0}>
						Requests
					</NavItem>
				</CelebrityProfileNav>
				<CelebrityProfileContent>
					<Outlet />
				</CelebrityProfileContent>

				{sharePopup && (
					<CartPopupContainer>
						<CartPopupBody>
							<h2>Share the expertise</h2>
							<p>Showcase Your Talent, Let the World Discover You.</p>
							<div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
								<SocialLinks style={{ marginTop: "32px" }}>
									<Link to={""}>
										<img loading="lazy" src={copyLinkImg} alt="Copy Link" />
									</Link>
									<Link to={"https://www.instagram.com/sharer/sharer.php?u="}>
										<img loading="lazy" src={instagramImg} alt="" />
									</Link>
									<Link to={"https://www.facebook.com/sharer/sharer.php?u="}>
										<img loading="lazy" src={facebookImg} alt="" />
									</Link>
									<Link to={"https://www.youtube.com/share?url="}>
										<img loading="lazy" src={youtubeImg} alt="" />
									</Link>
									<Link to={"https://www.tiktok.com/share?url="}>
										<img loading="lazy" src={tiktokImg} alt="" />
									</Link>
								</SocialLinks>
							</div>
							<ShareInput>
								<input type="text" placeholder="Enter URL" />
								<button>Copy link</button>
							</ShareInput>
							<ShareCelebrityInfo>
								<div>
									<h4>{celebrityData?.getCelebrity.firstName}</h4>
									<span>{celebrityData?.getCelebrity.bio}</span>
								</div>
								<img
									src={`${baseUrl}${celebrityData?.getCelebrity?.profileImg}`}
									alt="Celebrity Profile image"
								/>
							</ShareCelebrityInfo>
							<button onClick={() => setSharePopup(false)}>
								<span>&times;</span>
							</button>
						</CartPopupBody>
					</CartPopupContainer>
				)}
			</CelebrityProfileContainer>
		</CelebrityContext.Provider>
	);
};

export default memo(CelebrityProfileLayout);
