import { memo, useEffect, useState } from "react";
import {
	AboutInputContainer,
	EditContainer,
	EditForm,
	EditSidebar,
	InputRowContainer,
	NewPasswordInput,
	SidebarButton,
	InputRow,
	SocialButtonContainer,
} from "./styles";
import armeniaIcon from "../../../../Assets/Images/SignUp/armenia-icon.svg";
import russiaIcon from "../../../../Assets/Images/SignUp/russia-icon.svg";
import { Checkbox, CheckboxOpen, TelCheckbox, TelInputContainer, TelInputRow } from "../../Auth/SignUp/styles";
import selectArrowIcon from "../../../../Assets/Images/SignUp/chevron-down.svg";
import { useCelebrity } from "../../../../Layouts/CelebrityProfile";
import { useMutation } from "@apollo/client";
import { UPDATE_CELEBRITY_MUTATION } from "../../../../queries/celebrity";
import { validateEmail } from "../../../../utils/email";

const countries = [
	{ code: "+374", name: "Armenia", icon: armeniaIcon, digitsLength: 8 },
	{ code: "+7", name: "Russia", icon: russiaIcon, digitsLength: 12 },
];

const CelebrityProfileEdit = () => {
	const [current, setCurrent] = useState("profile");
	const [charCount, setCharCount] = useState(0);
	const [formData, setFormData] = useState(
		current === "profile"
			? {
					firstName: "",
					lastName: "",
					bio: "",
					email: "",
					tel: "",
					about: "",
				}
			: current === "social"
				? {
						instagram: "",
						facebook: "",
						tiktok: "",
						youtube: "",
					}
				: {
						oldPassword: "",
						newPassword1: "",
						newPassword2: "",
					},
	);
	const [validationErrors, setValidationErrors] = useState({});
	const [isOpen, setIsOpen] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState(countries[0]);

	const celebrityData = useCelebrity();
	const [updateCelebrity] = useMutation(UPDATE_CELEBRITY_MUTATION, {
		onCompleted: (data) => {
			console.log("data", data);
		},
		onError: (error) => {
			console.log("error", error);
		},
	});

	useEffect(() => {
		if (current === "profile") {
			const { firstName, lastName, email, tel, bio, about } = celebrityData?.getCelebrity || {};
			setFormData({
				firstName: firstName || "",
				lastName: lastName || "",
				email: email || "",
				tel: tel || "",
				bio: bio || "",
				about: about || "",
			});
		} else if (current === "social") {
			const newFormData = {};
			celebrityData?.getCelebrity.socialLinks.forEach((socialLink) => {
				const platform = socialLink.split(/[/.]/)[2];
				newFormData[platform] = socialLink;
			});
			setFormData(newFormData);
		} else if (current === "password") {
			setFormData({
				oldPassword: "",
				newPassword1: "",
				newPassword2: "",
			});
		}
	}, [celebrityData?.getCelebrity, current]);

	const regexSocialPatterns = {
		facebook: /^https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9_.-]+$/,
		tiktok: /^https?:\/\/(www\.)?tiktok\.com\/[a-zA-Z0-9_]+$/,
		instagram: /^https?:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9_.-]+$/,
		youtube: /^https?:\/\/(www\.)?youtube\.com\/in\/[a-zA-Z0-9_-]+$/,
	};

	const validateLink = (link) => {
		return Object.values(regexSocialPatterns).some((pattern) => pattern.test(link));
	};

	const validate = () => {
		const newErrors = {};
		const { firstName, lastName, bio, email, tel, about, instagram, facebook, tiktok, youtube, oldPassword, newPassword1, newPassword2 } =
			formData;

		switch (current) {
			case "profile":
				if (!firstName) {
					newErrors.firstName = "First Name is required";
				} else if (firstName && /[^a-zA-Z\s]/.test(firstName)) {
					newErrors.firstName = "First name can only contain letters and spaces";
				}

				// Check if lastName contains only alphabetic characters
				if (!lastName) {
					newErrors.lastName = "Last Name is required";
				} else if (lastName && /[^a-zA-Z\s]/.test(lastName)) {
					newErrors.lastName = "Last name can only contain letters and spaces";
				}

				if (!bio) {
					newErrors.bio = "Bio is required";
				}

				if (!email) {
					newErrors.email = "Email is required";
				} else if (!validateEmail(email)) {
					newErrors.email = "Enter a valid email";
				}

				if (!tel) {
					newErrors.tel = "Phone Number is required";
				} else if (tel.length !== selectedCountry.digitsLength) {
					newErrors.tel = "Phone Number is invalid";
				}

				if (!about) {
					newErrors.about = "About is required";
				}

				break;

			case "social":
				if (instagram && !validateLink(instagram)) {
					newErrors.instagram = "Invalid Instagram link";
				}
				if (facebook && !validateLink(facebook)) {
					newErrors.facebook = "Invalid Facebook link";
				}
				if (tiktok && !validateLink(tiktok)) {
					newErrors.tiktok = "Invalid Tiktok link";
				}
				if (youtube && !validateLink(youtube)) {
					newErrors.youtube = "Invalid Youtube link";
				}

				break;

			case "password":
				if (!oldPassword) {
					newErrors.oldPassword = "Old password is required";
				}

				if (!newPassword1) {
					newErrors.newPassword1 = "Password is required";
				} else if (newPassword1.length <= 6) {
					newErrors.newPassword1 = "New Password must be minimum 6 characters";
				}

				if (!newPassword2) {
					newErrors.newPassword2 = "Password is required";
				} else if (newPassword1 !== newPassword2) {
					newErrors.newPassword2 = "Passwords do not match";
				}

				break;

			default:
				break;
		}

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSidebarClick = (e) => {
		setCurrent(e.target.name);
	};

	const handleSelect = (country) => {
		setSelectedCountry(country);
		setIsOpen(false);
		setFormData((prevData) => ({
			...prevData,
			tel: "",
		}));
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "about") {
			setCharCount(e.target.value.length);
		}

		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (validate()) {
			let variables = {
				celebrityId: celebrityData?.getCelebrity?.id,
			};

			let celebrityInput = {};

			switch (current) {
				case "profile":
					celebrityInput = {
						firstName: formData.firstName,
						lastName: formData.lastName,
						bio: formData.bio || null,
						email: formData.email,
						tel: formData.tel,
						about: formData.about || null,
						// Include other required fields with defaults if necessary
						password: celebrityData?.getCelebrity?.password, // Keep existing password
						socialLinks: celebrityData?.getCelebrity?.socialLinks || [],
						price: celebrityData?.getCelebrity?.price || 0,
						rating: celebrityData?.getCelebrity?.rating || 0,
						ratingCount: celebrityData?.getCelebrity?.ratingCount || 0,
						profileImg: celebrityData?.getCelebrity?.profileImg || null,
						categoryId: celebrityData?.getCelebrity?.categoryId,
						paymentMethods:
							celebrityData?.getCelebrity?.paymentMethods.map((pm) => ({
								paymentType: pm.paymentType,
								cardNumber: pm.cardNumber,
								expirationDate: pm.expirationDate,
							})) || [],
						portfolioVideos: celebrityData?.getCelebrity?.portfolioVideos || [],
					};
					break;

				case "social":
					const updatedSocialLinks = Object.values(formData).filter((link) => link && validateLink(link));
					celebrityInput = {
						firstName: celebrityData?.getCelebrity?.firstName,
						lastName: celebrityData?.getCelebrity?.lastName || null,
						bio: celebrityData?.getCelebrity?.bio || null,
						email: celebrityData?.getCelebrity?.email,
						tel: celebrityData?.getCelebrity?.tel,
						about: celebrityData?.getCelebrity?.about || null,
						password: celebrityData?.getCelebrity?.password, // Keep existing password
						socialLinks: updatedSocialLinks,
						price: celebrityData?.getCelebrity?.price || 0,
						rating: celebrityData?.getCelebrity?.rating || 0,
						ratingCount: celebrityData?.getCelebrity?.ratingCount || 0,
						profileImg: celebrityData?.getCelebrity?.profileImg || null,
						categoryId: celebrityData?.getCelebrity?.categoryId,
						paymentMethods:
							celebrityData?.getCelebrity?.paymentMethods.map((pm) => ({
								paymentType: pm.paymentType,
								cardNumber: pm.cardNumber,
								expirationDate: pm.expirationDate,
							})) || [],
						portfolioVideos: celebrityData?.getCelebrity?.portfolioVideos || [],
					};
					break;

				case "password":
					// variables = {
					// 	...variables,
					// 	requestData: {
					// 		socialLinks: { ...formData },
					// 	},
					// };
					break;

				default:
					break;
			}

			variables = {
				...variables,
				celebrityInput,
			};

			console.log("variables", variables);

			await updateCelebrity({
				variables: variables,
			});
		}
	};

	return (
		<EditContainer>
			<EditSidebar>
				<SidebarButton name="profile" onClick={handleSidebarClick} current={current === "profile"}>
					Edit Profile
				</SidebarButton>
				<SidebarButton name="social" onClick={handleSidebarClick} current={current === "social"}>
					Edit Social Links
				</SidebarButton>
				<SidebarButton name="password" onClick={handleSidebarClick} current={current === "password"}>
					Password
				</SidebarButton>
			</EditSidebar>
			<EditForm current={current} onSubmit={handleSubmit}>
				{current === "profile" ? (
					<>
						<InputRowContainer error={validationErrors.firstName ? 1 : 0}>
							<label htmlFor="firstName">First Name</label>
							<div>
								<input
									type="text"
									name="firstName"
									id="firstName"
									placeholder="Adele"
									value={formData.firstName}
									onChange={handleChange}
								/>
								{validationErrors.firstName && <p>{validationErrors.firstName}</p>}
							</div>
						</InputRowContainer>
						<InputRowContainer error={validationErrors.lastName ? 1 : 0}>
							<label htmlFor="lastName">Last Name</label>
							<div>
								<input
									type="text"
									name="lastName"
									id="lastName"
									placeholder="Laurie Blue Adkins"
									value={formData.lastName}
									onChange={handleChange}
								/>
								{validationErrors.lastName && <p>{validationErrors.lastName}</p>}
							</div>
						</InputRowContainer>
						<InputRowContainer error={validationErrors.bio ? 1 : 0}>
							<label htmlFor="bio">Bio</label>
							<div>
								<input
									type="text"
									name="bio"
									id="bio"
									placeholder="English singer-songwriter"
									value={formData.bio}
									onChange={handleChange}
								/>
								{validationErrors.bio && <p>{validationErrors.bio}</p>}
							</div>
						</InputRowContainer>
						<InputRowContainer error={validationErrors.email ? 1 : 0}>
							<label htmlFor="email">Email</label>
							<div>
								<input
									type="text"
									name="email"
									id="email"
									placeholder="example@gmail.com"
									value={formData.email}
									onChange={handleChange}
								/>
								{validationErrors.email && <p>{validationErrors.email}</p>}
							</div>
						</InputRowContainer>
						<TelInputRow error={validationErrors.tel} page={"becomePartner"}>
							<label htmlFor="tel">Phone</label>
							<div>
								<TelInputContainer error={validationErrors.tel ? 1 : 0}>
									<TelCheckbox onClick={() => setIsOpen(!isOpen)}>
										<Checkbox>
											<img loading="lazy" src={selectedCountry.icon} alt={selectedCountry.name} />
											<img loading="lazy" src={selectArrowIcon} alt="select arrow" />
											{/*<span>{selectedCountry.code}</span>*/}
										</Checkbox>
										{isOpen && (
											<CheckboxOpen>
												{countries.map((country) => (
													<div key={country.code} onClick={() => handleSelect(country)}>
														<img loading="lazy" src={country.icon} alt={country.name} />
														<div>
															<span>{country.code}</span>
															<img loading="lazy" src={selectArrowIcon} alt="select arrow" />
														</div>
													</div>
												))}
											</CheckboxOpen>
										)}
									</TelCheckbox>
									<input
										type="text"
										placeholder="(374) 99 12 00 14"
										name="tel"
										value={formData.tel}
										onChange={handleChange}
										style={{ padding: "12px 16px 12px 58px" }}
									/>
								</TelInputContainer>
								{validationErrors.tel && <p>{validationErrors.tel}</p>}
							</div>
						</TelInputRow>
						<AboutInputContainer error={validationErrors.about ? 1 : 0}>
							<div>
								<label htmlFor="about">About</label>
								<span>{charCount}/1020</span>
							</div>
							<div>
								<textarea
									name="about"
									id="about"
									cols="30"
									rows="10"
									maxLength={1020}
									placeholder="English singer-songwriter"
									value={formData.about}
									onChange={handleChange}
								/>
								{validationErrors.about && <p>{validationErrors.about}</p>}
							</div>
						</AboutInputContainer>
						<div>
							<button type="submit">Save Changes</button>
						</div>
					</>
				) : current === "social" ? (
					<>
						<InputRowContainer error={validationErrors.instagram ? 1 : 0}>
							<label htmlFor="instagram">Instagram</label>
							<div>
								<InputRow>
									<input
										type="text"
										name="instagram"
										id="instagram"
										placeholder="Enter URL"
										value={formData.instagram}
										onChange={handleChange}
									/>
									<button type="submit">Add link</button>
								</InputRow>
								{validationErrors.instagram && <p>{validationErrors.instagram}</p>}
							</div>
						</InputRowContainer>
						<InputRowContainer error={validationErrors.facebook ? 1 : 0}>
							<label htmlFor="facebook">Facebook</label>
							<div>
								<InputRow>
									<input
										type="text"
										name="facebook"
										id="facebook"
										placeholder="Enter URL"
										value={formData.facebook}
										onChange={handleChange}
									/>
									<button type="submit">Add link</button>
								</InputRow>
								{validationErrors.facebook && <p>{validationErrors.facebook}</p>}
							</div>
						</InputRowContainer>
						<InputRowContainer error={validationErrors.tiktok ? 1 : 0}>
							<label htmlFor="tiktok">Tik Tok</label>
							<div>
								<InputRow>
									<input
										type="text"
										name="tiktok"
										id="tiktok"
										placeholder="Enter URL"
										value={formData.tiktok}
										onChange={handleChange}
									/>
									<button type="submit">Add link</button>
								</InputRow>
								{validationErrors.tiktok && <p>{validationErrors.tiktok}</p>}
							</div>
						</InputRowContainer>
						<InputRowContainer error={validationErrors.youtube ? 1 : 0}>
							<label htmlFor="youtube">Youtube</label>
							<div>
								<InputRow>
									<input
										type="text"
										name="youtube"
										id="youtube"
										placeholder="Enter URL"
										value={formData.youtube}
										onChange={handleChange}
									/>
									<button type="submit">Add link</button>
								</InputRow>
								{validationErrors.youtube && <p>{validationErrors.youtube}</p>}
							</div>
						</InputRowContainer>
						<SocialButtonContainer>
							<button type="submit">Save Changes</button>
						</SocialButtonContainer>
					</>
				) : (
					<>
						<InputRowContainer error={validationErrors.oldPassword ? 1 : 0}>
							<label htmlFor="oldPassword">Old Password</label>
							<div>
								<input
									type="text"
									name="oldPassword"
									id="oldPassword"
									placeholder=""
									value={formData.oldPassword}
									onChange={handleChange}
								/>
								{validationErrors.oldPassword && <p>{validationErrors.oldPassword}</p>}
							</div>
						</InputRowContainer>
						<InputRowContainer error={validationErrors.newPassword1 ? 1 : 0}>
							<label htmlFor="newPassword1">New Password</label>
							<NewPasswordInput>
								<div>
									<input
										type="text"
										name="newPassword1"
										id="newPassword1"
										placeholder=""
										value={formData.newPassword1}
										onChange={handleChange}
									/>
									{validationErrors.newPassword1 && <p>{validationErrors.newPassword1}</p>}
								</div>
								<span>Minimum 6 characters</span>
							</NewPasswordInput>
						</InputRowContainer>
						<InputRowContainer error={validationErrors.newPassword2 ? 1 : 0}>
							<label htmlFor="newPassword2">Old Password</label>
							<div>
								<input
									type="text"
									name="newPassword2"
									id="newPassword2"
									placeholder=""
									value={formData.newPassword2}
									onChange={handleChange}
								/>
								{validationErrors.newPassword2 && <p>{validationErrors.newPassword2}</p>}
							</div>
						</InputRowContainer>
						<div>
							<button type="submit">Change</button>
						</div>
					</>
				)}
			</EditForm>
		</EditContainer>
	);
};

export default memo(CelebrityProfileEdit);
