import HeroSection from "../../Components/Organisms/SubscribedCelebrity/HeroSection";
import SubscribedCelebrityInfo from "../../Components/Organisms/SubscribedCelebrity/SubscribedCelebrityInfo";

const SubscribedCelebrityPage = () => {
	return (
		<div>
			<HeroSection />
			<SubscribedCelebrityInfo />
		</div>
	);
};

export default SubscribedCelebrityPage;
