import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	max-width: 1400px;
	position: relative;
	left: 0;
	right: 0;
	margin: 50px auto 0;
	display: flex;
	flex-direction: column;
`;

export const SectionNav = styled.div`
	width: 100%;
	border-bottom: 2px solid #e8e8e8;
	display: flex;
	gap: calc(23px + 1.438vw);

	@media (min-width: 1600px) {
		gap: 46px;
	}
`;

export const NavItem = styled.button`
	margin: 0;
	padding-bottom: 18px;
	font-size: calc(12px + 0.75vw);
	font-weight: 500;
	line-height: calc(14.5px + 0.909vw);
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #000000;
	border-width: 0 0 ${(props) => (props.current ? "3px" : "0")} 0;
	border-style: solid;
	border-color: #000000;
	background: none;
	cursor: pointer;

	@media (min-width: 1600px) {
		font-size: 24px;
		line-height: 29.05px;
	}
`;

export const InfoGrid = styled.div`
	width: 100%;
	margin-top: 50px;
	display: flex;
	flex-wrap: wrap;
	gap: calc(14px + 0.875vw);
	justify-content: center;

	@media (min-width: 1600px) {
		gap: 28px;
	}
`;

export const InfoCard = styled.div`
	width: 48%;
	//max-width: calc(200px + 30.375vw);
	padding: calc(20px + 1.25vw);
	border-radius: 40px;
	display: flex;
	flex-direction: column;
	gap: calc(20px + 1.25vw);
	background-color: #fafafa;
	box-sizing: border-box;

	h3 {
		margin: 0;
		font-size: calc(12px + 0.75vw);
		font-weight: 600;
		line-height: calc(14.5px + 0.909vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 768px) {
		width: 100%;
	}

	@media (min-width: 1600px) {
		max-width: 686px;
		padding: 40px;
		gap: 40px;

		h3 {
			font-size: 24px;
			line-height: 29.05px;
		}
	}
`;

export const CelebrityRating = styled.div`
	width: 100%;
	display: flex;
	background-color: #ffffff;
	border-radius: 30px;
	padding: calc(20.5px + 1.284vw) calc(8px + 0.5vw);
	align-items: center;
	justify-content: space-evenly;
	gap: calc(14px + 0.875vw);
	box-sizing: border-box;

	& > div {
		display: flex;
		flex-direction: column;

		h4 {
			margin: 0 0 8px;
			font-size: calc(8px + 0.5vw);
			font-weight: 400;
			line-height: calc(9.5px + 0.616vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			text-transform: uppercase;
			color: #535862;
		}

		span {
			font-size: calc(11px + 0.688vw);
			font-weight: 500;
			line-height: calc(13px + 0.852vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
			display: flex;
			align-items: center;

			img {
				width: calc(12px + 0.75vw);
				height: calc(12px + 0.75vw);
			}
		}
	}

	@media (min-width: 1600px) {
		padding: 41.5px 16px;
		gap: 28px;

		& > div {
			h4 {
				font-size: 16px;
				line-height: 19.36px;
			}

			span {
				font-size: 22px;
				line-height: 26.63px;

				img {
					width: 24px;
					height: 24px;
				}
			}
		}
	}
`;

export const CelebrityAbout = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(12px + 0.75vw);

	p {
		margin: 0;
		font-size: calc(10px + 0.625vw);
		font-weight: 500;
		line-height: calc(12px + 0.762vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #535862;
	}

	span {
		cursor: pointer;
		font-size: calc(10px + 0.625vw);
		font-weight: 600;
		line-height: calc(12px + 0.762vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
		margin-left: 5px;
	}

	@media (min-width: 1600px) {
		gap: 24px;

		p {
			font-size: 20px;
			line-height: 24.2px;
		}

		span {
			font-size: 20px;
			line-height: 24.2px;
		}
	}
`;

export const VideoReasons = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(12px + 0.75vw);

	& > div {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: calc(10px + 0.625vw);

		span {
			padding: calc(8px + 0.5vw) calc(12px + 0.75vw);
			border-radius: 30px;
			background-color: #ffffff;
			font-size: calc(10px + 0.625vw);
			font-weight: 500;
			line-height: calc(12px + 0.762vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
		}
	}

	@media (min-width: 1600px) {
		gap: 24px;

		& > div {
			gap: 20px;

			span {
				padding: 16px 24px;
				font-size: 20px;
				line-height: 24.2px;
			}
		}
	}
`;

export const PaymentMethods = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(12px + 0.75vw);

	& > div {
		width: 100%;
		display: flex;
		gap: calc(10px + 0.625vw);
		flex-wrap: wrap;
	}

	@media (min-width: 1600px) {
		gap: 24px;

		& > div {
			gap: 20px;
		}
	}
`;

export const UserName = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(10px + 0.625vw);

	input {
		width: 100%;
		padding: calc(5px + 0.313vw) calc(8px + 0.5vw);
		border: 1px solid #a79dad;
		border-radius: 80px;
		font-size: calc(9px + 0.563vw);
		font-weight: 500;
		line-height: calc(10.5px + 0.705vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
		background: none;

		&::placeholder {
			color: #a4a7ae;
		}
	}

	p {
		margin: 0;
		color: #d00306;
		font-size: 14px;
		font-weight: 400;
		line-height: 16.94px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
	}

	& > div {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: calc(5px + 0.344vw);
	}

	@media (min-width: 1600px) {
		gap: 20px;

		input {
			padding: 10px 16px;
			font-size: 18px;
			line-height: 21.78px;
		}

		& > div {
			gap: 10.5px;
		}
	}
`;

export const VideoFromInput = styled.input`
	border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
`;

export const VideoForInput = styled.input`
	border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
`;

export const UserNameButton = styled.button`
	cursor: pointer;
	padding: calc(8px + 0.5vw) calc(12px + 0.75vw);
	border: none;
	border-radius: 30px;
	background-color: ${(props) => (props.current ? "#000000" : "#FFFFFF")};
	font-size: calc(10px + 0.625vw);
	font-weight: 500;
	line-height: calc(12px + 0.762vw);
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: ${(props) => (props.current ? "#FFFFFF" : "#000000")};

	@media (min-width: 1600px) {
		padding: 16px 24px;
		font-size: 20px;
		line-height: 24.2px;
	}
`;

export const OccasionSelect = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(10px + 0.625vw);

	select {
		width: 100%;
		padding: calc(10px + 0.625vw) calc(8px + 0.5vw);
		border: 1px solid #a79dad;
		border-radius: 80px;
		font-size: 18px;
		font-weight: 500;
		line-height: 21.78px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (min-width: 1600px) {
		gap: 20px;

		select {
			padding: 10px 16px;
		}
	}
`;

export const DetailsRequest = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(10px + 0.625vw);
	position: relative;

	& > div {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: calc(10px + 0.625vw);

		textarea {
			width: 100%;
			max-width: 100%;
			min-width: 100%;
			height: 161px;
			min-height: 161px;
			max-height: 161px;
			padding: calc(8px + 0.5vw);
			border: 1px solid #a79dad;
			border-radius: 20px;
			box-sizing: border-box;
			font-family: Inter, sans-serif; //
			font-size: calc(9px + 0.563vw);
			font-weight: 400;
			line-height: calc(10.5px + 0.705vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
			background: none;

			&::placeholder {
				color: #a4a7ae;
			}
		}

		span {
			font-size: calc(9px + 0.563vw);
			font-weight: 400;
			line-height: calc(10.5px + 0.705vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #a4a7ae;
		}
	}

	@media (min-width: 1600px) {
		gap: 20px;

		& > div {
			gap: 20px;

			textarea {
				padding: 16px;
				font-size: 18px;
				line-height: 21.78px;
			}

			span {
				font-size: 18px;
				line-height: 21.78px;
			}
		}
	}
`;

export const ActionButtons = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: calc(4px + 0.25vw);
	margin-top: 30px;

	button {
		padding: calc(9px + 0.563vw) calc(10px + 0.625vw);
		border-radius: 80px;
		font-size: calc(9px + 0.563vw);
		font-weight: 600;
		line-height: calc(13.5px + 0.844vw);
		letter-spacing: -0.011em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		cursor: pointer;
	}

	@media (min-width: 1600px) {
		gap: 8px;

		button {
			padding: 18px 20px;
			font-size: 18px;
			line-height: 27px;
		}
	}
`;

export const CartButton = styled.button`
	background-color: #ffffff;
	border: 1px solid #d6cfdb;
	color: #000000;
	width: 100%;
	max-width: 200px;
`;

export const BookButton = styled.button`
	background-color: #7f56d9;
	color: #ffffff;
	border: none;
	width: 100%;
	max-width: 340px;
`;
