import styled, { keyframes } from "styled-components";

export const Section = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 32px;

	@media (max-width: 768px) {
		gap: 28px;
	}

	@media (min-width: 1600px) {
		gap: 32px;
	}
`;

export const SectionHeading = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(4px + 0.25vw);

	h2 {
		font-size: calc(16px + 1vw);
		font-weight: 500;
		line-height: calc(18px + 1.171vw);
		text-align: center;
		color: #121212;
		margin: 0;
	}

	span {
		font-size: calc(6px + 0.375vw);
		font-weight: 300;
		line-height: calc(6px + 0.532vw);
		text-align: left;
		color: #000000;
	}

	@media (max-width: 768px) {
		gap: 8px;

		h2 {
			font-size: 24px;
			line-height: 29.05px;
		}

		span {
			font-size: 12px;
			line-height: 14.52px;
		}
	}

	@media (min-width: 1600px) {
		gap: 8px;

		h2 {
			font-size: 32px;
			line-height: 38.73px;
		}

		span {
			font-size: 12px;
			line-height: 14.52px;
		}
	}
`;

// export const SocialSignIn = styled.div`
// 	max-width: 400px;
// 	width: 100%;
// 	display: flex;
// 	flex-direction: column;
// 	gap: calc(12px + 0.75vw);
//
// 	& > a {
// 		border: 1px solid #d6cfdb;
// 		border-radius: 80px;
// 		padding: calc(7px + 0.438vw);
// 		display: flex;
// 		justify-content: center;
// 		gap: calc(5px + 0.313vw);
// 		width: 100%;
// 		box-sizing: border-box;
// 		text-decoration: none;
//
// 		span {
// 			font-size: calc(8px + 0.5vw);
// 			font-weight: 500;
// 			line-height: calc(12px + 0.75vw);
// 			letter-spacing: -0.011em;
// 			text-align: left;
// 			color: #1c1c1c;
// 		}
// 	}
//
// 	& > div {
// 		display: flex;
// 		align-items: center;
// 		justify-content: space-between;
// 		gap: calc(2px + 0.125vw);
//
// 		span:nth-child(odd) {
// 			width: 100%;
// 			height: 1px;
// 			background-color: #d6cfdb;
// 		}
//
// 		span:nth-child(even) {
// 			font-size: calc(7px + 0.438vw);
// 			font-weight: 300;
// 			line-height: calc(10px + 0.625vw);
// 			letter-spacing: 0.30000001192092896px;
// 			text-align: center;
// 			color: #121212;
// 		}
// 	}
//
// 	@media (min-width: 1600px) {
// 		gap: 24px;
//
// 		& > div:first-child {
// 			padding: 14px;
// 			gap: 10px;
//
// 			span {
// 				font-size: 16px;
// 				line-height: 24px;
// 			}
// 		}
//
// 		& > div:last-child {
// 			gap: 4px;
//
// 			span:nth-child(even) {
// 				font-size: 14px;
// 				line-height: 20px;
// 			}
// 		}
// 	}
// `;

export const SignInForm = styled.form`
	max-width: 400px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(6px + 0.375vw);

	@media (max-width: 768px) {
		gap: 16px;
	}

	@media (min-width: 1600px) {
		gap: 12px;
	}
`;

export const SignInInputs = styled.div`
	position: relative;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: calc(12px + 0.75vw);
	text-align: end;

	input {
		width: 100%;
		box-sizing: border-box;
		position: relative;
		padding: 16px;
		border: 1px solid #d6cfdb;
		border-radius: 80px;
		gap: 32px;

		&:active,
		&:focus,
		&:focus-visible,
		&:target {
			background-color: #ffffff;
			border: 1px solid #9747ff;
		}

		&::placeholder {
			font-size: calc(7px + 0.438vw);
			font-weight: 300;
			line-height: calc(8px + 0.526vw);
			text-align: left;
			color: #717680;
		}
	}

	& > div {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		gap: 6px;
	}

	//& > div > div {
	//	width: 100%;
	//	box-sizing: border-box;
	//	position: relative;
	//	display: flex;
	//	align-items: center;
	//
	//	img {
	//		position: absolute;
	//		right: 0;
	//		margin-right: 14px;
	//	}
	//}

	// errors

	p {
		font-size: calc(6px + 0.375vw);
		font-weight: 400;
		line-height: calc(7px + 0.441vw);
		text-align: left;
		color: #d00306;
		margin: 0 0 0 6px;
	}

	@media (max-width: 768px) {
		gap: 28px;

		input {
			&::placeholder {
				font-size: 14px;
				line-height: 16.94px;
			}
		}
	}

	@media (min-width: 1600px) {
		input {
			padding: 16px;

			&::placeholder {
				font-size: 14px;
				line-height: 16.41px;
			}
		}

		& > div {
			img {
				margin-right: 14px;
			}
		}

		p {
			font-size: 12px;
			line-height: 14.06px;
		}
	}
`;

export const TelOrEmailInput = styled.input`
	border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
`;

// export const PasswordInput = styled.input`
// 	border: 1px solid
// 		${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
// `;

export const SignInButton = styled.button`
	cursor: pointer;
	margin-top: 4px;
	padding: calc(7px + 0.438vw);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	border-radius: 80px;
	border: none;
	background-color: ${(props) => (props.loading ? "#9E77ED" : "#7f56d9")};

	font-size: calc(8px + 0.5vw);
	font-weight: 500;
	line-height: calc(8px + 0.672vw);
	text-align: left;
	color: #ffffff;

	@media (max-width: 768px) {
		padding: 16px;
		font-size: 16px;
		line-height: 19.36px;
		margin-top: 0;
	}

	@media (min-width: 1600px) {
		margin-top: 4px;
		padding: 14px;
		font-size: 16px;
		line-height: 18.75px;
	}
`;

const Spin = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`;

export const Spinner = styled.div`
	width: 14px;
	height: 14px;
	border-radius: 90px;
	border: 3px solid #ffffff;
	background: transparent;
	animation: ${Spin} 2s linear infinite;
`;

export const SignUpLink = styled.span`
	display: flex;
	gap: 6px;
	font-size: calc(7px + 0.438vw);
	font-weight: 400;
	line-height: calc(10px + 0.625vw);
	letter-spacing: 0.30000001192092896px;
	text-align: center;
	color: #121212;

	a {
		color: #7f56d9;
	}

	@media (max-width: 768px) {
		font-size: 12px;
		line-height: 14.52px;
	}

	@media (min-width: 1600px) {
		gap: 6px;
		font-size: 14px;
		line-height: 20px;
	}
`;
