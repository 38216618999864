import styled from "styled-components";

export const CelebrityWallet = styled.div`
	width: 100%;
	max-width: 1400px;
	position: relative;
	left: 0;
	right: 0;
	margin: 0 auto;
	display: flex;
	justify-content: center;

	h2 {
		margin: 0;
		font-size: calc(16px + 1vw);
		font-weight: 600;
		line-height: calc(19px + 1.233vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 768px) {
		font-size: 22px;
		line-height: 26.63px;
	}

	@media (min-width: 1600px) {
		h2 {
			font-size: 32px;
			line-height: 38.73px;
		}
	}
`;

export const PaymentContainer = styled.div`
	margin-top: 60px;
	width: 100%;
	max-width: 520px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;

	@media (max-width: 768px) {
		gap: 24px;
	}
`;

export const PaymentHeading = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;

	@media (max-width: 768px) {
		justify-content: flex-start;
	}
`;

export const WalletContainer = styled.div`
	margin-top: 60px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const WalletHeading = styled.div`
	width: 100%;
	display: flex;
`;

export const WalletCard = styled.div`
	padding: 55.5px 32px;
	border-radius: 20px;
	background-color: #ffffff;
	width: 100%;
	max-width: 440px;
	box-shadow: 0 8px 18px 0 #f6f5f7b2;
	display: flex;
	flex-direction: column;
	gap: 12px;

	@media (max-width: 768px) {
		padding: 16px 20px;
		max-width: 280px;
	}
`;

export const AddPaymentMethod = styled.div`
	display: flex;
	flex-direction: column;
	gap: 28px;
	width: 100%;

	h3 {
		margin: 0;
		font-size: 24px;
		font-weight: 400;
		line-height: 29.05px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	span {
		font-size: 18px;
		font-weight: 500;
		line-height: 21.78px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 768px) {
		gap: 12px;
		max-width: 280px;

		h3 {
			font-weight: 400;
			font-size: 16px;
			line-height: 19.36px;
			letter-spacing: 0;
		}

		span {
			font-weight: 500;
			font-size: 12px;
			line-height: 14.52px;
			letter-spacing: 0;
		}
	}
`;

export const CardsInfo = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	padding: 8px 16px;
	box-sizing: border-box;
	border: 1px solid #fafafa;
	border-radius: 40px;

	img {
		width: 32px;
		height: auto;
	}

	span {
		//font-family: IBM Plex Sans;
		font-size: 12px;
		font-weight: 400;
		line-height: 15.6px;
		text-align: center;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	input[type="checkbox"] {
		position: absolute;
		right: 10px;
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		width: 16px;
		height: 16px;
		border-radius: 50px;
		border: 1px solid #7f56d9;
		cursor: pointer;

		&:checked {
			background: #7f56d9;
		}
	}

	@media (max-width: 768px) {
		img {
			width: 24px;
			height: 12px;
		}

		span {
			font-size: 8px;
			line-height: 9.68px;
		}

		input {
			width: 10px;
			height: 10px;
		}
	}
`;

export const WalletButton = styled.button`
	padding: 16px 32px;
	border: none;
	background-color: #7f56d9;
	border-radius: 80px;
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
	letter-spacing: -0.011em;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #ffffff;
	cursor: pointer;

	@media (max-width: 768px) {
		padding: 15.5px 20px;
		font-size: 14px;
		line-height: 16.94px;

		img {
			width: 15px;
			height: 15px;
		}
	}
`;
