import { useState } from "react";
import {
	AddPaymentMethod,
	CardsInfo,
	CelebrityWallet,
	PaymentContainer,
	PaymentHeading,
	WalletButton,
	WalletCard,
	WalletContainer,
	WalletHeading,
} from "./styles";
import {
	CardNumberRow,
	CVVRow,
	ExpirationDateRow,
	FormRow,
	PayButton,
	PaymentForm,
	SaveCardCheckbox,
} from "../../../Organisms/Booking/BookingOrder/styles";
import checkboxIcon from "../../../../Assets/Images/SignUp/checkbox-checked.svg";
import addImg from "../../../../Assets/Images/CelebrityProfile/add-icon.svg";
import visaImg from "../../../../Assets/Images/UserCelebrity/visa-icon.svg";
import mastercardImg from "../../../../Assets/Images/UserCelebrity/mastercard-icon.svg";
import paypalImg from "../../../../Assets/Images/UserCelebrity/paypal-icon.svg";
import applepayImg from "../../../../Assets/Images/UserCelebrity/applepay-icon.svg";
import { Link, useParams } from "react-router-dom";
import { useCelebrity } from "../../../../Layouts/CelebrityProfile";
import { normalizePaymentType } from "../../../../utils/image";

// const paymentMethods = [];

const payments = {
	visa: visaImg,
	mastercard: mastercardImg,
	paypal: paypalImg,
	applepay: applepayImg,
};

const CelebrityProfileWallet = () => {
	// const [paymentMethods, setPaymentMethods] = useState([
	// 	{
	// 		paymentType: "mastercard",
	// 		bankName: "Axis",
	// 		cardNumber: "1234123412348395",
	// 		expirationDate: "2020-05-01",
	// 	},
	// 	{
	// 		paymentType: "visa",
	// 		bankName: "HDFC",
	// 		cardNumber: "1234123412346246",
	// 		expirationDate: "2020-05-01",
	// 	},
	// ]);
	const [newPaymentMethod, setNewPaymentMethod] = useState(false);
	const [checkboxChecked, setCheckboxChecked] = useState(false);
	const [formData, setFormData] = useState({
		cardNumber: "",
		expirationDate: "",
		cvv: "",
	});
	const [validationErrors, setValidationErrors] = useState({});
	const celebrityData = useCelebrity();

	console.log("celebrityData paymentMethods", celebrityData?.getCelebrity?.paymentMethods);

	const validate = () => {
		const newErrors = {};
		const { cardNumber, expirationDate, cvv } = formData;

		// Card Number Validation
		const cardNumberRaw = cardNumber.replace(/\s/g, ""); // Remove spaces
		if (!cardNumberRaw) {
			newErrors.cardNumber = "Card number is required";
		} else if (cardNumberRaw.length !== 16 || !/^\d{16}$/.test(cardNumberRaw)) {
			newErrors.cardNumber = "Use a valid card number";
		}

		// Expiration Date Validation
		if (!expirationDate) {
			newErrors.expirationDate = "Expiration date is required";
		} else if (!/^\d{2}\/\d{2}$/.test(expirationDate)) {
			newErrors.expirationDate = "Expiration month or year is incorrect";
		} else {
			const [month, year] = expirationDate.split("/").map(Number);
			if (month < 1 || month > 12) {
				newErrors.expirationDate = "Expiration month or year is incorrect";
			}
			const currentYear = new Date().getFullYear() % 100; // Last two digits of the current year
			if (year < currentYear) {
				newErrors.expirationDate = "Year must be current or in the future";
			}
		}

		// CVV Validation
		if (!cvv) {
			newErrors.cvv = "CVV is required";
		} else if (cvv.length !== 3 || !/^\d{3}$/.test(cvv)) {
			newErrors.cvv = "Enter a valid 3-digit CVV";
		}

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		let formattedValue = value;

		if (name === "cardNumber") {
			// Remove all non-numeric characters
			formattedValue = value.replace(/\D/g, "");
			// Add a space after every 4 characters
			formattedValue = formattedValue.replace(/(\d{4})/g, "$1 ").trim();
		}

		if (name === "expirationDate") {
			// Remove all non-numeric characters
			formattedValue = value.replace(/\D/g, "");
			// Format as MM/YY
			if (formattedValue.length <= 2) {
				// First two digits
				formattedValue = formattedValue;
			} else if (formattedValue.length <= 4) {
				// Add a slash after the first two digits
				formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
			} else {
				// Restrict to MM/YY format
				formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}`;
			}
		}

		if (name === "cvv") {
			// Remove all non-numeric characters and limit to 3 digits
			formattedValue = value.replace(/\D/g, "").slice(0, 3);
		}

		setFormData((prevData) => ({
			...prevData,
			[name]: formattedValue,
		}));

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (validate()) {
			// Proceed with form submission logic
			// setPaymentMethods((prevData) => [...prevData, formData]);
			setNewPaymentMethod(false);
			setFormData({});
		}
	};

	return (
		<CelebrityWallet>
			{/*paymentMethods.length === 0 || */}
			{newPaymentMethod ? (
				<PaymentContainer>
					<PaymentHeading>
						<h2>Add new payment method</h2>
					</PaymentHeading>
					<PaymentForm onSubmit={handleSubmit}>
						<FormRow>
							<CardNumberRow error={validationErrors.cardNumber ? 1 : 0}>
								<label htmlFor="cardNumber">Card Number</label>
								<div>
									<input
										type="text"
										name="cardNumber"
										id="cardNumber"
										maxLength="19"
										placeholder="1234 5678 9101 1121"
										value={formData.cardNumber}
										onChange={handleChange}
									/>
									{validationErrors.cardNumber && <p>{validationErrors.cardNumber}</p>}
								</div>
							</CardNumberRow>
						</FormRow>
						<div>
							<FormRow>
								<ExpirationDateRow error={validationErrors.expirationDate ? 1 : 0}>
									<label htmlFor="expirationDate">Expiration Date</label>
									<div>
										<input
											type="text"
											name="expirationDate"
											id="expirationDate"
											placeholder="MM/YY"
											value={formData.expirationDate}
											onChange={handleChange}
										/>
										{validationErrors.expirationDate && <p>{validationErrors.expirationDate}</p>}
									</div>
								</ExpirationDateRow>
								<CVVRow error={validationErrors.cvv ? 1 : 0}>
									<label htmlFor="cvv">CVV</label>
									<div>
										<input
											type="text"
											name="cvv"
											id="cvv"
											placeholder="123"
											maxLength="19"
											value={formData.cvv}
											onChange={handleChange}
										/>
										{validationErrors.cvv && <p>{validationErrors.cvv}</p>}
									</div>
								</CVVRow>
							</FormRow>
							<SaveCardCheckbox>
								<span>
									{checkboxChecked ? (
										<img
											src={checkboxIcon}
											alt=""
											onClick={() => setCheckboxChecked(!checkboxChecked)}
										/>
									) : (
										<span onClick={() => setCheckboxChecked(!checkboxChecked)}></span>
									)}
									Save card details
								</span>
							</SaveCardCheckbox>
						</div>
						<PayButton type="submit">Connect Card</PayButton>
					</PaymentForm>
				</PaymentContainer>
			) : (
				<WalletContainer>
					<WalletHeading>
						<h2>Billings & Payments</h2>
					</WalletHeading>
					<WalletCard>
						{celebrityData?.getCelebrity?.paymentMethods.length === 0 ? (
							<AddPaymentMethod>
								<h3>Add a billing method</h3>
								<span>Payment Card</span>
							</AddPaymentMethod>
						) : (
							celebrityData?.getCelebrity?.paymentMethods.map((payment, index) => {
								const paymentType = normalizePaymentType(payment.paymentType); // Normalize payment type
								const imageSrc = paymentType ? payments[paymentType] : null;
								if (!imageSrc) {
									console.warn(`No image found for payment type: ${paymentType}`);
									return null; // Skip rendering if no image is found
								}
								return (
									<CardsInfo key={index}>
										<img loading="lazy" src={imageSrc} alt={payment.paymentType} />
										<span>Axis Bank **** **** **** {payment.cardNumber.slice(-4)}</span>
										<input type="checkbox" />
									</CardsInfo>
								);
							})
						)}
					</WalletCard>
					<div>
						<WalletButton onClick={() => setNewPaymentMethod(true)}>
							<img loading="lazy" src={addImg} alt="Add new payment method" />
							New payment method
						</WalletButton>
					</div>
				</WalletContainer>
			)}
		</CelebrityWallet>
	);
};

export default CelebrityProfileWallet;
