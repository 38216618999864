import styled from "styled-components";

export const CelebrityProfileContainer = styled.section`
	width: 100%;
	max-width: 1400px;
	//position: relative;
	left: 0;
	right: 0;
	margin: 0 auto;
	overflow-x: hidden;

	//& > div:first-child {
	//	position: absolute;
	//	top: 0;
	//	left: 0;
	//	width: 100vw;
	//	height: 370px;
	//	background: linear-gradient(0deg, #f4e3ff 0%, #ffffff 100%);
	//	z-index: -1;
	//}
`;

export const InfoCol = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const InfoCol1 = styled.div`
	display: flex;
	flex-direction: column;
	gap: 18px;

	h2 {
		margin: 0;
		font-size: calc(16px + 1vw);
		font-weight: 600;
		line-height: calc(19px + 1.233vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	span {
		font-size: calc(12px + 0.75vw);
		font-weight: 400;
		line-height: calc(14.5px + 0.909vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 996px) {
		width: 100%;
		align-items: flex-start;
		gap: 8px;

		h2 {
			font-size: 22px;
			line-height: 26.63px;
		}

		span {
			font-size: 14px;
			line-height: 16.94px;
		}
	}

	@media (min-width: 1600px) {
		gap: 18px;

		h2 {
			font-size: 32px;
			line-height: 38.73px;
		}

		span {
			font-size: 24px;
			line-height: 29.05px;
		}
	}
`;

export const InfoCol2 = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(14px + 0.875vw);
	align-items: flex-end;

	& > div:nth-child(2) {
		display: flex;
		gap: calc(14px + 0.875vw);

		button {
			border-radius: 80px;
			font-size: calc(9px + 0.563vw);
			font-weight: 600;
			line-height: calc(13.5px + 0.844vw);
			letter-spacing: -0.011em;
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #ffffff;
			white-space: nowrap;
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: calc(5px + 0.313vw);

			img {
				width: calc(11px + 0.688vw);
				height: calc(11px + 0.688vw);
			}

			&:first-child {
				padding: calc(5.25px + 0.328vw) calc(19.25px + 1.203vw);
				background-color: #7f56d9;
				border: 1px solid #7f56d9;
			}

			&:nth-child(2) {
				padding: calc(5.25px + 0.328vw) calc(29.25px + 1.828vw);
				background-color: #000000;
				border: 1px solid #000000;
			}
		}
	}

	@media (max-width: 1400px) {
		align-items: flex-start;

		& > div:nth-child(2) {
			gap: calc(2px + 0.875vw);
		}
	}

	@media (max-width: 1200px) {
		& > div:nth-child(2) {
			flex-direction: column;

			button {
				padding: calc(4px + 0.328vw) calc(14px + 1.203vw);
				font-size: 14px;
				line-height: 16.94px;
			}
		}
	}

	@media (max-width: 996px) {
		& > div:nth-child(2) {
			flex-direction: row;
		}
	}

	@media (max-width: 768px) {
		& > div:nth-child(2) {
			button {
				padding: 8px 20px;
				font-size: 14px;
				line-height: 16.94px;

				& > img {
					width: 15px;
					height: 15px;
				}
			}
		}
	}

	@media (min-width: 1600px) {
		gap: 28px;

		& > div:nth-child(2) {
			gap: 28px;

			button {
				font-size: 18px;
				line-height: 27px;
				gap: 10px;

				&:first-child {
					padding: 10.5px 38.5px;
				}

				&:nth-child(2) {
					padding: 10.5px 59.5px;
				}
			}
		}
	}
`;

export const CelebrityRating = styled.div`
	width: 100%;
	display: flex;
	background-color: #fafafa;
	border-radius: 30px;
	padding: 18px 31.5px;
	align-items: center;
	justify-content: space-evenly;
	gap: calc(14px + 0.875vw);
	box-sizing: border-box;

	& > div {
		display: flex;
		flex-direction: column;

		&:last-child {
			margin-left: 67px;
		}

		h4 {
			margin: 0 0 8px;
			font-size: calc(8px + 0.5vw);
			font-weight: 400;
			line-height: calc(9.5px + 0.616vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			text-transform: uppercase;
			color: #535862;
		}

		span {
			font-size: calc(11px + 0.688vw);
			font-weight: 500;
			line-height: calc(13px + 0.852vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
			display: flex;
			align-items: center;

			img {
				width: calc(12px + 0.75vw);
				height: calc(12px + 0.75vw);
			}
		}
	}

	@media (max-width: 1400px) {
		justify-content: space-between;
		& > div {
			&:last-child {
				margin-left: 0;
			}
		}
	}

	@media (max-width: 996px) {
		padding: 16.5px 20px;
		border-radius: 16px;

		& > div {
			h4 {
				font-size: 10px;
				line-height: 12.1px;
			}

			span {
				font-size: 14px;
				line-height: 16.94px;

				img {
					width: 18px;
					height: 18px;
				}
			}
		}
	}

	@media (max-width: 768px) {
		& > div {
			&:last-child {
				margin-left: 0;
			}
		}
	}

	@media (min-width: 1600px) {
		padding: 18px 31.5px;
		gap: 28px;

		& > div {
			h4 {
				font-size: 16px;
				line-height: 19.36px;
			}

			span {
				font-size: 22px;
				line-height: 26.63px;

				img {
					width: 24px;
					height: 24px;
				}
			}
		}
	}
`;

export const SocialLinks = styled.div`
	display: flex;
	gap: 12px;

	img {
		width: calc(25px + 1.875vw);
		height: calc(25px + 1.875vw);
	}

	@media (max-width: 996px) {
		gap: 10px;
	}

	@media (max-width: 768px) {
		img {
			width: 30px;
			height: 30px;
		}
	}

	@media (min-width: 1600px) {
		img {
			width: 55px;
			height: 55px;
		}
	}
`;

export const CelebrityProfileNav = styled.div`
	width: 100%;
	//border-bottom: 2px solid #e8e8e8;
	display: flex;
	gap: 40px;
	margin-top: 47px;
	position: relative;

	&:before {
		content: "";
		width: 100%;
		height: 2px;
		background-color: #e8e8e8;
		position: absolute;
		left: 0;
		bottom: 0;
		border-radius: 3px;
	}

	@media (max-width: 768px) {
		gap: 28px;
	}

	@media (min-width: 1600px) {
		gap: 40px;
	}
`;

export const NavItem = styled.button`
	margin: 0;
	padding-bottom: 20px;
	font-size: 16px;
	font-weight: ${(props) => (props.current ? "500" : "400")};
	line-height: 19.36px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: ${(props) => (props.current ? "#000000" : "#454545")};
	border: none;
	background: none;
	cursor: pointer;
	position: relative;

	&:before {
		content: "";
		width: 100%;
		height: 2px;
		background-color: #000000;
		visibility: ${(props) => (props.current ? "visible" : "hidden")};
		position: absolute;
		left: 0;
		bottom: 0;
		border-radius: 3px;
	}

	@media (max-width: 768px) {
		font-size: 14px;
		line-height: 16.94px;
		padding-bottom: 16px;
	}

	@media (min-width: 1600px) {
		font-size: 16px;
		line-height: 19.36px;
	}
`;

export const CelebrityProfileContent = styled.div`
	width: 100%;
	position: relative;
`;
