import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 32px;

	@media (max-width: 768px) {
		gap: 28px;
	}

	@media (min-width: 1600px) {
		gap: 32px;
	}
`;

export const SectionHeading = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: calc(4px + 0.25vw);

	h2 {
		font-size: calc(16px + 1vw);
		font-weight: 500;
		line-height: calc(18px + 1.171vw);
		text-align: center;
		color: #121212;
		margin: 0;
	}

	span {
		font-size: calc(6px + 0.375vw);
		font-weight: 300;
		line-height: calc(6px + 0.532vw);
		text-align: left;
		color: #000000;
	}

	@media (max-width: 768px) {
		gap: 8px;

		h2 {
			font-size: 24px;
			line-height: 29.05px;
		}

		span {
			font-size: 12px;
			line-height: 14.52px;
		}
	}

	@media (min-width: 1600px) {
		gap: 8px;

		h2 {
			font-size: 32px;
			line-height: 38.73px;
		}

		span {
			font-size: 12px;
			line-height: 14.52px;
		}
	}
`;

export const SignUpForm = styled.form`
	max-width: 400px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(8px + 0.375vw);

	// errors

	p {
		font-size: 12px;
		font-weight: 400;
		line-height: 14.06px;
		text-align: left;
		color: #d00306;
		margin: 0 0 0 6px;
	}

	@media (max-width: 768px) {
		gap: 16px;
	}

	@media (min-width: 1600px) {
		gap: 16px;

		& > div {
			gap: 24px;
		}
	}
`;

export const SignUpInputs = styled.div`
	input {
		width: 100%;
		box-sizing: border-box;
		position: relative;
		padding: 15.5px 16px;
		//border: 1px solid #d6cfdb;
		border: none;
		border-radius: 80px;

		&:active,
		&:focus,
		&:focus-visible,
		&:target {
			background-color: #ffffff;
			border: 1px solid #9747ff;
		}

		&::placeholder {
			font-size: calc(7px + 0.438vw);
			font-weight: 300;
			line-height: calc(8px + 0.526vw);
			text-align: left;
		}
	}

	& > div {
		width: 100%;
		box-sizing: border-box;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 32px;
	}

	@media (max-width: 768px) {
		& > div {
			gap: 28px;
		}

		input {
			padding: 16px;

			&::placeholder {
				font-size: 14px;
				line-height: 16.94px;
			}
		}
	}

	@media (min-width: 1600px) {
		input {
			padding: 15.5px 16px;

			&::placeholder {
				font-size: 14px;
				line-height: 16.41px;
			}
		}

		& > div:first-child {
			gap: 32px;
		}
	}
`;

export const EmailInputRow = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;

	input {
		border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
	}

	p {
		margin-top: 6px;
	}
`;

export const TelInputRow = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 6px;

	input {
		width: 100%;
		border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
		box-sizing: border-box;
		//padding: 12px 16px 12px 54px;
	}

	@media (min-width: 1600px) {
		gap: 6px;
	}
`;

export const TelInputContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	& > div {
		height: 100%;

		display: flex;
		align-items: center;

		&:first-child {
			padding-left: 16px;
		}

		&:nth-child(2) {
			span {
				font-size: calc(7px + 0.438vw);
				font-weight: 300;
				line-height: calc(8px + 0.526vw);
				text-align: left;
			}
		}
	}
`;

export const TelCheckbox = styled.div`
	position: absolute;
	left: 0;
	z-index: 1;
	display: flex;
	align-items: center;
`;

export const Checkbox = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	max-height: 48px;
	font-size: 14px;
	font-weight: 300;
	line-height: 16.94px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #121212;

	img:first-child {
		margin-right: 4px;
		pointer-events: none;
	}

	@media (max-width: 768px) {
		font-size: 14px;
		line-height: 16px;
	}
`;

export const CheckboxOpen = styled.div`
	position: absolute;
	top: 48px;
	left: 0;
	width: 100%;
	background: #ffffff;
	border: 1px solid #d6cfdb;
	border-radius: 20px;
	z-index: 1;
	min-width: 100px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	& > div {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: calc(6.5px + 0.406vw) calc(7.5px + 0.469vw);
		cursor: pointer;

		& > img {
			margin-right: 4px;
		}

		& > div {
			display: flex;

			img {
				transform: rotate(180deg);
			}
		}
	}

	@media (min-width: 1600px) {
		& > div {
			padding: 13px 15px;
		}
	}
`;

export const TermsAndPrivacy = styled.div`
	span {
		display: flex;
		align-items: center;
		gap: calc(4px + 0.25vw);
		font-size: calc(7px + 0.438vw);
		font-weight: 400;
		line-height: calc(8px + 0.559vw);
		text-align: left;

		span {
			width: 21px;
			height: 20px;
			border: 1px solid #7f56d9;
			border-radius: 4px;
			box-sizing: border-box;
		}

		a {
			color: #9c4ccf;
		}
	}

	@media (max-width: 768px) {
		span {
			gap: 8px;
			font-size: 14px;
			line-height: 16.94px;

			span {
				width: 21px;
				height: 20px;
			}
		}
	}

	@media (min-width: 1600px) {
		span {
			gap: 8px;
			font-size: 14px;
			line-height: 16.94px;

			span {
				width: 21px;
				height: 20px;
			}
		}
	}
`;

export const SignUpButton = styled.button`
	cursor: ${(props) => props.checkboxChecked && "pointer"};
	margin-top: 4px;
	padding: calc(7px + 0.438vw);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	border-radius: 80px;
	border: none;
	background-color: ${(props) => (props.checkboxChecked ? "#7f56d9" : "#9E77ED")};

	font-size: calc(8px + 0.5vw);
	font-weight: 500;
	line-height: calc(8px + 0.672vw);
	text-align: left;
	color: #ffffff;

	@media (max-width: 768px) {
		padding: 16px;
		font-size: 16px;
		line-height: 19.36px;
	}

	@media (min-width: 1600px) {
		button {
			margin-top: 4px;
			padding: 14px;

			font-size: 16px;
			line-height: 18.75px;
		}
	}
`;

export const SignInLink = styled.span`
	display: flex;
	gap: calc(3px + 0.188vw);
	font-size: calc(7px + 0.438vw);
	font-weight: 400;
	line-height: calc(10px + 0.625vw);
	letter-spacing: 0.30000001192092896px;
	text-align: center;
	color: #121212;

	a {
		color: #9c4ccf;
	}

	@media (max-width: 768px) {
		gap: 6px;
		font-size: 14px;
		line-height: 16.94px;
	}

	@media (min-width: 1600px) {
		gap: 6px;
		font-size: 14px;
		line-height: 20px;
	}
`;
