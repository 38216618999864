import { useState } from "react";
import {
	BioInputContainer,
	EmailInputContainer,
	FirstNameInputContainer,
	LastNameInputContainer,
	ProfileForm,
	Section,
	SectionHeading,
	CustomerImg,
	FormButton,
} from "./styles";
import cameraImg from "../../../../Assets/Images/CelebrityProfile/camera-icon.svg";
import userImg from "../../../../Assets/Images/UserProfile/user-img.webp";
import { Checkbox, CheckboxOpen, TelCheckbox, TelInputContainer, TelInputRow } from "../../../Molecules/Auth/SignUp/styles";
import selectArrowIcon from "../../../../Assets/Images/SignUp/chevron-down.svg";
import armeniaIcon from "../../../../Assets/Images/SignUp/armenia-icon.svg";
import russiaIcon from "../../../../Assets/Images/SignUp/russia-icon.svg";
import celebrityImg1 from "../../../../Assets/Images/UserCelebrity/celebrity1.webp";
import ProfilePhotoInput from "../../../Molecules/CelebrityProfile/CelebrityProfileImageInput";
import { validateEmail } from "../../../../utils/email";

const countries = [
	{ code: "+374", name: "Armenia", icon: armeniaIcon, digitsLength: 8 },
	{ code: "+7", name: "Russia", icon: russiaIcon, digitsLength: 12 },
];

const CustomerProfileForm = () => {
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		bio: "",
		email: "",
		tel: "",
	});
	const [validationErrors, setValidationErrors] = useState({});
	const [isOpen, setIsOpen] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState(countries[0]);

	const validate = () => {
		const newErrors = {};
		const { firstName, lastName, bio, email, tel } = formData;

		if (!firstName) {
			newErrors.firstName = "First Name is required";
		} else if (firstName && /[^a-zA-Z\s]/.test(firstName)) {
			newErrors.firstName = "First name can only contain letters and spaces";
		}

		// Check if lastName contains only alphabetic characters
		if (!lastName) {
			newErrors.lastName = "Last Name is required";
		} else if (lastName && /[^a-zA-Z\s]/.test(lastName)) {
			newErrors.lastName = "Last name can only contain letters and spaces";
		}

		if (!bio) {
			newErrors.bio = "Bio is required";
		}

		if (!email) {
			newErrors.email = "Email is required";
		} else if (!validateEmail(email)) {
			newErrors.email = "Enter a valid email";
		}

		if (!tel) {
			newErrors.tel = "Phone Number is required";
		} else if (tel.length !== selectedCountry.digitsLength) {
			newErrors.tel = "Phone Number is invalid";
		}

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "tel") {
			const regex = new RegExp(`^[0-9]{0,${selectedCountry.digitsLength}}$`);
			if (regex.test(value)) {
				setFormData((prevData) => ({
					...prevData,
					[name]: value,
				}));
			}
		} else if (name === "firstName" || name === "lastName") {
			setFormData((prevData) => ({
				...prevData,
				[name]: value.replace(/[^a-zA-Z\s]/g, ""),
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSelect = (country) => {
		setSelectedCountry(country);
		setIsOpen(false);
		setFormData((prevData) => ({
			...prevData,
			tel: "",
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (validate()) {
			// Proceed with form submission logic
		}
	};

	return (
		<Section>
			<div>
				<SectionHeading>
					<CustomerImg>
						<ProfilePhotoInput currentPhoto={userImg} onUpload={(file) => console.log("Upload this file: ", file)} />
					</CustomerImg>
				</SectionHeading>
				<ProfileForm onSubmit={handleSubmit}>
					<FirstNameInputContainer error={validationErrors.firstName ? 1 : 0}>
						<label htmlFor="firstName">First Name</label>
						<div>
							<input
								type="text"
								name="firstName"
								id="firstName"
								placeholder="Adele"
								value={formData.firstName}
								onChange={handleChange}
							/>
							{validationErrors.firstName && <p>{validationErrors.firstName}</p>}
						</div>
					</FirstNameInputContainer>
					<LastNameInputContainer error={validationErrors.lastName ? 1 : 0}>
						<label htmlFor="lastName">Last Name</label>
						<div>
							<input
								type="text"
								name="lastName"
								id="lastName"
								placeholder="Laurie Blue Adkins"
								value={formData.lastName}
								onChange={handleChange}
							/>
							{validationErrors.lastName && <p>{validationErrors.lastName}</p>}
						</div>
					</LastNameInputContainer>
					<BioInputContainer error={validationErrors.bio ? 1 : 0}>
						<label htmlFor="bio">Bio</label>
						<div>
							<input
								type="text"
								name="bio"
								id="bio"
								placeholder="English singer-songwriter"
								value={formData.bio}
								onChange={handleChange}
							/>
							{validationErrors.bio && <p>{validationErrors.bio}</p>}
						</div>
					</BioInputContainer>
					<EmailInputContainer error={validationErrors.email ? 1 : 0}>
						<label htmlFor="email">Email</label>
						<div>
							<input
								type="text"
								name="email"
								id="email"
								placeholder="example@gmail.com"
								value={formData.email}
								onChange={handleChange}
							/>
							{validationErrors.email && <p>{validationErrors.email}</p>}
						</div>
					</EmailInputContainer>
					<TelInputRow error={validationErrors.tel} page={"becomePartner"}>
						<label htmlFor="">Phone</label>
						<div>
							<TelInputContainer error={validationErrors.tel ? 1 : 0}>
								<TelCheckbox onClick={() => setIsOpen(!isOpen)}>
									<Checkbox>
										<img loading="lazy" src={selectedCountry.icon} alt={selectedCountry.name} />
										<img loading="lazy" src={selectArrowIcon} alt="select arrow" />
										{/*<span>{selectedCountry.code}</span>*/}
									</Checkbox>
									{isOpen && (
										<CheckboxOpen>
											{countries.map((country) => (
												<div key={country.code} onClick={() => handleSelect(country)}>
													<img loading="lazy" src={country.icon} alt={country.name} />
													<div>
														<span>{country.code}</span>
														<img loading="lazy" src={selectArrowIcon} alt="select arrow" />
													</div>
												</div>
											))}
										</CheckboxOpen>
									)}
								</TelCheckbox>
								<input
									type="text"
									placeholder="(374) 99 12 00 14"
									name="tel"
									value={formData.tel}
									onChange={handleChange}
									style={{ padding: "12px 16px 12px 58px" }}
								/>
							</TelInputContainer>
							{validationErrors.tel && <p>{validationErrors.tel}</p>}
						</div>
					</TelInputRow>
					<FormButton>
						<button type="submit">Save Changes</button>
					</FormButton>
				</ProfileForm>
			</div>
		</Section>
	);
};

export default CustomerProfileForm;
