import { memo, useEffect, useState } from "react";
import cameraImg from "../../../../Assets/Images/CelebrityProfile/camera-icon.svg";
import { PhotoInput } from "./styles";
import userImg from "../../../../Assets/Images/UserProfile/user-img.webp";

const ProfilePhotoInput = ({ currentPhoto, onUpload }) => {
	const [selectedImage, setSelectedImage] = useState(currentPhoto);
	const [validImageUrl, setValidImageUrl] = useState(null);

	const baseUrl = process.env.NODE_ENV === "production" ? "https://server.fameeo.com" : "http://localhost:8080";

	useEffect(() => {
		setSelectedImage(currentPhoto);
	}, [currentPhoto]);

	// Function to check if an image URL is valid
	const checkImageExists = (url) => {
		return new Promise((resolve) => {
			const img = new Image();
			img.onload = () => resolve(true);
			img.onerror = () => resolve(false);
			img.src = url;
		});
	};

	// Verify image existence when currentPhoto or selectedImage changes
	useEffect(() => {
		const imageUrl = selectedImage ? `${baseUrl}${selectedImage}` : null;

		if (imageUrl) {
			checkImageExists(imageUrl).then((exists) => {
				setValidImageUrl(exists ? imageUrl : userImg);
			});
		} else {
			setValidImageUrl(userImg);
		}
	}, [selectedImage, baseUrl]);

	const handleImageChange = async (event) => {
		const file = event.target.files[0];
		if (file) {
			const tempImageUrl = URL.createObjectURL(file);
			setSelectedImage(tempImageUrl);

			try {
				const newImageUrl = await onUpload(file);
				setSelectedImage(newImageUrl);
				URL.revokeObjectURL(tempImageUrl);
			} catch (error) {
				console.error("Upload failed:", error);
				setSelectedImage(currentPhoto);
			}
		}
	};

	// Use AsyncImage or similar for faster image loading
	return (
		<PhotoInput>
			<img loading="lazy" src={validImageUrl || userImg} alt="Profile" className="profile-photo" />
			<label htmlFor="profile-upload" className="upload-label">
				<img loading="lazy" src={cameraImg} alt="Upload" className="camera-img" />
			</label>
			<input
				id="profile-upload"
				type="file"
				accept="image/*"
				style={{ display: "none" }}
				onChange={handleImageChange}
			/>
		</PhotoInput>
	);
};

export default memo(ProfilePhotoInput);
