import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	max-width: 1400px;
	//position: relative;
	left: 0;
	right: 0;
	margin: 0 auto;
	display: flex;
	justify-content: center;

	& > div:first-child {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 370px;
		background: linear-gradient(0deg, #f4e3ff 0%, #ffffff 100%);
		z-index: -1;
	}
`;

export const SectionContainer = styled.div`
	margin-top: 78px;
	width: 100%;
	max-width: 942px;
	display: flex;
	align-items: center;
	padding: calc(40px + 2.5vw) calc(102px + 6.375vw);
	background-color: #fafafa;
	border-radius: 40px;
	box-sizing: border-box;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		gap: calc(16px + 1vw);

		h1 {
			margin: 0;
		}
	}

	& > h1 {
		font-size: calc(15px + 0.938vw);
		font-weight: 600;
		line-height: calc(18px + 1.144vw);
		letter-spacing: -0.02em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #0a0d13;
	}

	@media (min-width: 768px) and (max-width: 996px) {
		padding: calc(20px + 1.25vw) calc(51px + 3.188vw);
	}

	@media (max-width: 768px) {
		padding: 24px;
		border-radius: 30px;

		& > div {
			gap: 20px;
			align-items: center;

			h1 {
				font-weight: 500;
				font-size: 24px;
				line-height: 29.05px;
			}
		}
	}

	@media (min-width: 1600px) {
		padding: 80px 204px;

		& > div {
			gap: 32px;
		}

		& > h1 {
			font-size: 30px;
			line-height: 36.31px;
		}
	}
`;

export const PartnerForm = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(9px + 0.563vw);

	input {
		padding: calc(6px + 0.375vw) calc(8px + 0.5vw);
		border-radius: 80px;
		border: 1px solid #d6cfdb;
		font-size: calc(8px + 0.5vw);
		font-weight: 400;
		line-height: calc(11px + 0.688vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;

		&::placeholder {
			color: #acacac;
		}
	}

	p {
		margin-bottom: 0;
		color: #d00306;
		font-size: 14px;
		font-weight: 400;
		line-height: 16.94px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
	}

	label {
		font-size: calc(9px + 0.563vw);
		font-weight: 500;
		line-height: calc(11px + 0.688vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #0a0d13;

		span {
			color: #7f56d9;
		}
	}

	@media (max-width: 768px) {
		gap: 20px;

		label {
			font-size: 16px;
			line-height: 19.36px;
		}

		input {
			padding: 16px;
			font-size: 14px;
			line-height: 16.94px;
		}
	}

	@media (min-width: 1600px) {
		gap: 18px;

		input {
			padding: 12px 16px;
			font-size: 16px;
			line-height: 22px;
		}

		label {
			font-size: 18px;
			line-height: 22px;
		}
	}
`;

export const NameInputsRow = styled.div`
	width: 100%;
	display: flex;
	gap: calc(7px + 0.438vw);

	input {
		width: 50%;
	}

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 20px;
	}

	@media (min-width: 1600px) {
		gap: 14px;
	}
`;

export const InputRow = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: calc(9px + 0.563vw);
	width: 100%;

	input {
		width: 100%;
		box-sizing: border-box;
	}

	@media (min-width: 1600px) {
		gap: 18px;
	}
`;

export const FirstNameInputContainer = styled.div`
	input {
		border: 1.5px solid ${(props) => (props.error ? "#D00306" : "#acacac")};
	}
`;

export const LastNameInputContainer = styled.div`
	input {
		border: 1.5px solid ${(props) => (props.error ? "#D00306" : "#acacac")};
	}
`;

export const EmailInputContainer = styled.div`
	input {
		border: 1.5px solid ${(props) => (props.error ? "#D00306" : "#acacac")};
	}
`;

export const SocialInputContainer = styled.div`
	display: flex;
	flex-direction: column;

	& > div {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		// border: "1px solid #ccc",
		// padding: "10px",
		border-radius: 4px;
		position: relative;
		justify-content: flex-start;

		&:first-child {
			margin-bottom: 10px;
		}
	}

	span {
		display: inline-flex;
		align-items: center;
		padding: 5px 10px;
		background: #f0f0f0;
		border-radius: 12px;

		button {
			margin-left: 8px;
			background: transparent;
			border: none;
			cursor: pointer;
			color: #7f56d9;
			font-size: 16px;
		}
	}

	input {
		flex: 1;
		min-width: 150px;
		// padding: 8px;
		// border: none;
		outline: none;
		border: 1.5px solid ${(props) => (props.error ? "#D00306" : "#acacac")};
	}

	p {
		margin-top: 6px;
	}
`;

export const SocialInput = styled.div`
	position: relative;
	display: flex;
	align-items: center;

	& > div {
		display: flex;
		align-items: center;
		position: absolute;
		right: 20px;

		button {
			margin-left: 10px;
			padding: 8px 12px;
			background-color: #7f56d9;
			color: #fff;
			border: none;
			border-radius: 4px;
			cursor: pointer;
		}
	}
`;

export const PartnerButton = styled.button`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #7f56d9;
	font-size: calc(8px + 0.5vw);
	font-weight: 600;
	line-height: calc(9.5px + 0.616vw);
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #ffffff;
	padding: calc(10px + 0.625vw) 0;
	border-radius: 80px;
	border: none;
	cursor: pointer;

	@media (max-width: 768px) {
		padding: 16px;
		font-size: 16px;
		line-height: 19.36px;
	}

	@media (min-width: 1600px) {
		font-size: 16px;
		line-height: 19.36px;
		padding: 20px 0;
	}
`;
