import styled from "styled-components";
import { Link } from "react-router-dom";

export const SidebarContainer = styled.div`
	width: 100vw;
	height: 100%;
	overflow: hidden !important;
	position: fixed;
	z-index: 44444;
	top: 0;
	left: 0;
	transform: translateX(-100%);
	background: rgba(35, 35, 35, 0.5); /* Semi-transparent background */
	backdrop-filter: blur(10px); /* Apply blur effect */
`;

export const SidebarBody = styled.div`
	margin: 0;
	padding: 26px 100px;
	display: flex;
	flex-direction: column;
	//justify-content: space-between;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	position: relative;
	background-color: #ffffff;
	bottom: env(safe-area-inset-bottom);
	top: 0;
	@media (max-width: 768px) {
		padding: 20px 16px;
	}
`;

export const SectionHeading = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	span {
		font-size: 30px;
		font-weight: 300;
		cursor: pointer;
		margin-right: 10px;
		transform: translateY(-3px);
	}

	h2 {
		font-size: 20px;
		font-weight: 500;
		line-height: 24.2px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #121212;
		margin: 0;
	}
`;

export const SidebarLogo = styled(Link)`
	font-family: "Reost Bold";
	font-size: 24px;
	font-weight: 600;
	line-height: 29.05px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #121212;
	text-decoration: none;
	transform: translateY(3px);
`;

export const SubCategoryArrow = styled.img`
	transform: rotate(90deg);
	width: 30px;
	height: 30px;
	cursor: pointer;
`;

export const MenuContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 28px;
	margin-top: 93px;

	a,
	span {
		font-size: 20px;
		font-weight: 400;
		line-height: 24.2px;
		letter-spacing: 0.02em;
		text-align: left;
		color: #000000;
		text-decoration: none;
		border-bottom: ${(props) => props.current && "2px solid #000000"};
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		cursor: pointer;

		img {
			transform: rotate(-90deg);
		}
	}
`;

export const SocialLinks = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	gap: 20px;
	margin-top: 193px;

	a {
		font-size: 12px;
		font-weight: 400;
		line-height: 14.52px;
		letter-spacing: 0.02em;
		text-align: left;
		color: #000000;
		text-decoration: none;
	}
`;
