import { gql } from "@apollo/client";

export const GET_CELEBRITY_QUERY = gql`
	query GetCelebrity($celebrityId: String!) {
		getCelebrity(celebrityId: $celebrityId) {
			id
			firstName
			lastName
			email
			password
			tel
			bio
			about
			socialLinks
			price
			paymentMethods {
				paymentType
				cardNumber
				expirationDate
			}
			rating
			ratingCount
			profileImg
			categoryId
			portfolioVideos
			notifications
		}
	}
`;

export const GET_ALL_CELEBRITIES_QUERY = gql`
	query GetAllCelebrities {
		getAllCelebrities {
			id
			firstName
			lastName
			price
			rating
			ratingCount
			profileImg
			categoryId
		}
	}
`;

export const CELEBRITY_SIGN_IN_MUTATION = gql`
	mutation CelebritySignIn($email: String!, $password: String!) {
		celebritySignIn(email: $email, password: $password) {
			success
			tokens {
				access
				refresh
			}
			message
		}
	}
`;

export const CELEBRITY_SIGN_OUT_MUTATION = gql`
	mutation CelebritySignOut($celebrityId: String!) {
		celebritySignOut(celebrityId: $celebrityId)
	}
`;

export const UPDATE_CELEBRITY_MUTATION = gql`
	mutation UpdateCelebrity($celebrityId: String!, $celebrityInput: CelebrityInput!) {
		updateCelebrity(celebrityId: $celebrityId, celebrityInput: $celebrityInput)
	}
`;

export const CELEBRITY_RENEW_TOKEN_MUTATION = gql`
	mutation CelebrityRenewToken($refreshState: String!) {
		celebrityRenewToken(refreshState: $refreshState) {
			access
			refresh
		}
	}
`;

export const UPLOAD_CELEBRITY_VIDEO_MUTATION = gql`
	mutation UploadCelebrityVideo($celebrityId: String!, $video: Upload!, $occasion: String!) {
		uploadCelebrityVideo(celebrityId: $celebrityId, video: $video, occasion: $occasion)
	}
`;

export const UPLOAD_PROFILE_IMAGE_MUTATION = gql`
	mutation UploadProfileImage($celebrityId: String!, $file: Upload!) {
		uploadProfileImage(celebrityId: $celebrityId, file: $file) {
			profileImg
		}
	}
`;
