import { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { TelOrEmailInput, Section, SectionHeading, SignInForm, SignInInputs, SignInButton, SignUpLink } from "./styles";
import { validateEmail } from "../../../../utils/email";

const SignIn = () => {
	const dispatch = useDispatch();
	const { loading, error, otpRequested, otpContactInfo } = useSelector((state) => state.auth);
	const [contactInfo, setContactInfo] = useState("");
	const [otpCode, setOtpCode] = useState("");

	// const [requestOTP] = useMutation(REQUEST_OTP, {
	// 	onCompleted: () => {
	// 		dispatch(setOTPRequested(contactInfo));
	// 	},
	// 	onError: (error) => {
	// 		dispatch(setError(error.message));
	// 	}
	// });
	//
	// const [verifyOTP] = useMutation(VERIFY_OTP, {
	// 	onCompleted: (data) => {
	// 		dispatch(setAuthenticated(data.verifyOTP.tokens));
	// 	},
	// 	onError: (error) => {
	// 		dispatch(setError(error.message));
	// 	}
	// });

	// if (!otpRequested) {
	// 	return (
	// 		<div>
	// 			<input
	// 				type="text"
	// 				placeholder="Phone number or email"
	// 				value={contactInfo}
	// 				onChange={(e) => setContactInfo(e.target.value)}
	// 			/>
	// 			<button onClick={handleRequestOTP} disabled={loading}>
	// 				{loading ? 'Sending...' : 'Get verification code'}
	// 			</button>
	// 			{error && <div className="error">{error}</div>}
	// 		</div>
	// 	);
	// }
	//
	// return (
	// 	<div>
	// 		<input
	// 			type="text"
	// 			placeholder="Enter verification code"
	// 			value={otpCode}
	// 			onChange={(e) => setOtpCode(e.target.value)}
	// 		/>
	// 		<button onClick={handleVerifyOTP} disabled={loading}>
	// 			{loading ? 'Verifying...' : 'Verify'}
	// 		</button>
	// 		{error && <div className="error">{error}</div>}
	// 	</div>
	// );

	const [formData, setFormData] = useState({
		telOrEmail: "",
	});
	const [validationErrors, setValidationErrors] = useState({});

	const validatePhoneNumber = (phone) => {
		if (!phone) return false;
		return phone.match(/^\+?\d{10,15}$/);
	};

	const validate = () => {
		const newErrors = {};
		const { telOrEmail } = formData;

		if (!telOrEmail) {
			newErrors.telOrEmail = "Email or phone number is required";
		} else if (!validateEmail(telOrEmail) && !validatePhoneNumber(telOrEmail)) {
			newErrors.telOrEmail = "Enter a valid email or phone number";
		}

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (validate()) {
			// Proceed with form submission logic
		}
	};

	return (
		<Section>
			<SectionHeading>
				<h2>Sign In</h2>
				<span>Keep it all together and you”ll be fine.</span>
			</SectionHeading>

			<SignInForm action="" onSubmit={handleSubmit}>
				<SignInInputs errors={validationErrors}>
					<div>
						<TelOrEmailInput
							type="text"
							placeholder={"Enter your phone number or email"}
							name="telOrEmail"
							value={formData.telOrEmail}
							onChange={handleChange}
							error={validationErrors.telOrEmail}
						/>
						{validationErrors.telOrEmail && <p>{validationErrors.telOrEmail}</p>}
					</div>
					<SignInButton type="submit">Sign In</SignInButton>
				</SignInInputs>
				<SignUpLink>
					Don’t have an account? <Link to="/auth/sign-up">Sign up</Link>
				</SignUpLink>
			</SignInForm>
		</Section>
	);
};

export default SignIn;
