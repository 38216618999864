import { Link, Outlet } from "react-router-dom";
import { useMobileDetector, useTabletDetector } from "../../utils/customHooks";
import { AuthContainer, AuthContent, AuthLogo, CelebrityContent } from "./styles";
import CelebritySwiper from "../../Components/Molecules/Home/CelebritySwiper";

const AuthLayout = () => {
	const isMobile = useMobileDetector();
	const isTablet = useTabletDetector();

	return (
		<AuthContainer>
			<AuthContent>
				<AuthLogo>
					<Link to="/">Fameeo</Link>
				</AuthLogo>

				<Outlet />
			</AuthContent>

			{!isMobile && !isTablet && (
				<CelebrityContent>
					<div>
						<div>
							<CelebritySwiper />
						</div>
					</div>
				</CelebrityContent>
			)}
		</AuthContainer>
	);
};

export default AuthLayout;
