import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

export const Section = styled.section`
	width: 100%;
	position: relative;
	left: 0;
	right: 0;
	margin: calc(40px + 2.5vw) auto 0 auto;
	display: flex;
	flex-direction: column;
	gap: calc(30px + 1.875vw);

	@media (min-width: 1600px) {
		gap: 60px;
		margin-top: 80px;
	}
`;

export const CelebrityInfoRow = styled.div`
	width: 100%;
	max-width: 1400px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	left: 0;
	right: 0;
	margin: 0 auto;

	@media (max-width: 768px) {
		flex-direction: column-reverse;
	}
`;

export const CelebrityCategoryRow = styled.div`
	width: 100vw;
	//height: 365px;
	background-color: transparent;
	display: flex;
	//margin-top: calc(30px + 1.875vw);
	margin-left: -100px;

	& > div:after {
		content: "";
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(
			to right,
			rgba(255, 255, 255, 1),
			transparent 30%,
			transparent 70%,
			rgba(255, 255, 255, 1)
		);
		z-index: 3; // Ensure it's behind the content
		pointer-events: none;
	}

	@media (min-width: 768px) and (max-width: 996px) {
		margin-left: -75px;
	}

	@media (max-width: 768px) {
		margin-left: -50px;
	}
`;

export const CelebrityInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(20px + 1.25vw);
	width: 35.42%;

	p {
		font-size: calc(12px + 0.75vw);
		font-weight: 400;
		line-height: calc(16px + 0.816vw);
		letter-spacing: -0.011em;
		text-align: left;
	}

	@media (max-width: 768px) {
		width: 100%;
	}

	@media (min-width: 1600px) {
		gap: 40px;

		p {
			font-size: 24px;
			line-height: 30px;
		}

		span {
			font-size: 24px;
			line-height: 29.05px;
			gap: 16px;
		}
	}
`;

export const ViewAllButton = styled.button`
	font-size: calc(12px + 0.75vw);
	font-weight: 400;
	line-height: calc(16px + 0.816vw);
	letter-spacing: -0.011em;
	text-align: left;
	display: flex;
	align-items: center;
	gap: calc(8px + 0.5vw);
	border: none;
	background: none;
	cursor: pointer;

	img {
		width: calc(24px + 1.5vw);
		height: calc(24px + 1.5vw);
	}

	@media (min-width: 1600px) {
		font-size: 24px;
		line-height: 29.05px;
		gap: 16px;

		img {
			width: 48px;
			height: 48px;
		}
	}
`;

export const SectionHeading = styled.div`
	display: flex;
	flex-direction: column;
	width: 56.71%;

	@media (max-width: 768px) {
		width: 100%;
		align-items: flex-start;
	}
`;

export const TextRow = styled.div`
	font-size: calc(24px + 3vw);
	font-weight: 500;
	line-height: calc(32px + 3.446vw);
	letter-spacing: -0.011em;
	text-align: right;
	text-transform: uppercase;

	@media (max-width: 768px) {
		text-align: left;
	}

	@media (min-width: 1600px) {
		font-size: 72px;
		font-weight: 500;
		line-height: 87.14px;
	}
`;

export const CustomSwiperSlide = styled(SwiperSlide)`
	max-width: 276px !important;

	div {
		max-width: 276px;
	}

	span {
		font-size: calc(12px + 0.75vw);
		font-weight: 400;
		line-height: calc(16px + 0.816vw);
		letter-spacing: -0.011em;
		text-align: left;
	}

	@media (min-width: 1600px) {
		span {
			font-size: 24px;
			line-height: 29.05px;
		}
	}
`;

export const CategoryImage = styled.img`
	width: 100%;
	max-width: 276px;
	max-height: 316px;
	margin-bottom: 27px;

	display: block;
	height: auto;

	@media (min-width: 1600px) {
		width: 276px;
	}
`;
