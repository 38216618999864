import { Section, SectionColumn, ImageColumn, SectionHeading, TextRow, SectionDescription } from "./styles";

import becomePartner1 from "../../../../Assets/Images/Home/becomePartner1.webp";
import becomePartner2 from "../../../../Assets/Images/Home/becomePartner2.webp";
import becomePartner3 from "../../../../Assets/Images/Home/becomePartner3.webp";
import becomePartner4 from "../../../../Assets/Images/Home/becomePartner4.webp";
import becomePartner5 from "../../../../Assets/Images/Home/becomePartner5.webp";
import becomePartner6 from "../../../../Assets/Images/Home/becomePartner6.webp";
import becomePartner7 from "../../../../Assets/Images/Home/becomePartner7.webp";
import becomePartner8 from "../../../../Assets/Images/Home/becomePartner8.webp";
import { useMobileDetector, useTabletDetector } from "../../../../utils/customHooks";
import { useNavigate } from "react-router-dom";

const BecomePartner = () => {
	const isMobile = useMobileDetector();
	const isTablet = useTabletDetector();

	const navigate = useNavigate();

	return (
		<Section>
			<SectionColumn>
				<ImageColumn>
					<img loading="lazy" src={becomePartner1} alt="" />
					<img loading="lazy" src={becomePartner2} alt="" />
				</ImageColumn>
				<ImageColumn>
					<img loading="lazy" src={becomePartner3} alt="" />
					<img loading="lazy" src={becomePartner4} alt="" />
				</ImageColumn>
				<ImageColumn>
					<div>
						<img loading="lazy" src={becomePartner5} alt="" />
						<ImageColumn>
							<img loading="lazy" src={becomePartner6} alt="" />
							<img loading="lazy" src={becomePartner7} alt="" />
						</ImageColumn>
					</div>
					<img loading="lazy" src={becomePartner8} alt="" />
				</ImageColumn>
			</SectionColumn>

			<SectionColumn>
				<SectionHeading>
					<TextRow>Share Your Talent, Reach</TextRow>
					<TextRow>Your Fans!</TextRow>
				</SectionHeading>
				<SectionDescription>
					{isMobile || isTablet ? (
						<span>
							Start sharing personalized video messages with your fans and earn from every interaction
						</span>
					) : (
						<>
							<span>Start sharing personalized video messages </span>
							<span>with your fans and earn from every interaction</span>
						</>
					)}
				</SectionDescription>
				<div>
					<button onClick={() => navigate("/become-partner")}>Become a Partner</button>
				</div>
			</SectionColumn>
		</Section>
	);
};

export default BecomePartner;
