import styled from "styled-components";

export const OutletContainer = styled.div`
	min-height: 100vh;
	width: 100%;
	box-sizing: border-box;
	padding: 0 100px;
	overflow-x: hidden !important;

	@media (max-width: 1400px) {
		padding: 0 75px;
	}

	@media (max-width: 1200px) {
		padding: 0 50px;
	}

	@media (max-width: 768px) {
		padding: 0 16px;
	}
`;
