import styled from "styled-components";
import checkImg from "../../../../Assets/Images/UserCelebrity/check-icon.svg";

export const VideoContainer = styled.div`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: 57px;
	gap: calc(28.5px + 1.781vw);

	@media (max-width: 768px) {
		margin-top: 50px;
	}

	@media (min-width: 1600px) {
		gap: 57px;
	}
`;

export const VideoHeading = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(6px + 0.375vw);

	h2 {
		margin: 0;
		font-size: calc(16px + 1vw);
		font-weight: 600;
		line-height: calc(19px + 1.233vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	span {
		font-size: calc(10px + 0.625vw);
		font-weight: 400;
		line-height: calc(12.1px + 0.756vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 768px) {
		align-items: center;

		span {
			max-width: 175px;
			text-align: center;
		}
	}

	@media (min-width: 1600px) {
		gap: 12px;

		h2 {
			font-size: 32px;
			line-height: 38.73px;
		}

		span {
			font-size: 20px;
			line-height: 24.2px;
		}
	}
`;

export const VideoGrid = styled.div`
	width: 100%;
	margin-top: 50px;
	display: flex;
	flex-wrap: wrap;
	gap: calc(14px + 0.875vw);

	@media (max-width: 996px) {
		justify-content: flex-start;
		gap: 13px;
		margin-top: 20px;
	}

	@media (max-width: 768px) {
		gap: 8px;
		margin-top: 0;
	}

	@media (min-width: 1600px) {
		gap: 28px;
	}
`;

export const VideoCard = styled.div`
	width: 100%;
	max-width: 326px;
	height: 350px;
	padding: calc(17px + 1.063vw);
	border-radius: 40px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	gap: calc(20px + 1.25vw);
	background-color: #fafafa;
	box-sizing: border-box;
	position: relative;
	cursor: pointer;
	flex-grow: 0;
	flex-shrink: 0;

	//

	& > div {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: #fafafa;
		border-radius: 40px;
	}

	span {
		font-size: calc(12px + 0.75vw);
		font-weight: 500;
		line-height: calc(14.5px + 0.909vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
		z-index: 1;
	}

	@media (max-width: 768px) {
		max-width: 174px;
		height: 189px;
		border-radius: 16px;

		span {
			font-size: 12px;
			line-height: 14.52px;
		}
	}

	@media (max-width: 480px) {
		max-width: 164px;
	}

	@media (min-width: 1600px) {
		padding: 34px;
		gap: 40px;

		span {
			font-size: 24px;
			line-height: 29.05px;
		}
	}
`;

export const PlayButtonContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: 768px) {
		img {
			width: 40px;
			height: 40px;
		}
	}
`;

export const VideoUploadCard = styled.div`
	width: 100%;
	max-width: 326px;
	padding: calc(30.5px + 1.906vw) calc(19px + 1.188vw);
	border: 2px dashed #c0c0c0;
	text-align: center;
	border-radius: 40px;
	background-color: #ffffff;
	box-sizing: border-box;
	transition: 0.5s;
	display: flex;
	flex-direction: column;
	gap: calc(7px + 0.438vw);
	align-items: center;
	justify-content: center;

	&:hover {
		border-color: #000000;
	}

	h3 {
		margin: 0;
		font-size: calc(12px + 0.75vw);
		font-weight: 600;
		line-height: calc(14.5px + 0.909vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	p {
		margin: 0;
		font-size: calc(8px + 0.5vw);
		font-weight: 400;
		line-height: calc(9.5px + 0.616vw);
		text-align: center;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	label {
		padding: calc(6px + 0.375vw) calc(16px + 1vw);
		background-color: #7f56d9;
		border: none;
		border-radius: 30px;
		cursor: pointer;
		font-size: calc(9px + 0.563vw);
		font-weight: 600;
		line-height: calc(10.5px + 0.705vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #ffffff;
		margin-top: 4px;
	}

	input[type="file"] {
		display: none;
	}

	@media (max-width: 768px) {
		max-width: 174px;
		height: 189px;
		border-radius: 16px;
		border: 1px dashed #c0c0c0;

		img {
			width: 26px;
			height: 26px;
		}

		h3 {
			font-size: 12px;
			line-height: 14.52px;
		}

		p {
			font-size: 8px;
			line-height: 9.68px;
		}

		label {
			padding: 8px 32px;
			font-size: 10px;
			line-height: 12.1px;
		}
	}

	@media (max-width: 480px) {
		max-width: 164px;
	}

	@media (min-width: 1600px) {
		padding: 61px 38px;
		gap: 14px;

		h3 {
			font-size: 24px;
			line-height: 29.05px;
		}

		p {
			font-size: 16px;
			line-height: 19.36px;
		}

		label {
			padding: 12px 32px;
			font-size: 18px;
			line-height: 21.78px;
		}
	}
`;

export const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 111;
	overflow: hidden;
`;

export const ModalContent = styled.div`
	background: #ffffff;
	width: 100%;
	max-width: 797px;
	padding: calc(20px + 1.25vw);
	box-sizing: border-box;
	border-radius: 40px;
	text-align: center;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: calc(19px + 1.188vw);

	progress {
		width: 100%;
		margin: 10px 0 20px;
	}

	select {
		width: 100%;
		padding: 8px;
		margin-bottom: 20px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}

	button {
		padding: calc(5px + 0.313vw) calc(10px + 0.313vw);
		margin: 0 10px;
		border: none;
		border-radius: 4px;
		font-size: calc(7px + 0.438vw);
		cursor: pointer;
	}

	@media (min-width: 1600px) {
		padding: 40px;
		gap: 38px;

		progress {
			margin: 10px 0 20px;
		}

		select {
			padding: 8px;
			margin-bottom: 20px;
		}

		button {
			padding: 10px 20px;
			font-size: 14px;
		}
	}
`;

export const ModalHeading = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	h4 {
		margin: 0;
		font-size: calc(16px + 1vw);
		font-weight: 600;
		line-height: calc(24px + 1.5vw);
		letter-spacing: -0.011em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	span {
		cursor: pointer;
		font-size: calc(16px + 1vw);
		font-weight: 200;
		line-height: calc(24px + 1.5vw);
	}

	@media (min-width: 1600px) {
		h4 {
			font-size: 32px;
			line-height: 48px;
		}

		span {
			font-size: 32px;
			line-height: 48px;
		}
	}
`;

export const FileContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: calc(6px + 0.375vw);
	border-radius: 30px;
	padding: calc(8px + 0.5vw);
	background-color: #fafafa;
	box-sizing: border-box;

	& > div {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: calc(7px + 0.438vw);

		progress {
			margin: 0;
			appearance: none;
			height: 8px;

			&::-webkit-progress-value {
				background-color: #7f56d9;
				border-radius: 10px;
				height: 8px;
			}

			&::-webkit-progress-bar {
				background-color: #ffffff;
				border-radius: 10px;
				height: 8px;
			}
		}
	}

	@media (min-width: 1600px) {
		gap: 12px;
		padding: 16px;

		& > div {
			gap: 14px;
		}
	}
`;

export const FileName = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > span {
		display: flex;
		align-items: center;
		gap: calc(7px + 0.438vw);
		font-size: calc(10px + 0.625vw);
		font-weight: 400;
		line-height: calc(15px + 0.938vw);
		letter-spacing: -0.011em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;

		span {
			color: #828282;
		}
	}

	@media (min-width: 1600px) {
		& > span {
			gap: 14px;
			font-size: 20px;
			line-height: 30px;
		}
	}
`;

export const OccasionContainer = styled.div`
	position: relative;
	width: 100%;
`;

export const Selected = styled.div`
	display: flex;
	align-items: center;
	padding: calc(5px + 0.313vw) calc(8px + 0.5vw);
	border-radius: 80px;
	cursor: pointer;
	background-color: #fff;
	position: relative;
	gap: calc(4px + 0.25vw);
	border: none;

	img:last-child {
		position: absolute;
		right: 16px;
		transition: 0.3s;
		transform: rotate(${(props) => (props.isOpen ? "180deg" : "0deg")});
	}

	span {
		font-size: calc(8px + 0.5vw);
		color: #000;
	}

	@media (min-width: 1600px) {
		padding: 10px 16px;
		gap: 8px;

		img:last-child {
			right: 16px;
		}

		span {
			font-size: 16px;
		}
	}
`;

export const Options = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background-color: #fff;
	border: 1px solid #a79dad;
	border-radius: 20px;
	margin-top: 8px;
	overflow: hidden;
	z-index: 10;
	padding: calc(4.5px + 0.281vw) calc(8px + 0.5vw);
	box-sizing: border-box;

	@media (min-width: 1600px) {
		padding: 9px 16px;
	}
`;

export const Option = styled.div`
    display: flex;
    align-items: center;
    gap: calc(5px + 0.313vw);
    padding: calc(3.5px + 0.219vw) 0;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:before {
        content: ${(props) => props.current && `url(${checkImg})`};
        position: absolute;
        right: 16px;
    }

    &:hover {
        span {
            color: #828282;
        }

        &:before {
            content: url(${checkImg});
            position: absolute;
            right: 16px;
        }
    }

    span {
        font-size: calc(8px + 0.5vw)
        color: #000;
    }

    @media (min-width: 1600px) {
        gap: 10px;
        padding: 7px 0;

        &:before {
            right: 16px;
        }

        &:hover {
            &:before {
                right: 16px;
            }
        }

        span {
            font-size: 16px;
        }
    }
`;

export const ModalActionButtons = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	gap: calc(9px + 0.563vw);

	button {
		margin: 0;
		padding: calc(6px + 0.375vw) calc(8px + 0.5vw);
		border-radius: 80px;
		font-size: calc(10px + 0.625vw);
		font-weight: 600;
		line-height: calc(15px + 0.938vw);
		letter-spacing: -0.011em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;

		&:first-child {
			background-color: #fafafa;
			color: #000000;
			padding: calc(4.5px + 0.281vw) calc(20.75px + 1.297vw);
		}

		&:nth-child(2) {
			background-color: #000000;
			color: #ffffff;
			padding: calc(4.5px + 0.281vw) calc(21.5px + 1.344vw);
		}
	}

	@media (min-width: 1600px) {
		gap: 18px;

		button {
			padding: 12px 16px;
			font-size: 20px;
			line-height: 30px;

			&:first-child {
				padding: 9px 41.5px;
			}

			&:nth-child(2) {
				padding: 9px 43px;
			}
		}
	}
`;
