import styled from "styled-components";
import { Link } from "react-router-dom";
import arrowDownImg from "../../../Assets/Images/Layout/Navbar/arrow-down-icon.svg";

export const Navbar = styled.nav`
	display: flex;
	flex-direction: column;
	//align-items: center;
	//justify-content: space-between;
	//box-sizing: border-box;
	padding-block: 50px;
	z-index: 111;
	position: relative;
	width: 100%;
	max-width: 1400px;
	left: 0;
	right: 0;
	margin: 0 auto;
	gap: 20px;

	//@media (max-width: 1200px) {
	//	width: calc(100% + 100px);
	//	margin-left: -50px;
	//}

	@media (max-width: 996px) {
		padding-block: 20px;
	}

	& > div {
		&:first-child {
			display: flex;
			align-items: center;
			justify-content: space-between;
			box-sizing: border-box;

			& > div:first-child {
				display: flex;
				gap: calc(10px + 0.625vw);
				align-items: center;
			}
		}

		&:nth-child(2) {
			width: 100%;
			position: relative;
		}
	}
`;

export const NavLinkMobile = styled.div`
	display: none;
	cursor: pointer;

	@media (max-width: 996px) {
		width: calc(17px + 1.063vw);
		height: calc(9px + 0.563vw);
		position: relative;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		gap: 4px;

		div {
			width: 70%;
			border: 1.2px solid ${(props) => (props.loc === "/" ? "#000000" : "#1F1F1F")};
			border-radius: 20px;
		}

		&:before,
		&:after {
			content: "";
			width: 100%;
			border: 1.2px solid ${(props) => (props.loc === "/" ? "#000000" : "#1F1F1F")};
			border-radius: 20px;
		}
	}
`;

export const NavLogo = styled(Link)`
	font-family: "Reost Bold";
	font-size: calc(20px + 1.25vw);
	font-weight: 700;
	line-height: calc(25.4px + 1.587vw);
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	margin: 0 20px 0 0;
	text-decoration: none;
	color: #383838;
	transform: translateY(3px);

	@media (min-width: 1600px) {
		font-size: 40px;
		line-height: 48.41px;
	}
`;

export const NavLinkContainer = styled.div`
	//width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: calc(5px + 2.313vw);
	//z-index: 1;
	position: relative;

	@media (min-width: 996px) and (max-width: 1200px) {
		gap: calc(5px + 1vw);
	}

	@media (max-width: 996px) {
		gap: calc(3px + 1.313vw);
	}

	@media (max-width: 768px) {
		gap: 3px;
	}

	@media (min-width: 1600px) {
		gap: 42px;
	}
`;

export const DropdownContainer = styled.div`
	position: relative;
	display: inline-block;
`;

export const DropdownButton = styled.button`
	background-color: #fafafa;
	padding: 16px 24px;
	border-radius: 30px;
	border: none;
	font-size: 16px;
	font-weight: 500;
	line-height: 19.36px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 4px;
	position: relative;

	&:after {
		content: "";
		background-image: url(${arrowDownImg});
		background-size: 100%;
		background-position: center;
		width: 22px;
		height: 22px;
		display: inline-block;
		transform: rotate(${(props) => props.isOpen && "180deg"});
		transition: 0.3s;
	}

	@media (min-width: 1600px) {
		font-size: 16px;
		line-height: 19.36px;
		padding: 16px 24px;
	}
`;

export const DropdownMenu = styled.div`
	display: flex;
	background-color: #fafafa;
	position: absolute;
	z-index: -1; // Ensure the dropdown menu is beneath the navbar
	padding: 66px 44px;
	border-radius: 40px;
	transition: 0.5s ease-in-out;
	margin-top: 12px;

	/* Add invisible bridge to maintain hover */

	&:before {
		content: "";
		position: absolute;
		top: -12px; /* Same as margin-top */
		left: 0;
		right: 0;
		height: 12px; /* Same as margin-top */
		background: transparent;
	}

	& > div {
		display: flex;
		flex-direction: column;
		gap: 22px;
	}

	& > div:last-child {
		margin-left: 42px;
	}
`;

export const DropdownItem = styled(Link)`
	padding: 0;
	margin: 0;
	cursor: pointer;
	white-space: nowrap;
	font-size: 16px;
	font-weight: 500;
	line-height: 19.36px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	text-decoration: none;
	color: #000000cc;

	&:hover {
		background-color: #f1f1f1;
	}
`;

export const SelectDivider = styled.div`
	width: 1px;
	height: auto;
	background-color: #c4c4c4;
	margin: 0 19px;
`;

export const NavLink = styled(Link)`
	font-size: calc(8px + 0.5vw);
	font-weight: 500;
	line-height: calc(9.5px + 0.616vw);
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	text-decoration: none;
	color: #121212cc;
	display: flex;
	align-items: center;
	justify-content: center;
	text-wrap: nowrap;
	//position: relative;
	//z-index: 1;

	@media (min-width: 1600px) {
		font-size: 16px;
		line-height: 19.36px;
	}
`;

export const NavSearch = styled.div`
	width: 100%;
	//max-width: 434px;
	//min-width: 300px;
	width: 434px;
	flex: 1;

	input {
		width: 100%;
		padding: 14px 20px;
		gap: calc(5px + 0.313vw);
		border-radius: 100px;
		border: 1px solid #12121233;
		color: #12121299;

		font-size: 16px;
		font-weight: 400;
		line-height: 19.36px;
		letter-spacing: -0.011em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		box-sizing: border-box;
		background: transparent;
	}

	@media (min-width: 1100px) and (max-width: 1300px) {
		width: auto;
		min-width: 350px;
	}

	@media (max-width: 1100px) {
		width: auto;
		min-width: 300px;
	}

	@media (min-width: 1600px) {
		input {
			padding: 14px 20px;
			gap: 10px;

			font-size: 16px;
			line-height: 19.36px;
		}
	}
`;

export const LoginLink = styled(Link)`
	font-size: calc(10px + 0.625vw);
	font-weight: 500;
	line-height: calc(8px + 0.512vw);
	letter-spacing: -0.011em;
	text-align: left;
	text-decoration: none;
	color: #121212cc;
	white-space: nowrap;

	@media (min-width: 1600px) {
		font-size: 20px;
		line-height: 30px;
	}
`;

export const CelebrityDropdownContainer = styled.div`
	position: relative;
`;

export const CelebrityNav = styled.div`
	display: flex;
	gap: 10px;

	button {
		padding: 0;
	}
`;

export const NotificationButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	position: relative;
`;

export const NotificationsDropdownMenu = styled.div`
	width: 309px;
	position: absolute;
	top: 100%;
	right: 50%;
	padding: 32px;
	border-radius: 10px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	gap: 20px;
	transition: 0.5s ease-in-out;
	margin-top: 5px;
	z-index: 1;
	box-shadow: 0 1px 40px 0 #b8b8b82e;
	box-sizing: border-box;

	&:before {
		content: "";
		position: absolute;
		top: -5px; /* Same as margin-top */
		left: 0;
		right: 0;
		height: 12px; /* Same as margin-top */
		background: transparent;
	}

	@media (max-width: 480px) {
		width: 232px;
		padding: 16px;
		gap: 14px;
		margin-top: 3px;
	}
`;

export const NotificationsHeading = styled.h3`
	margin: 0;
	font-weight: 500;
	font-size: 16px;
	line-height: 19.36px;
	letter-spacing: 0;
	color: #000000;
`;

export const NotificationsDropdownBody = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 18px;

	@media (max-width: 480px) {
		gap: 10px;
	}
`;

export const NotificationItem = styled.div`
	width: 100%;
	display: flex;
	gap: 10px;

	img {
		width: 30px;
		height: 30px;
	}

	& > div {
		display: flex;
		flex-direction: column;
		gap: 4px;

		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 16.94px;
			letter-spacing: 0;
			color: #000000;
			margin: 0;
		}

		span {
			font-weight: 400;
			font-size: 12px;
			line-height: 14.52px;
			letter-spacing: 0;
			color: #a4a7ae;
		}
	}

	@media (max-width: 480px) {
		img {
			width: 18px;
			height: 18px;
		}
	}
`;

export const CelebrityDropdownButton = styled.button`
	display: flex;
	//gap: 3px;
	background: none;
	border: none;
	align-items: center;
	width: 34px;
	height: 34px;

	img {
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		transition: 0.3s;
		cursor: pointer;
		border-radius: 50%;

		&:nth-of-type(2) {
			transform: rotate(${(props) => (props.isOpen ? "180deg" : "0")});
		}
	}
`;

// export const CelebrityDropdownMenu = styled.div``;

export const CustomerDropdownContainer = styled.div``;

export const CustomerDropdownButtons = styled.div`
	display: flex;
	gap: 12px;
	align-items: center;

	button {
		cursor: pointer;
		background: none;
		border: none;
	}
`;

export const CartPopupContainer = styled.div`
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100vw !important;
	height: 100vh !important;
	overflow: hidden;
	z-index: 120; // for navbar
	background-color: #0000003d;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const CartPopupBody = styled.div`
	width: 100%;
	max-width: 486px;
	padding: 32px;
	border-radius: 20px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 12px;
	position: relative;
	box-sizing: border-box;

	h2 {
		margin: 0;
		font-size: 24px;
		font-weight: 600;
		line-height: 36px;
		letter-spacing: -0.011em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	p {
		margin: 0;
		font-size: 16px;
		font-weight: 400;
		line-height: 19.36px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	& > div {
		width: 100%;
		display: flex;
		//justify-content: flex-end;
		gap: 10px;
		margin-top: 16px;

		button {
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			letter-spacing: -0.011em;
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			box-sizing: border-box;
			border-radius: 80px;
			border: none;
			cursor: pointer;

			&:first-child {
				padding: 12px 13px;
				color: #000000;
				background-color: #fafafa;
			}

			&:nth-child(2) {
				padding: 12px 25px;
				color: #ffffff;
				background-color: #7f56d9;
			}
		}
	}

	& > button {
		position: absolute;
		top: 32px;
		right: 32px;
		cursor: pointer;
		border: none;
		background: none;

		span {
			font-size: 32px;
			font-weight: 300;
			line-height: 24px;
		}
	}
`;

export const ShareInput = styled.div`
	width: 100%;
	display: flex;
	gap: 7px;

	input {
		width: 100%;
		border: 1px solid #d6cfdb;
		padding: 12px 16px;
		box-sizing: border-box;
		font-weight: 400;
		font-size: 12px;
		line-height: 14.52px;
		letter-spacing: 0;
		color: #717680;
		border-radius: 80px;
	}

	button {
		padding: 12px 32px;
		background-color: #7f56d9;
		border-radius: 30px;
		font-weight: 500;
		font-size: 12px;
		line-height: 14.52px;
		letter-spacing: 0;
		color: #ffffff;
		white-space: nowrap;
	}
`;

export const ShareCelebrityInfo = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	div {
		display: flex;
		flex-direction: column;
		gap: 12px;

		h4 {
			margin: 0;
			font-weight: 600;
			font-size: 24px;
			line-height: 29.05px;
			letter-spacing: 0;
			color: #000000;
		}

		span {
			font-weight: 400;
			font-size: 16px;
			line-height: 19.36px;
			letter-spacing: 0;
			color: #000000;
		}
	}

	img {
		width: 100%;
		max-width: 61px;
		height: auto;
	}
`;

export const AuthDropdownMenu = styled.div`
	position: absolute;
	top: 100%;
	right: 0;
	padding: 12px;
	border-radius: 10px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	gap: 16px;
	transition: 0.5s ease-in-out;
	margin-top: 12px;
	z-index: 1;
	box-shadow: 0 1px 40px 0 #b8b8b82e;

	&:before {
		content: "";
		position: absolute;
		top: -12px; /* Same as margin-top */
		left: 0;
		right: 0;
		height: 12px; /* Same as margin-top */
		background: transparent;
	}

	a {
		text-decoration: none;
		font-size: 16px;
		font-weight: 400;
		line-height: 19.36px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
		display: flex;
		align-items: center;
		gap: 4px;
	}
`;
