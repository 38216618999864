import { Section, SectionCol, SectionHeading, SectionButton, ImageCol } from "./styles";
import watchingVideoImg from "../../../../Assets/Images/Category/watching-video.webp";

const HeroSection = () => {
	return (
		<Section>
			<SectionCol>
				<SectionHeading>
					Introducing live <span>video</span> calls with your favorite <span>celebrities!</span>
				</SectionHeading>
				<div>
					<SectionButton>Explore Now</SectionButton>
				</div>
			</SectionCol>
			<ImageCol>
				<img loading="lazy" src={watchingVideoImg} alt="" />
			</ImageCol>
		</Section>
	);
};

export default HeroSection;
