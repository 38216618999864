import styled from "styled-components";
import authBackgroundImg from "../../Assets/Images/Layout/Auth/auth-background.webp";

export const AuthContainer = styled.div`
	width: 100vw;
	height: 100vh;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	overflow: hidden; //
	//background-color: #f1daff;
	background-image: url(${authBackgroundImg});
	background-repeat: no-repeat;
	background-position: right;
	//background-size: 100%;
`;

export const AuthContent = styled.div`
	width: 58.5%;
	display: flex;
	flex-direction: column;
	padding: calc(25px + 1.563vw) calc(50px + 3.125vw);
	box-sizing: border-box;
	border-bottom-right-radius: 40px;
	background-color: #ffffff;

	@media (min-width: 1200px) and (max-width: 1400px) {
		width: 50%;
	}

	@media (min-width: 996px) and (max-width: 1200px) {
		width: 40%;
		padding: calc(15px + 1.563vw) calc(30px + 3.125vw);
	}

	@media (max-width: 996px) {
		width: 100%;
		padding: calc(5px + 1.563vw) calc(10px + 3.125vw);
		border-bottom-right-radius: 0;
	}

	@media (min-width: 1600px) {
		padding: 50px 100px;
	}
`;

export const AuthLogo = styled.div`
	width: 100%;

	a {
		font-family: "Reost Bold";
		text-decoration: none;
		font-size: calc(20px + 1.25vw);
		font-weight: 600;
		line-height: calc(24px + 1.526vw);
		text-align: left;
		color: #121212;
	}

	@media (min-width: 1600px) {
		a {
			font-size: 40px;
			line-height: 48.41px;
		}
	}
`;

export const CelebrityContent = styled.div`
	width: 41.5%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	& > div {
		max-height: 750px;
		position: relative;
		overflow: hidden;
	}

	& > div > div {
		display: flex;
		gap: calc(14px + 0.875vw);
	}

	& > div > div:after {
		content: "";
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 1),
			transparent 0%,
			transparent 100%,
			rgba(255, 255, 255, 1)
		);
		z-index: 3;
		pointer-events: none;
	}

	@media (min-width: 1200px) and (max-width: 1400px) {
		width: 50%;
	}

	@media (max-width: 1200px) {
		width: 60%;
	}

	@media (min-width: 1600px) {
		& > div > div {
			gap: 28px;
		}
	}
`;
