import styled from "styled-components";

export const FooterSection = styled.footer`
	position: absolute;
	left: 0;
	right: 0;
	margin: calc(60px + 3.75vw) auto 0 auto;
	background: #ebebeb;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-height: 768px;
	overflow: hidden;

	& > div:first-child {
		max-width: 1600px;
		width: 100%;
		padding: calc(40px + 2.5vw) calc(50px + 3.125vw);
		display: flex;
		gap: calc(55px + 3.438vw);
		box-sizing: border-box;
		justify-content: space-between;

		h3,
		span {
			color: #262626;
		}
	}

	@media (max-width: 1200px) {
		& > div:first-child {
			width: 100%;
			padding: 30px 30px 0 30px;
		}
	}

	@media (max-width: 996px) {
		& > div:first-child {
			gap: 0;
			padding: 20px 10px 0 10px;
		}
	}

	@media (max-width: 768px) {
		& > div:first-child {
			flex-direction: column;
			gap: 40px;
		}
	}

	@media (min-width: 1600px) {
		margin-top: 120px;

		& > div:first-child {
			padding: 80px 100px;
			gap: 110px;
		}
	}
`;

export const FirstColumn = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	a {
		font-family: "Reost Bold";
		font-size: calc(20px + 1.25vw);
		font-weight: 600;
		line-height: calc(26px + 1.401vw);
		text-align: left;
		text-decoration: none;
		color: #000000;
	}

	@media (min-width: 1600px) {
		a {
			font-size: 40px;
			line-height: 48.41px;
		}
	}
`;

export const MiddleColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(9px + 0.563vw);

	h3,
	a {
		font-size: calc(10px + 0.625vw);
		font-weight: 400;
		line-height: calc(12px + 0.762vw);
		letter-spacing: -0.011em;
		text-align: left;
		margin: 0;
		text-decoration: none;
		color: #262626;
	}

	h3 {
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 6px;
	}

	@media (min-width: 1600px) {
		gap: 18px;

		h3,
		span {
			font-size: 20px;
			line-height: 24.2px;
		}
	}
`;

export const LastColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	h3 {
		font-size: calc(10px + 0.625vw);
		font-weight: 500;
		line-height: calc(12px + 0.762vw);
		text-align: left;
		text-transform: uppercase;
	}

	& > form {
		width: 100%;

		// error
		p {
			font-size: calc(6px + 0.375vw);
			font-weight: 400;
			line-height: calc(7px + 0.441vw);
			text-align: left;
			color: #d00306;
			margin: 9px 0 0 0;
			width: 100%;
			display: flex;
			justify-content: flex-start;
		}
	}

	& > div,
	& > form > div {
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;

		input {
			width: 100%;
			padding: calc(9px + 0.563vw) calc(32.5px + 2.031vw) calc(7.5px + 0.469vw) calc(8.5px + 0.531vw);
			border-radius: 32px;
			border: 1px;
		}
	}

	& > div:last-child {
		margin-top: 24px;

		span {
			font-weight: 400;
			font-size: calc(10px + 0.625vw);
			line-height: 100%;
			color: #262626;

			a {
				font-weight: 600;
				text-decoration: none;
				color: #262626;
			}
		}
	}

	@media (max-width: 480px) {
		& > div:last-child {
			margin-top: 16px;

			span {
				font-size: 16px;
			}
		}
	}

	@media (min-width: 1600px) {
		h3 {
			font-size: 20px;
			line-height: 21px;
		}

		& > div {
			width: 322px;

			input {
				padding: 18px 65px 15px 17px;
				border-radius: 32px;
			}
		}
	}
`;

export const SubscriberInput = styled.input`
	border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
`;

export const SocialLinks = styled.div`
	margin-top: 40px;
	display: flex;
	gap: calc(10px + 0.625vw);

	@media (min-width: 1600px) {
		margin-top: 40px;
		gap: 20px;
	}
`;

export const SubscribeButton = styled.button`
	cursor: pointer;
	position: absolute;
	right: 0;
	width: calc(24px + 1.5vw);
	height: calc(24px + 1.5vw);
	border-radius: 29px;
	background: #e9e9e9;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	//transform: translateX(14px);

	@media (min-width: 1600px) {
		width: 48px;
		height: 48px;
	}
`;

export const FooterLogo = styled.div`
	width: 100%;
	padding: 0;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;

	span {
		margin: 0;
		transform: translateY(calc(6px + 7.5vw)) translateX(calc(-0.313vw));
		font-family: "Reost Bold";
		font-size: calc(5px + 24.375vw);
		font-weight: 700;
		line-height: calc(10px + 30.728vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #ffffff;
	}

	@media (min-width: 1400px) and (max-width: 1500px) {
		span {
			transform: translateY(calc(6px + 8.7vw)) translateX(calc(-0.313vw));
		}
	}

	@media (min-width: 768px) and (max-width: 1400px) {
		span {
			transform: translateY(calc(6px + 9.1vw)) translateX(calc(-0.313vw));
		}
	}

	@media (max-width: 600px) {
		span {
			transform: translateY(calc(6px + 9.1vw)) translateX(calc(-0.313vw));
		}
	}

	@media (min-width: 1600px) {
		span {
			transform: translateY(106px) translateX(-5px);
			font-size: 395px;
			line-height: 501.65px;
		}
	}
`;
