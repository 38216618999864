import CustomerProfileForm from "../../Components/Organisms/CustomerProfile/CustomerProfileForm";

const CustomerProfilePage = () => {
	return (
		<div>
			<CustomerProfileForm />
		</div>
	);
};

export default CustomerProfilePage;
