import { memo, useState } from "react";
import { useMobileDetector, useTabletDetector } from "../../../../utils/customHooks";
import {
	BalanceCard,
	BalanceContainer,
	BalanceHeading,
	BalanceTransactions,
	TransactionStatus,
	FilterDropdown,
	TransactionTableDesktop,
	TransactionTableMobile,
	TransactionAmountDesktop,
	TransactionAmountMobile,
	TransactionPayment,
	TransactionHeading,
	FilterDropdownButton,
	TranscationArrowButton,
	TransactionRowMobile,
	TransactionRowDesktop,
	TransactionMobileBody,
	MobilePaymentId,
	MobileDateCreated,
	MobilePaymentMethod,
} from "./styles";
import succeededImg from "../../../../Assets/Images/CelebrityProfile/succeeded-icon.svg";
import pendingImg from "../../../../Assets/Images/CelebrityProfile/pending-icon.svg";
import declinedImg from "../../../../Assets/Images/CelebrityProfile/declined-icon.svg";
import createImg from "../../../../Assets/Images/CelebrityProfile/create-icon.svg";
import visaImg from "../../../../Assets/Images/CelebrityProfile/visa-icon.svg";
import mastercardImg from "../../../../Assets/Images/CelebrityProfile/mastercard-icon.svg";
import arrowDownImg from "../../../../Assets/Images/Layout/Navbar/arrow-down-icon.svg";
import workInProgressImg from "../../../../Assets/Images/CelebrityProfile/work-in-progress-icon.svg";
import inReviewImg from "../../../../Assets/Images/CelebrityProfile/in-review-icon.svg";
import availableImg from "../../../../Assets/Images/CelebrityProfile/available-icon.svg";

const balances = [
	{ label: "Work in progress", img: workInProgressImg, price: "50000" },
	{ label: "In Review", img: inReviewImg, price: "50000" },
	{ label: "Pending", img: pendingImg, price: "20000" },
	{ label: "Available", img: availableImg, price: "100000" },
];

const statuses = {
	succeeded: { label: "succeeded", message: "Succeeded", img: succeededImg },
	pending: { label: "pending", message: "Pending", img: pendingImg },
	declined: { label: "declined", message: "Declined", img: declinedImg },
	create: { label: "create", message: "Create", img: createImg },
};

const bankImages = {
	visa: visaImg,
	mastercard: mastercardImg,
};

const transactions = [
	{
		paymentId: "06c1774-7f3d-46ad-fb554-b90a8",
		status: statuses.succeeded,
		amount: "19623",
		payment: {
			bankImage: bankImages.visa,
			cardDigits: "154642124242",
		},
		dateCreated: "Mar 23, 2022, 13:00 PM",
	},
	{
		paymentId: "06c1774-7f3d-46ad-fb554-b90a8",
		status: statuses.pending,
		amount: "19623",
		payment: {
			bankImage: bankImages.mastercard,
			cardDigits: "154642124242",
		},
		dateCreated: "Mar 23, 2022, 13:00 PM",
	},
	{
		paymentId: "06c1774-7f3d-46ad-fb554-b90a8",
		status: statuses.declined,
		amount: "19623",
		payment: {
			bankImage: bankImages.mastercard,
			cardDigits: "154642124242",
		},
		dateCreated: "Mar 23, 2022, 13:00 PM",
	},
	{
		paymentId: "06c1774-7f3d-46ad-fb554-b90a8",
		status: statuses.succeeded,
		amount: "19623",
		payment: {
			bankImage: bankImages.visa,
			cardDigits: "154642124242",
		},
		dateCreated: "Mar 23, 2022, 13:00 PM",
	},
	{
		paymentId: "06c1774-7f3d-46ad-fb554-b90a8",
		status: statuses.create,
		amount: "19623",
		payment: {
			bankImage: bankImages.visa,
			cardDigits: "154642124242",
		},
		dateCreated: "Mar 23, 2022, 13:00 PM",
	},
];

const filters = ["Weekly", "Monthly"];

const CelebrityProfileBalance = () => {
	const isMobile = useMobileDetector();
	// const isTablet = useTabletDetector();
	const [filterIsOpen, setFilterIsOpen] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState("Weekly");

	const handleFilterSelect = (e) => {
		setSelectedFilter(e.target.name);
		setFilterIsOpen(false);
	};

	return (
		<BalanceContainer>
			<BalanceHeading>
				<h2>Balance</h2>
				<div>
					{balances.map((balance, index) => (
						<BalanceCard key={index}>
							<h4>
								<img loading="lazy" src={balance.img} alt="" /> {balance.label}
							</h4>
							<span>AMD {balance.price}</span>
						</BalanceCard>
					))}
				</div>
			</BalanceHeading>

			<BalanceTransactions>
				<TransactionHeading>
					<h2>Transactions</h2>
					<div>
						<button onClick={() => setFilterIsOpen(!filterIsOpen)}>{selectedFilter}</button>
						{filterIsOpen && (
							<FilterDropdown>
								{filters.map((filter, index) => (
									<FilterDropdownButton
										key={index}
										name={filter}
										onClick={handleFilterSelect}
										current={selectedFilter === filter ? 1 : 0}
									>
										{filter}
									</FilterDropdownButton>
								))}
							</FilterDropdown>
						)}
					</div>
				</TransactionHeading>
				{!isMobile ? (
					<TransactionTableDesktop>
						<TransactionRowDesktop type="heading">
							<th>Payment ID</th>
							<th>Status</th>
							<th>Amount</th>
							<th>p. method</th>
							<th>Creation date</th>
						</TransactionRowDesktop>

						{transactions.map((transaction, index) => (
							<TransactionRowDesktop key={index} type="row">
								<td>
									<span>
										{transaction.paymentId.slice(0, 18)}...{transaction.paymentId.slice(-4)}
									</span>
								</td>
								<td>
									<TransactionStatus status={transaction.status.label}>
										<img loading="lazy" src={transaction.status.img} alt="" />
										{transaction.status.message}
									</TransactionStatus>
								</td>
								<td>
									<TransactionAmountDesktop>
										${transaction.amount} <span>USD</span>
									</TransactionAmountDesktop>
								</td>
								<td>
									<TransactionPayment>
										<img loading="lazy" src={transaction.payment.bankImage} alt="" />
										&#x2022;&#x2022;&#x2022;&#x2022;
										{transaction.payment.cardDigits.slice(-4)}
									</TransactionPayment>
								</td>
								<td>
									<span>{transaction.dateCreated}</span>
								</td>
							</TransactionRowDesktop>
						))}
					</TransactionTableDesktop>
				) : (
					<TransactionTableMobile>
						{transactions.map((transaction, index) => (
							<TransactionRowMobile key={index}>
								<TransactionAmountMobile>
									<div>
										+${transaction.amount} <span>USD</span>
									</div>
									<div>
										<TransactionStatus status={transaction.status.label}>
											<img loading="lazy" src={transaction.status.img} alt="" />
											{transaction.status.message}
										</TransactionStatus>
										<TranscationArrowButton>
											<img loading="lazy" src={arrowDownImg} alt="Open Transaction" />
										</TranscationArrowButton>
									</div>
								</TransactionAmountMobile>

								<TransactionMobileBody>
									<MobilePaymentId>
										<h4>payment id</h4>
										<span>
											{transaction.paymentId.slice(0, 18)}...{transaction.paymentId.slice(-4)}
										</span>
									</MobilePaymentId>
									<MobileDateCreated>
										<h4>creation date</h4>
										<span>Mar 23, 2022, 13:00 PM</span>
									</MobileDateCreated>
									<MobilePaymentMethod>
										<h4>p. method</h4>
										<TransactionPayment>
											<img loading="lazy" src={transaction.payment.bankImage} alt="" />
											&#x2022;&#x2022;&#x2022;&#x2022;
											{transaction.payment.cardDigits.slice(-4)}
										</TransactionPayment>
									</MobilePaymentMethod>
								</TransactionMobileBody>
							</TransactionRowMobile>
						))}
					</TransactionTableMobile>
				)}
			</BalanceTransactions>
		</BalanceContainer>
	);
};

export default memo(CelebrityProfileBalance);
