import { gql } from "@apollo/client";

export const GET_CATEGORY_QUERY = gql`
	query GetCategory($categoryId: String!) {
		getCategory(categoryId: $categoryId) {
			id
			name
		}
	}
`;

export const GET_ALL_CATEGORIES_QUERY = gql`
	query GetAllCategories {
		getAllCategories {
			id
			name
		}
	}
`;

export const ADD_CATEGORY_MUTATION = gql`
	mutation AddCategory($categoryName: String!) {
		addCategory(categoryName: $categoryName)
	}
`;

export const DELETE_CATEGORY_MUTATION = gql`
	mutation DeleteCategory($categoryId: String!) {
		deleteCategory(categoryId: $categoryId)
	}
`;
