import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isAuthenticated: false,
	tokens: {
		access: "",
		refresh: "",
	},
	loading: false,
	error: null,
	otpRequested: false,
	otpContactInfo: null,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setError: (state, action) => {
			state.error = action.payload;
		},
		setOTPRequested: (state, action) => {
			state.otpRequested = true;
			state.otpContactInfo = action.payload;
		},
		setAuthenticated: (state, action) => {
			state.isAuthenticated = true;
			state.tokens = action.payload;
		},
		signOut: (state) => {
			state.isAuthenticated = false;
			state.tokens = { access: "", refresh: "" };
			state.otpRequested = false;
			state.otpContactInfo = null;
		},
		clearError: (state) => {
			state.error = null;
		},
		renewToken: (state, action) => {
			state.tokens = action.payload;
		},
	},
});

export const { setLoading, setError, setOTPRequested, setAuthenticated, signOut, clearError, renewToken } =
	authSlice.actions;

export default authSlice.reducer;
