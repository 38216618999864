import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	max-width: 1400px;
	position: relative;
	left: 0;
	right: 0;
	margin: 30px auto 0;
	background-color: #f8efff;
	border-radius: 50px;
	display: flex;
	align-items: center;
	//height: 377px;

	@media (max-width: 1200px) {
		border-radius: 20px;
	}

	@media (max-width: 996px) {
		flex-direction: column;
		gap: 40px;
		//min-height: 474px;
	}
`;

export const SectionCol = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(17px + 1.063vw);
	max-width: 504px;
	//padding: calc(22.5px + 1.406vw) calc(41.5px + 2.594vw) calc(22.5px + 1.406vw) calc(35px + 2.188vw);
	padding: 45px 83px 45px 70px;

	@media (max-width: 996px) {
		padding: 47px 16px 0;
	}

	@media (min-width: 1600px) {
		gap: 34px;
		padding: 45px 83px 45px 70px;
	}
`;

export const SectionHeading = styled.h1`
	font-size: calc(20px + 1.25vw);
	font-weight: 500;
	line-height: calc(29px + 1.813vw);
	letter-spacing: -0.011em;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #000000;
	text-transform: uppercase;
	margin: 0;
	font-style: normal;

	span {
		font-style: italic;
	}

	@media (min-width: 1600px) {
		font-size: 40px;
		line-height: 58px;
	}
`;

export const SectionButton = styled.button`
	cursor: pointer;
	padding: calc(9px + 0.563vw) calc(25px + 1.563vw);
	background-color: #121212;
	border-radius: 110px;
	font-size: calc(12px + 0.75vw);
	font-weight: 600;
	line-height: calc(18px + 1.125vw);
	letter-spacing: -0.011em;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #ffffff;

	@media (max-width: 996px) {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	@media (min-width: 1600px) {
		padding: 18px 50px;
		font-size: 24px;
		line-height: 36px;
	}
`;

export const ImageCol = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	//right: calc(10px + 9.75vw);
	right: 0;

	img {
		position: absolute;
		right: calc(10px + 9.75vw);
		bottom: 0;
		max-width: 562px;
		width: 100%;
		height: 100%;
	}

	@media (min-width: 1200px) and (max-width: 1400px) {
		img {
			right: calc(10px + 3.75vw);
		}
	}

	@media (min-width: 996px) and (max-width: 1200px) {
		img {
			right: 5px;
		}
	}

	@media (max-width: 996px) {
		position: relative;
		right: 0;
		display: flex;
		justify-content: center;

		img {
			position: static;
			width: 90%;
		}
	}

	@media (min-width: 1600px) {
		img {
			right: 182px;
		}
	}
`;
