import { useMobileDetector, useTabletDetector } from "../../../../utils/customHooks";
import { CelebritySwiperContainer, Section, SectionColumn, SectionContainer, SectionHeading, TextRow } from "./styles";
import CelebritySwiper from "../../../Molecules/Home/CelebritySwiper";
import { useNavigate } from "react-router-dom";
import { memo } from "react";

const HeroSection = () => {
	const isMobile = useMobileDetector();
	const isTablet = useTabletDetector();

	const navigate = useNavigate();

	return (
		<Section>
			<SectionColumn>
				<SectionContainer>
					<SectionHeading>
						<TextRow>Get a Personalized</TextRow>
						<TextRow>
							<span>Video</span> From your
						</TextRow>
						<TextRow>Favorite Celebrity!</TextRow>
					</SectionHeading>
					{!isTablet && !isMobile && (
						<>
							<p>Make every moment unforgettable with custom videos from the celebs you adore.</p>
							<button onClick={() => navigate("/category/all-celebrities")}>Book a Video</button>
						</>
					)}
				</SectionContainer>
			</SectionColumn>

			<SectionColumn>
				<CelebritySwiperContainer>
					<CelebritySwiper />
				</CelebritySwiperContainer>
			</SectionColumn>
		</Section>
	);
};

export default memo(HeroSection);
