import HeroSection from "../../Components/Organisms/Home/HeroSection";
import WorkSteps from "../../Components/Organisms/Home/WorkSteps";
import CelebrityGift from "../../Components/Organisms/Home/CelebrityGift";
import CategoriesSwiper from "../../Components/Organisms/Home/CategoriesSwiper";
import BecomePartner from "../../Components/Organisms/Home/BecomePartner";
import FAQ from "../../Components/Organisms/Home/FAQ";

const HomePage = () => {
	return (
		<div>
			<HeroSection />
			<WorkSteps />
			<CelebrityGift />
			<CategoriesSwiper />
			<BecomePartner />
			<FAQ />
		</div>
	);
};

export default HomePage;
