import { gql } from "@apollo/client";

export const GET_SUBSCRIBER_QUERY = gql`
	query GetSubscriber($subscriberId: String!) {
		getSubscriber(subscriberId: $subscriberId) {
			id
			email
		}
	}
`;

export const GET_ALL_SUBSCRIBERS_QUERY = gql`
	query GetAllSubscribers {
		getAllSubscribers {
			id
			email
		}
	}
`;

export const ADD_SUBSCRIBER_MUTATION = gql`
	mutation AddSubscriber($email: String!) {
		addSubscriber(email: $email)
	}
`;

export const DELETE_SUBSCRIBER_MUTATION = gql`
	mutation DeleteSubscriber($subscriberId: String!) {
		deleteSubscriber(subscriberId: $subscriberId)
	}
`;
