import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./reducers/auth";
import userReducer from "./reducers/user";

const persistConfig = {
	key: "root",
	storage,
	// blacklist: [""]
	whitelist: ["auth", "user"],
};

const rootReducer = combineReducers({
	auth: authReducer,
	user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
				// Ignore these paths in the state
				ignoredPaths: ["auth.tokens"],
			},
		}),
});

export const persistor = persistStore(store);
