import { Swiper } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { useMobileDetector } from "../../../../utils/customHooks";
import { CelebrityColumn, CelebrityImage, CelebrityInfo, CustomSwiperSlide } from "./styles";
import "swiper/css";
import heroSection1 from "../../../../Assets/Images/Home/heroSection1.webp";
import heroSection2 from "../../../../Assets/Images/Home/heroSection2.webp";

const SLIDE_HEIGHTS = {
	info: {
		large: "168px",
		mobile: "119px",
	}, // Height for info slides
	image: {
		large: "367px",
		mobile: "258px",
	}, // Height for image slides
};

const SWIPER_CONFIG = {
	direction: "vertical",
	loop: true,
	speed: 5000, // Increased speed for smoother continuous movement
	modules: [Autoplay],
	allowTouchMove: false, // Disable touch/mouse interactions
	watchSlidesProgress: true, // Enable continuous tracking of slides
	className: "eachSlide swiper-no-swiping",
};

const CelebritySwiper = () => {
	const isMobile = useMobileDetector();

	return (
		<>
			<CelebrityColumn>
				<Swiper
					{...SWIPER_CONFIG}
					slidesPerView="auto"
					autoplay={{
						delay: 0,
						reverseDirection: true,
						disableOnInteraction: false,
						pauseOnMouseEnter: false,
					}}
					spaceBetween={20}
				>
					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.info.mobile : SLIDE_HEIGHTS.info.large}>
						<CelebrityInfo variant="blue">
							<div>
								<span>UP TO</span>
								<span>100+</span>
								<span>Active Celebrities.</span>
							</div>
						</CelebrityInfo>
					</CustomSwiperSlide>

					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.image.mobile : SLIDE_HEIGHTS.image.large}>
						<CelebrityImage>
							<img loading="lazy" src={heroSection1} alt="Celebrity" />
						</CelebrityImage>
					</CustomSwiperSlide>

					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.info.mobile : SLIDE_HEIGHTS.info.large}>
						<CelebrityInfo variant="gray">
							<div>
								<span>Your Moment, Their Voice!</span>
								<button>Get Started</button>
							</div>
						</CelebrityInfo>
					</CustomSwiperSlide>

					{/* duplicate slide for smooth looping */}
					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.image.mobile : SLIDE_HEIGHTS.image.large}>
						<CelebrityImage>
							<img loading="lazy" src={heroSection1} alt="Celebrity" />
						</CelebrityImage>
					</CustomSwiperSlide>

					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.info.mobile : SLIDE_HEIGHTS.info.large}>
						<CelebrityInfo variant="blue">
							<div>
								<span>UP TO</span>
								<span>100+</span>
								<span>Active Celebrities.</span>
							</div>
						</CelebrityInfo>
					</CustomSwiperSlide>

					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.image.mobile : SLIDE_HEIGHTS.image.large}>
						<CelebrityImage>
							<img loading="lazy" src={heroSection1} alt="Celebrity" />
						</CelebrityImage>
					</CustomSwiperSlide>
				</Swiper>
			</CelebrityColumn>

			<CelebrityColumn>
				<Swiper
					{...SWIPER_CONFIG}
					slidesPerView="auto"
					autoplay={{
						delay: 0,
						disableOnInteraction: false,
						pauseOnMouseEnter: false,
					}}
					spaceBetween={20}
				>
					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.info.mobile : SLIDE_HEIGHTS.info.large}>
						<CelebrityInfo variant="pink">
							<div>
								<span>UP TO</span>
								<span>2,000+</span>
								<span>Registered Users</span>
							</div>
						</CelebrityInfo>
					</CustomSwiperSlide>

					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.image.mobile : SLIDE_HEIGHTS.image.large}>
						<CelebrityImage>
							<img loading="lazy" src={heroSection2} alt="Celebrity" />
						</CelebrityImage>
					</CustomSwiperSlide>

					{/* duplicate */}
					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.info.mobile : SLIDE_HEIGHTS.info.large}>
						<CelebrityInfo variant="pink">
							<div>
								<span>UP TO</span>
								<span>2,000+</span>
								<span>Registered Users</span>
							</div>
						</CelebrityInfo>
					</CustomSwiperSlide>

					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.image.mobile : SLIDE_HEIGHTS.image.large}>
						<CelebrityImage>
							<img loading="lazy" src={heroSection2} alt="Celebrity" />
						</CelebrityImage>
					</CustomSwiperSlide>

					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.info.mobile : SLIDE_HEIGHTS.info.large}>
						<CelebrityInfo variant="pink">
							<div>
								<span>UP TO</span>
								<span>2,000+</span>
								<span>Registered Users</span>
							</div>
						</CelebrityInfo>
					</CustomSwiperSlide>

					<CustomSwiperSlide height={isMobile ? SLIDE_HEIGHTS.image.mobile : SLIDE_HEIGHTS.image.large}>
						<CelebrityImage>
							<img loading="lazy" src={heroSection2} alt="Celebrity" />
						</CelebrityImage>
					</CustomSwiperSlide>
				</Swiper>
			</CelebrityColumn>
		</>
	);
};

export default CelebritySwiper;
