import { useEffect, useState, memo } from "react";
// import { Link } from "react-router-dom";
import {
	Section,
	SectionHeading,
	SectionContainer,
	Payment,
	OrderSummary,
	OrderHeading,
	PaymentForm,
	FormRow,
	SaveCardCheckbox,
	PayButton,
	OrderDetails,
	OrderImage,
	OrderDiscount,
	OrderTotal,
	CardNumberRow,
	ExpirationDateRow,
	CVVRow,
	BookingButton,
	PayButtonMobileContainer,
} from "./styles";
import { CartPopupBody, CartPopupContainer } from "../../../../Layouts/Default/Navbar/styles";
import checkboxIcon from "../../../../Assets/Images/SignUp/checkbox-checked.svg";
import celebrityImg1 from "../../../../Assets/Images/UserCelebrity/celebrity1.webp";
// import { SocialLinks } from "../../../../Layouts/CelebrityProfile/styles";
// import { Link } from "react-router-dom";
// import tiktokImg from "../../../../Assets/Images/CelebrityProfile/tiktok-icon.svg";
// import instagramImg from "../../../../Assets/Images/CelebrityProfile/instagram-icon.svg";
// import facebookImg from "../../../../Assets/Images/CelebrityProfile/facebook-icon.svg";
// import youtubeImg from "../../../../Assets/Images/CelebrityProfile/youtube-icon.svg";
import successImg from "../../../../Assets/Images/Booking/booking-success.svg";
import { useMobileDetector } from "../../../../utils/customHooks";

const BookingOrder = () => {
	const [checkboxChecked, setCheckboxChecked] = useState(false);
	const [formData, setFormData] = useState({
		cardNumber: "",
		expirationDate: "",
		cvv: "",
		discountCode: "",
	});
	const [bookingSuccess, setBookingSuccess] = useState(false);
	const [validationErrors, setValidationErrors] = useState({});

	const isMobile = useMobileDetector();

	useEffect(() => {
		if (bookingSuccess) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflowY = "auto";
		}
	}, [bookingSuccess]);

	const validate = () => {
		const newErrors = {};
		const { cardNumber, expirationDate, cvv, discountCode } = formData;

		// Card Number Validation
		const cardNumberRaw = cardNumber.replace(/\s/g, ""); // Remove spaces
		if (!cardNumberRaw) {
			newErrors.cardNumber = "Card number is required";
		} else if (cardNumberRaw.length !== 16 || !/^\d{16}$/.test(cardNumberRaw)) {
			newErrors.cardNumber = "Use a valid card number";
		}

		// Expiration Date Validation
		if (!expirationDate) {
			newErrors.expirationDate = "Expiration date is required";
		} else if (!/^\d{2}\/\d{2}$/.test(expirationDate)) {
			newErrors.expirationDate = "Expiration month or year is incorrect";
		} else {
			const [month, year] = expirationDate.split("/").map(Number);
			if (month < 1 || month > 12) {
				newErrors.expirationDate = "Expiration month or year is incorrect";
			}
			const currentYear = new Date().getFullYear() % 100; // Last two digits of the current year
			if (year < currentYear) {
				newErrors.expirationDate = "Year must be current or in the future";
			}
		}

		// CVV Validation
		if (!cvv) {
			newErrors.cvv = "CVV is required";
		} else if (cvv.length !== 3 || !/^\d{3}$/.test(cvv)) {
			newErrors.cvv = "Enter a valid 3-digit CVV";
		}

		// if (discountCode.length) {
		// 	newErrors.discountCode = "";
		// }

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		let formattedValue = value;

		if (name === "cardNumber") {
			// Remove all non-numeric characters
			formattedValue = value.replace(/\D/g, "");
			// Add a space after every 4 characters
			formattedValue = formattedValue.replace(/(\d{4})/g, "$1 ").trim();
		}

		if (name === "expirationDate") {
			// Remove all non-numeric characters
			formattedValue = value.replace(/\D/g, "");
			// Format as MM/YY
			if (formattedValue.length <= 2) {
				// First two digits
				formattedValue = formattedValue;
			} else if (formattedValue.length <= 4) {
				// Add a slash after the first two digits
				formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
			} else {
				// Restrict to MM/YY format
				formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}`;
			}
		}

		if (name === "cvv") {
			// Remove all non-numeric characters and limit to 3 digits
			formattedValue = value.replace(/\D/g, "").slice(0, 3);
		}

		setFormData((prevData) => ({
			...prevData,
			[name]: formattedValue,
		}));

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (validate()) {
			// Proceed with form submission logic
			setBookingSuccess(true);
		}
	};

	return (
		<Section>
			<SectionHeading></SectionHeading>

			<SectionContainer>
				<Payment>
					<h2>Payment</h2>
					<PaymentForm onSubmit={handleSubmit}>
						<FormRow>
							<CardNumberRow error={validationErrors.cardNumber ? 1 : 0}>
								<label htmlFor="cardNumber">Card Number</label>
								<div>
									<input
										type="text"
										name="cardNumber"
										id="cardNumber"
										maxLength="19"
										placeholder="1234 5678 9101 1121"
										value={formData.cardNumber}
										onChange={handleChange}
									/>
									{validationErrors.cardNumber && <p>{validationErrors.cardNumber}</p>}
								</div>
							</CardNumberRow>
						</FormRow>
						<FormRow>
							<ExpirationDateRow error={validationErrors.expirationDate ? 1 : 0}>
								<label htmlFor="expirationDate">Expiration Date</label>
								<div>
									<input
										type="text"
										name="expirationDate"
										id="expirationDate"
										placeholder="MM/YY"
										value={formData.expirationDate}
										onChange={handleChange}
									/>
									{validationErrors.expirationDate && <p>{validationErrors.expirationDate}</p>}
								</div>
							</ExpirationDateRow>
							<CVVRow error={validationErrors.cvv ? 1 : 0}>
								<label htmlFor="cvv">CVV</label>
								<div>
									<input
										type="text"
										name="cvv"
										id="cvv"
										placeholder="123"
										maxLength="19"
										value={formData.cvv}
										onChange={handleChange}
									/>
									{validationErrors.cvv && <p>{validationErrors.cvv}</p>}
								</div>
							</CVVRow>
						</FormRow>
						<SaveCardCheckbox>
							<span>
								{checkboxChecked ? (
									<img
										src={checkboxIcon}
										alt=""
										onClick={() => setCheckboxChecked(!checkboxChecked)}
									/>
								) : (
									<span onClick={() => setCheckboxChecked(!checkboxChecked)}></span>
								)}
								Save card details
							</span>
						</SaveCardCheckbox>
						{!isMobile && <PayButton type="submit">Pay AMD 65.000</PayButton>}
					</PaymentForm>
				</Payment>
				<OrderSummary>
					<OrderHeading>
						<h2>Order Summary</h2>
						<p>
							Your video will be delivered to your email within 48 hours after booking. A secure link will
							allow easy access and download.
						</p>
					</OrderHeading>
					<OrderDetails>
						<OrderImage>
							<div>
								<img loading="lazy" src={celebrityImg1} alt="" />
								<span>Personalized video</span>
							</div>
							<span>AMD 55.000</span>
						</OrderImage>
						<OrderDiscount error={validationErrors.discountCode ? 1 : 0}>
							<input
								type="text"
								placeholder="Discount code"
								value={formData.discountCode}
								onChange={handleChange}
							/>
							{validationErrors.discountCode && <p>{validationErrors.discountCode}</p>}
							<button>Apply</button>
						</OrderDiscount>
						<OrderTotal>
							<span>Total</span>
							<span>AMD 55.000</span>
						</OrderTotal>
					</OrderDetails>
				</OrderSummary>

				{isMobile && (
					<PayButtonMobileContainer>
						<PayButton type="submit">Pay AMD 65.000</PayButton>
					</PayButtonMobileContainer>
				)}
			</SectionContainer>

			{bookingSuccess && (
				<CartPopupContainer>
					<CartPopupBody style={{ alignItems: "center", maxWidth: "410px", borderRadius: "40px" }}>
						<img loading="lazy" src={successImg} alt="Booking Success" />
						<h2>Success!</h2>
						<p>Thank you for your payment. We will be in contact with more details shortly</p>
						<BookingButton to={""} onClick={() => setBookingSuccess(false)}>
							Continue Booking
						</BookingButton>
					</CartPopupBody>
				</CartPopupContainer>
			)}
		</Section>
	);
};

export default memo(BookingOrder);
