import ContactDetails from "../../Components/Organisms/BecomePartner/ContactDetails";

const BecomePartnerPage = () => {
	return (
		<div>
			<ContactDetails />
		</div>
	);
};

export default BecomePartnerPage;
