import styled from "styled-components";
import arrowDownImg from "../../../../Assets/Images/Layout/Navbar/arrow-down-icon.svg";
import filterImg from "../../../../Assets/Images/Category/filter-icon.svg";
import { Link } from "react-router-dom";

export const Section = styled.section`
	width: 100%;
	max-width: 1400px;
	position: relative;
	left: 0;
	right: 0;
	margin: 40px auto 0;
	display: flex;
	gap: calc(20px + 1.25vw);

	& > div:first-child {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: calc(20px + 1.25vw);
	}

	@media (min-width: 768px) and (max-width: 996px) {
		gap: 10px;
	}

	@media (max-width: 768px) {
		flex-direction: column;
	}

	@media (min-width: 1600px) {
		gap: 40px;

		& > div:first-child {
			gap: 40px;
		}
	}
`;

export const CelebritiesFilterNav = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	h2 {
		margin: 0;
		display: flex;
		gap: 8px;
		align-items: center;
		font-size: calc(12px + 0.75vw);
		font-weight: 500;
		line-height: calc(14.5px + 0.909vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;

		strong {
			font-size: calc(12px + 0.75vw);
			line-height: calc(14.5px + 0.909vw);
		}

		span {
			font-size: calc(10px + 0.625vw);
			line-height: calc(12.1px + 0.756vw);
		}
	}

	& > div {
		display: flex;
		gap: calc(10px + 0.625vw);
	}

	@media (max-width: 480px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}

	@media (min-width: 1600px) {
		h2 {
			gap: 8px;
			font-size: 24px;
			line-height: 24.2px;

			strong {
				font-size: 24px;
				line-height: 29.05px;
			}

			span {
				font-size: 20px;
				line-height: 24.2px;
			}
		}

		& > div {
			gap: 20px;
		}
	}
`;

export const FilterButton = styled.button`
	background-color: transparent;
	color: #000000;
	border-radius: 30px;
	font-size: calc(10px + 0.625vw);
	font-weight: 500;
	line-height: calc(12px + 0.762vw);
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: calc(2px + 0.125vw);
	padding: calc(5.5px + 0.344vw) calc(14px + 0.875vw);
	border: 1px solid #d6cfdb;

	&:after {
		content: "";
		background-image: url(${filterImg});
		background-position: center;
		background-repeat: no-repeat;
		width: calc(16px + 1vw);
		height: calc(16px + 1vw);
		display: inline-block;
		transform: rotate(${(props) => props.isOpen && "180deg"});
		transition: 0.3s;
	}

	@media (max-width: 480px) {
		gap: 4px;

		span {
			font-size: 16px;
			line-height: 19.36px;
		}

		img {
			width: 24px;
			height: 24px;
		}
	}

	@media (min-width: 1600px) {
		gap: 4px;
		padding: 11px 28px;

		span {
			font-size: 20px;
			line-height: 24.2px;
		}

		&:after {
			width: 32px;
			height: 32px;
		}
	}
`;

export const CelebritiesGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(${(props) => (props.isopen ? "226px" : "314px")}, 1fr));
	grid-gap: ${(props) => (props.isopen ? "calc(11px + 0.688vw)" : "calc(16px + 1vw)")};
	width: 100%;

	@media (min-width: 480px) and (max-width: 768px) {
		grid-template-columns: repeat(auto-fill, minmax(174px, 1fr));
		grid-gap: 14px;
	}

	@media (max-width: 480px) {
		grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
		grid-gap: 10px;
	}

	@media (min-width: 1600px) {
		grid-gap: ${(props) => (props.isopen ? "22px" : "32px")};
	}
`;

export const CelebrityCard = styled.div`
	width: 100%;
	max-width: ${(props) => (props.isopen ? "243px" : "326px")};
	display: flex;
	flex-direction: column;
	gap: calc(14px + 0.875vw);
	overflow: hidden;
	position: relative;
	margin: 0 auto;
	box-sizing: border-box;

	& > div:last-child {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	& > img:last-child {
		position: absolute;
		top: ${(props) => (props.isopen ? "21px" : "27px")};
		right: ${(props) => (props.isopen ? "24px" : "32px")};
		opacity: ${(props) => (props.hovered ? "1" : "0")};
		transition: opacity 0.3s ease-in-out;
		cursor: pointer;
		width: ${(props) => (props.isopen ? "24px" : "auto")};
		height: ${(props) => (props.isopen ? "24px" : "auto")};
	}

	@media (max-width: 768px) {
		max-width: 100%;

		& > div:first-child {
			height: ${(props) => (props.isopen ? "189px" : "189px")};
			border-radius: 16px;
		}
	}

	@media (max-width: 480px) {
		max-width: 100%;

		& > div:first-child {
			height: ${(props) => (props.isopen ? "178px" : "178px")};
			border-radius: 16px;
		}
	}

	@media (min-width: 1600px) {
		gap: 28px;

		& > div:first-child {
			height: ${(props) => (props.isopen ? "243px" : "326px")};
		}
	}
`;

export const CelebrityImg = styled.img`
	width: 100%;
	height: ${(props) => (props.isopen ? "calc(121.5px + 7.594vw)" : "calc(175px + 10.938vw)")};
	border-radius: 40px;
	object-fit: cover;
	visibility: ${(props) => (props.hasloaded ? "visible" : "hidden")};
	position: ${(props) => (props.hasloaded ? "relative" : "absolute")}; // Overlaps the placeholder
	transition: 0.3s ease-in-out;
`;

export const CelebrityInfo = styled.div`
	width: 100%;
	padding: calc(22px + 1.406vw) 0 0;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	transform: translateY(${(props) => (props.hovered && !props.ismobile ? "calc(-22px - 1.406vw)" : "0")});
	transition: 0.3s ease-in-out;
	gap: ${(props) => (props.isopen ? "calc(10px + 0.625vw)" : "calc(15px + 0.938vw)")};

	& > div:first-child {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: calc(9px + 0.563vw);
		box-sizing: border-box;

		& > span {
			font-size: ${(props) => (props.isopen && !props.ismobile ? "calc(8px + 0.5vw)" : "calc(11px + 0.688vw)")};
			font-weight: 500;
			line-height: ${(props) => (props.isopen && !props.ismobile ? "calc(9.5px + 0.616vw)" : "calc(13px + 0.852vw)")};
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
		}
	}

	h3 {
		margin: 0;
		font-weight: 500;
		font-size: ${(props) => (props.isopen && !props.ismobile ? "calc(7px + 0.438vw)" : "calc(10px + 0.625vw)")};
		line-height: ${(props) => (props.isopen && !props.ismobile ? "calc(8px + 0.559vw)" : "calc(12px + 0.762vw)")};
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
		text-decoration: none;
	}

	@media (max-width: 768px) {
		padding: 0;
	}

	@media (min-width: 1600px) {
		padding: 44.5px 0 0;
		transform: translateY(${(props) => (props.hovered ? "-44.5px" : "0")});
		gap: ${(props) => (props.isopen ? "20px" : "30px")};

		& > div:first-child {
			gap: 18px;

			& > span {
				font-size: ${(props) => (props.isopen ? "16px" : "22px")};
				line-height: ${(props) => (props.isopen ? "19.36px" : "26.63px")};
			}
		}

		a {
			font-size: ${(props) => (props.isopen ? "14px" : "20px")};
			line-height: ${(props) => (props.isopen ? "16.94px" : "24.2px")};
		}
	}
`;

export const CelebrityRating = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: calc(9px + 0.563vw);

	& > div {
		display: flex;
		align-items: center;
		gap: calc(2.5px + 0.156vw);

		img {
			// width: ${(props) => (props.isopen ? "calc(12px + 0.75vw)" : "auto")};
			// height: ${(props) => (props.isopen ? "calc(12px + 0.75vw)" : "auto")};
			width: calc(12px + 0.75vw);
			height: calc(12px + 0.75vw);
		}
	}

	span {
		font-size: ${(props) => (props.isopen ? "calc(7px + 0.438vw)" : "calc(10px + 0.625vw)")};
		font-weight: 400;
		line-height: ${(props) => (props.isopen ? "calc(8px + 0.559vw)" : "calc(12px + 0.762vw)")};
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #717680;
	}

	@media (min-width: 1600px) {
		gap: 18px;

		& > div {
			gap: 5px;

			img {
				width: ${(props) => (props.isopen ? "24px" : "auto")};
				height: ${(props) => (props.isopen ? "24px" : "auto")};
			}
		}

		span {
			font-size: ${(props) => (props.isopen ? "14px" : "20px")};
			line-height: ${(props) => (props.isopen ? "16.94px" : "24.2px")};
		}
	}
`;

export const BookingButton = styled(Link)`
	background-color: #7f56d9;
	padding: ${(props) => (props.isopen && !props.ismobile ? "9px calc(16px + 1vw)" : "9px calc(33.5px + 2.094vw)")};
	color: #ffffff;
	font-size: ${(props) => (props.isopen && !props.ismobile ? "calc(7px + 0.438vw)" : "calc(11px + 0.688vw)")};
	font-weight: 600;
	line-height: ${(props) => (props.isopen && !props.ismobile ? "calc(8px + 0.559vw)" : "calc(16.5px + 1.031vw)")};
	letter-spacing: -0.011em;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	border-radius: 110px;
	border: none;
	text-decoration: none;
	transform: translateY(${(props) => (props.hovered || props.ismobile ? "0" : "150%")});
	transition: 0.3s ease-in-out;
	width: 100%;
	// max-width: ${(props) => (props.isopen ? "180px" : "240px")};
	display: flex;
	justify-content: center;
	box-sizing: border-box;

	@media (min-width: 1600px) {
		padding: ${(props) => (props.isopen ? "9px 32px" : "9px 67px")};
		font-size: ${(props) => (props.isopen ? "14px" : "22px")};
		line-height: ${(props) => (props.isopen ? "16.94px" : "33px")};
	}
`;

export const FilterDropdown = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(20px + 1.25vw);

	h2 {
		margin: 0;
		font-weight: 600;
		font-size: calc(15px + 0.938vw);
		line-height: 150%;
		letter-spacing: -1.1px;
	}

	@media (max-width: 480px) {
		font-size: 18px;
	}

	@media (min-width: 1600px) {
		gap: 40px;

		h2 {
			font-size: 30px;
		}
	}
`;

export const FilterDropdownWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	gap: calc(10px + 0.625vw);
	width: 390px;

	@media (min-width: 768px) and (max-width: 996px) {
		width: calc(90px + 20.08vw);
	}

	@media (max-width: 768px) {
		width: 100%;
	}

	@media (min-width: 1600px) {
		gap: 20px;
	}
`;

export const FilterDropdownContainer = styled.details`
	width: 100%;
	padding: 12px 0;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	border-bottom: 1px solid #959595;
	cursor: pointer;
	transition: background-color 0.3s ease;
	box-sizing: border-box;
	position: relative;

	&[open] {
		border-bottom: none;

		& > summary:after {
			transform: rotate(180deg);
		}
	}

	@media (max-width: 768px) {
		padding: 10px 0;
	}

	@media (min-width: 1600px) {
		padding: calc(6px + 0.375vw) calc(4px + 0.25vw);
	}
`;

export const FilterDropdownTitle = styled.summary`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	list-style-type: none;
	cursor: pointer;
	gap: 8px;
	padding: 0 8px;

	span {
		&:first-child {
			font-weight: 400;
			font-size: calc(8px + 0.5vw);
			line-height: calc(9px + 0.623vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
		}

		&:nth-child(2) {
			font-size: calc(7px + 0.438vw);
			font-weight: 400;
			line-height: calc(8px + 0.537vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #717680;
		}
	}

	&::-webkit-details-marker {
		display: none;
	}

	&::after {
		content: url("${arrowDownImg}");
		position: absolute;
		transition: transform 0.2s ease;
		right: 0;
	}

	@media (max-width: 768px) {
		span {
			&:first-child {
				font-weight: 400;
				font-size: 16px;
				line-height: 100%;
				vertical-align: middle;
			}

			&:nth-child(2) {
				font-weight: 500;
				font-size: 12px;
				line-height: 150%;
			}
		}
	}

	@media (min-width: 1600px) {
		span {
			&:first-child {
				font-size: 16px;
				line-height: 18.96px;
			}

			&:nth-child(2) {
				font-size: 14px;
				line-height: 16.89px;
			}
		}
	}
`;

export const FilterDropdownMenu = styled.div`
	width: 100%;
	margin-top: calc(4px + 0.25vw);
	padding: calc(4px + 0.25vw) 0;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	box-sizing: border-box;

	@media (min-width: 1600px) {
		margin-top: 8px;
		padding: 8px 0;
	}
`;

export const FilterDropdownMenuItem = styled.div`
	width: 100%;
	padding: calc(4px + 0.25vw) calc(14px + 0.375vw);
	font-size: calc(7px + 0.438vw);
	cursor: pointer;
	color: #000000;
	box-sizing: border-box;

	&:hover {
		color: #828282;
	}

	@media (max-width: 768px) {
		font-weight: 500;
		font-size: 12px;
		line-height: 150%;
	}

	@media (min-width: 1600px) {
		padding: 8px 20px;
		font-size: 14px;
	}
`;

export const PriceRangeContainer = styled.div`
	background-color: #fff;
	border-radius: 10px;
	padding: 20px;
	width: 100%;
	max-width: 300px;
	margin: auto;
	box-sizing: border-box;

	@media (max-width: 480px) {
		max-width: 100%;
	}
`;

export const PriceInputBox = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;

	input[type="number"] {
		width: 40px;
		padding: 10px;
		border: 1px solid #ccc;
		border-radius: 5px;
		text-align: center;
		-moz-appearance: textfield;

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
`;

export const PriceMinBox = styled.div`
	width: 50%;
`;

export const PriceMaxBox = styled.div`
	width: 50%;
	margin-right: 10px;

	input {
		float: right;
	}
`;

export const RangeSlider = styled.div`
	position: relative;
	width: 100%;
	height: 3px;
	margin: 30px 0;
	background-color: #d5d7da;
	border-radius: 5px;

	input[type="range"] {
		position: absolute;
		width: 100%;
		top: 0;
		transform: translateY(-50%);
		background: none;
		pointer-events: none;
		appearance: none;
		height: 5px;

		&::-webkit-slider-thumb {
			height: 12px;
			width: 12px;
			border-radius: 50%;
			background: #7f56d9;
			pointer-events: auto;
			appearance: none;
			cursor: pointer;
			box-shadow: 0 0.125rem 0.5625rem -0.125rem rgba(0, 0, 0, 0.25);
			position: relative;
			z-index: 2; /* Ensure thumbs appear above the track */
		}

		&::-moz-range-thumb {
			height: 25px;
			width: 25px;
			border-radius: 50%;
			border: 3px solid #fff;
			background: #7f56d9;
			pointer-events: auto;
			cursor: pointer;
			box-shadow: 0 0.125rem 0.5625rem -0.125rem rgba(0, 0, 0, 0.25);
			position: relative;
			z-index: 2;
		}
	}
`;

export const SliderTrack = styled.div`
	height: 100%;
	position: absolute;
	background-color: #7f56d9;
	left: 0;
	right: 0;
	border-radius: 5px;
`;

export const MinTooltip = styled.div`
	left: 0;
	transform: translateX(-50%);
	position: absolute;
	top: -35px;
	font-size: 12px;
	color: #555;
	background-color: #fff;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 5px;
	white-space: nowrap;
	z-index: 1;
`;

export const MaxTooltip = styled.div`
	right: 0;
	transform: translateX(50%);
	position: absolute;
	top: -35px;
	font-size: 12px;
	color: #555;
	background-color: #fff;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 5px;
	white-space: nowrap;
	z-index: 1;
`;
