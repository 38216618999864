import { useState } from "react";
import { Link } from "react-router-dom";
import {
	Section,
	SectionHeading,
	SignUpForm,
	SignUpInputs,
	SignInLink,
	TelInputRow,
	TelCheckbox,
	CheckboxOpen,
	Checkbox,
	TermsAndPrivacy,
	EmailInputRow,
	SignUpButton,
	TelInputContainer,
} from "./styles";
// import eyeOnIcon from "../../../Assets/Images/SignIn/eye-on-icon.svg";
// import eyeOffIcon from "../../../Assets/Images/SignIn/eye-off-icon.svg";
import armeniaIcon from "../../../../Assets/Images/SignUp/armenia-icon.svg";
import russiaIcon from "../../../../Assets/Images/SignUp/russia-icon.svg";
import selectArrowIcon from "../../../../Assets/Images/SignUp/chevron-down.svg";
import checkboxIcon from "../../../../Assets/Images/SignUp/checkbox-checked.svg";
import { validateEmail } from "../../../../utils/email";

const countries = [
	{ code: "+374", name: "Armenia", icon: armeniaIcon, digitsLength: 8 },
	{ code: "+7", name: "Russia", icon: russiaIcon, digitsLength: 12 },
];

const SignUp = () => {
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		tel: "",
		password1: "",
		password2: "",
	});
	// const [passVisible1, setPassVisible1] = useState(false);
	// const [passVisible2, setPassVisible2] = useState(false);
	const [checkboxChecked, setCheckboxChecked] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState(countries[0]);
	const [validationErrors, setValidationErrors] = useState({});

	const validate = (formData) => {
		const newErrors = {};

		//  if first name is invalid
		// newErrors.firstName = "First Name is invalid";

		//  if last name is invalid
		// newErrors.lastName = "Last Name is invalid";

		// Check if firstName contains only alphabetic characters
		if (formData.firstName && /[^a-zA-Z\s]/.test(formData.firstName)) {
			newErrors.firstName = "First name can only contain letters and spaces";
		}

		// Check if lastName contains only alphabetic characters
		if (formData.lastName && /[^a-zA-Z\s]/.test(formData.lastName)) {
			newErrors.lastName = "Last name can only contain letters and spaces";
		}

		if (formData.email && !validateEmail(formData.email)) {
			newErrors.email = "Email is invalid";
		}

		//  if phone number is invalid
		if (formData.tel.length !== selectedCountry.digitsLength) {
			newErrors.tel = "Phone Number is invalid";
		}

		if (formData.password1 && formData.password1.length < 8) {
			newErrors.password1 = "Password must be at least 8 characters";
		}

		if (formData.password1 !== formData.password2) {
			newErrors.password2 = "Passwords do not match";
		}

		if (!formData.firstName) newErrors.firstName = "First Name is required";
		if (!formData.lastName) newErrors.lastName = "Last Name is required";
		if (!formData.email) newErrors.email = "Email is required";
		if (!formData.tel) newErrors.tel = "Phone Number is required";
		if (!formData.password1) newErrors.password1 = "Password is required";
		if (!formData.password2) newErrors.password2 = "Password is required";

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSelect = (country) => {
		setSelectedCountry(country);
		setIsOpen(false);
		setFormData((prevData) => ({
			...prevData,
			tel: "",
		}));
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "tel") {
			const regex = new RegExp(`^[0-9]{0,${selectedCountry.digitsLength}}$`);
			if (regex.test(value)) {
				setFormData((prevData) => ({
					...prevData,
					[name]: value,
				}));
			}
		} else if (name === "firstName" || name === "lastName") {
			setFormData((prevData) => ({
				...prevData,
				[name]: value.replace(/[^a-zA-Z\s]/g, ""),
			}));
		} else if (name === "password1" || name === "password2") {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		} else {
			setFormData((prevData) => ({
				...prevData,
				[name]: value,
			}));
		}

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (validate(formData)) {
			//
		}

		// if (validationErrors) {
		// 	console.log("Errors:", validationErrors);
		// 	return;
		// }

		//
	};

	return (
		<Section>
			<SectionHeading>
				<h2>Create your account</h2>
				<span>Keep it all together and you”ll be fine.</span>
			</SectionHeading>

			<SignUpForm action="" onSubmit={handleSubmit}>
				<SignUpInputs>
					<div>
						<TelInputRow error={validationErrors.tel}>
							<TelInputContainer>
								<TelCheckbox onClick={() => setIsOpen(!isOpen)}>
									<Checkbox>
										<img loading="lazy" src={selectedCountry.icon} alt={selectedCountry.name} />
										<img loading="lazy" src={selectArrowIcon} alt="select arrow" />
										<span>{selectedCountry.code}</span>
									</Checkbox>
									{isOpen && (
										<CheckboxOpen>
											{countries.map((country) => (
												<div key={country.code} onClick={() => handleSelect(country)}>
													<img loading="lazy" src={country.icon} alt={country.name} />
													<div>
														<span>{country.code}</span>
														<img loading="lazy" src={selectArrowIcon} alt="select arrow" />
													</div>
												</div>
											))}
										</CheckboxOpen>
									)}
								</TelCheckbox>
								<input
									type="text"
									placeholder="Phone number"
									name="tel"
									value={formData.tel}
									onChange={handleChange}
									style={{ padding: "16px 16px 16px 98px" }}
								/>
							</TelInputContainer>
							{validationErrors.tel && <p>{validationErrors.tel}</p>}
						</TelInputRow>
						<EmailInputRow error={validationErrors.email}>
							<input type="text" placeholder="Enter your email" name="email" onChange={handleChange} />
							{validationErrors.email && <p>{validationErrors.email}</p>}
						</EmailInputRow>
					</div>
				</SignUpInputs>

				<TermsAndPrivacy>
					<span>
						{checkboxChecked ? (
							<img loading="lazy" src={checkboxIcon} alt="" onClick={() => setCheckboxChecked(!checkboxChecked)} />
						) : (
							<span onClick={() => setCheckboxChecked(!checkboxChecked)}></span>
						)}
						I Agree to the
						<Link to="/">Terms & Privacy</Link>
					</span>
				</TermsAndPrivacy>

				<SignUpButton type="submit" disabled={!checkboxChecked} checkboxChecked={checkboxChecked}>
					Sign Up
				</SignUpButton>
				<SignInLink>
					Already have an account? <Link to="/auth/sign-in">Sign In</Link>
				</SignInLink>
			</SignUpForm>
		</Section>
	);
};

export default SignUp;
