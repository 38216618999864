import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { ApolloClient, InMemoryCache, ApolloProvider, from, Observable, split, gql } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import Routing from "./Routing";
import { renewToken, signOut } from "./reducers/auth";
import { parseJwt } from "./utils/jwtToken";
import { CELEBRITY_RENEW_TOKEN_MUTATION } from "./queries/celebrity";
import { CUSTOMER_RENEW_TOKEN_MUTATION } from "./queries/customer";
import "./Assets/style.css";

// Create HTTP link with environment awareness
const httpLink = createUploadLink({
	uri: process.env.NODE_ENV === "production" ? "https://server.fameeo.com/graphql" : "http://localhost:8080/graphql",
	// uri: "https://fameeo.com/graphql",
	// credentials: "include",
});

// Create WebSocket link with proper error handling
const wsLink = new GraphQLWsLink(
	createClient({
		url: process.env.NODE_ENV === "production" ? "wss://server.fameeo.com/graphql" : "ws://localhost:8080/graphql",
		connectionParams: () => {
			const token = store.getState().auth.tokens.access;
			// return token ? { Authorization: `Bearer ${token}` } : {};
			return { Authorization: `Bearer ${token}` };
		},
		on: {
			connected: () => console.log("WebSocket connected"),
			message: (message) => console.log("WebSocket message:", message),
			closed: (event) => console.log("WebSocket closed:", event),
			error: (err) => console.error("WebSocket error:", err),
		},
		// Custom WebSocket implementation to force graphql-ws subprotocol
		// webSocketImpl: class extends WebSocket {
		// 		constructor(url, protocols?) {
		// 			super(url, "graphql-ws"); // Force graphql-ws subprotocol
		// 		}
		// },
	}),
);

let isRefreshing = false;
let pendingRequests = [];

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
	console.log("graphQLErrors", graphQLErrors);
	console.log("networkError", networkError);
	console.log("operation", operation);

	const isPublicOperation = [
		"CelebritySignIn",
		"CustomerSignIn",
		"CelebritySignUp",
		"CustomerSignUp",
		"CelebrityRenewToken",
		"CustomerRenewToken",
	].includes(operation.operationName);

	if (graphQLErrors) {
		for (let err of graphQLErrors) {
			if (err?.extensions?.code === "UNAUTHENTICATED" && !isPublicOperation) {
				// Handle token refresh
				if (isRefreshing) {
					return new Observable((observer) => {
						pendingRequests.push(() => {
							forward(operation).subscribe(observer);
						});
					});
				}

				isRefreshing = true;

				return new Observable((observer) => {
					const state = store.getState();
					const refreshState = state.auth.tokens.refresh;
					const userRole = state.user.role;

					if (!refreshState) {
						store.dispatch(signOut());
						observer.error(err);
						isRefreshing = false;
						return;
					}

					const RENEW_TOKEN_MUTATION =
						userRole.type === "celebrity" ? CELEBRITY_RENEW_TOKEN_MUTATION : CUSTOMER_RENEW_TOKEN_MUTATION;

					parseJwt(refreshState)
						.then((userData) => {
							client
								.mutate({
									mutation: RENEW_TOKEN_MUTATION,
									variables: { refreshState },
								})
								.then((response) => {
									const tokens =
										userRole.type === "celebrity"
											? response.data.celebrityRenewToken
											: response.data.customerRenewToken;

									if (!tokens) {
										throw new Error("Token renewal failed");
									}

									store.dispatch(renewToken(tokens));

									operation.setContext({
										headers: {
											...operation.getContext().headers,
											authorization: `Bearer ${tokens.access}`,
										},
									});

									forward(operation).subscribe(observer);
									pendingRequests.forEach((callback) => callback());
									pendingRequests = [];
								})
								.catch((error) => {
									console.error("Token renewal failed:", error);
									store.dispatch(signOut());
									observer.error(error);
									pendingRequests.forEach((callback) => callback());
									pendingRequests = [];
								})
								.finally(() => {
									isRefreshing = false;
								});
						})
						.catch((error) => {
							console.error("JWT parse error:", error);
							store.dispatch(signOut());
							observer.error(error);
							isRefreshing = false;
						});
				});
			}
		}
	}

	if (networkError) {
		console.log("Network error:", networkError);
		if (networkError.statusCode === 401 && !isPublicOperation) {
			store.dispatch(signOut());
		}
	}

	return forward(operation);
});

const authLink = setContext((operation, { headers }) => {
	// List of operations that should not include auth token
	const publicOperations = [
		"CelebritySignIn",
		"CustomerSignIn",
		"CelebritySignUp",
		"CustomerSignUp",
		"CelebrityRenewToken",
		"CustomerRenewToken",
	];

	// If it's a public operation, don't add the auth header
	if (publicOperations.includes(operation.operationName)) {
		return {
			headers: {
				...headers,
				authorization: "",
			},
		};
	}

	// For all other operations, send the token as usual
	const token = store.getState().auth.tokens.access;

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

// Split traffic between HTTP and WebSocket
const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return definition.kind === "OperationDefinition" && definition.operation === "subscription";
	},
	wsLink,
	httpLink,
);

const link = from([errorLink, authLink, splitLink]);

const client = new ApolloClient({
	link: link,
	cache: new InMemoryCache(),
});

const App = () => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ApolloProvider client={client}>
					<Routing />
				</ApolloProvider>
			</PersistGate>
		</Provider>
	);
};

export default App;
