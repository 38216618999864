import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	max-width: 1400px;
	//position: relative;
	left: 0;
	right: 0;
	margin: 0 auto;

	& > div:first-child {
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 370px;
		background: linear-gradient(0deg, #f4e3ff 0%, #ffffff 100%);
		z-index: -1;
	}
`;

export const CelebrityHeading = styled.div`
	margin-top: 137px;
	width: 100%;
	display: flex;
	align-items: center;
	gap: calc(30px + 1.875vw);

	& > div:first-of-type {
		display: flex;
		align-items: center;
		gap: calc(30px + 1.875vw);
	}

	@media (max-width: 1200px) {
		gap: calc(10px + 1.875vw);

		& > div:first-of-type {
			gap: calc(10px + 1.875vw);
		}
	}

	@media (max-width: 996px) {
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		gap: 24px;
	}

	@media (max-width: 768px) {
		& > div {
			width: 100%;
		}
	}

	@media (min-width: 1600px) {
		gap: 60px;
	}
`;

export const ImageCol = styled.div`
	width: 100%;
	position: relative;
	max-width: 368px;

	img {
		width: 100%;

		//&:nth-child(2) {
		//	width: 55px;
		//	height: 55px;
		//	position: absolute;
		//	bottom: 0;
		//	right: 0;
		//	cursor: pointer;
		//}
	}
`;

export const SocialLinks = styled.div`
	width: 100%;
	position: absolute;
	bottom: 31px;
	display: flex;
	justify-content: center;
	gap: calc(11px + 0.688vw);

	@media (min-width: 1600px) {
		gap: 22px;
	}
`;

export const InfoCol = styled.div`
	width: 100%;
	max-width: 388px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	& > div:first-child {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		h2 {
			margin: 0;
			font-size: calc(16px + 1vw);
			font-weight: 600;
			line-height: calc(19px + 1.233vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
		}

		img {
			cursor: pointer;
		}
	}

	& > div:nth-child(2) {
		span {
			font-size: calc(12px + 0.75vw);
			font-weight: 400;
			line-height: calc(14.5px + 0.909vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
		}
	}

	@media (min-width: 1600px) {
		gap: 20px;

		& > div:first-child {
			h2 {
				font-size: 32px;
				line-height: 38.73px;
			}
		}

		& > div:nth-child(2) {
			span {
				font-size: 24px;
				line-height: 29.05px;
			}
		}
	}
`;
