import { memo, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CELEBRITY_QUERY } from "../../../../queries/celebrity";
import {
	CelebrityAbout,
	CelebrityRating,
	DetailsRequest,
	InfoCard,
	InfoGrid,
	OccasionSelect,
	PaymentMethods,
	Section,
	SectionNav,
	NavItem,
	UserName,
	VideoReasons,
	ActionButtons,
	UserNameButton,
	CartButton,
	BookButton,
	VideoFromInput,
	VideoForInput,
} from "./styles";
import starImg from "../../../../Assets/Images/Category/star-icon.svg";
import visaImg from "../../../../Assets/Images/UserCelebrity/visa-icon.svg";
import mastercardImg from "../../../../Assets/Images/UserCelebrity/mastercard-icon.svg";
import paypalImg from "../../../../Assets/Images/UserCelebrity/paypal-icon.svg";
import applepayImg from "../../../../Assets/Images/UserCelebrity/applepay-icon.svg";
import { Option, Options, Selected } from "../../../Molecules/CelebrityProfile/CelebrityProfileVideo/styles";
import arrowDownImg from "../../../../Assets/Images/Layout/Navbar/arrow-down-icon.svg";
import giftImg from "../../../../Assets/Images/UserCelebrity/gift-icon.svg";
import birthdayImg from "../../../../Assets/Images/UserCelebrity/birthday-icon.svg";
import otherImg from "../../../../Assets/Images/UserCelebrity/other-icon.svg";
// import { useSelector } from "react-redux";
import { setAuthenticated, setError, setLoading } from "../../../../reducers/auth";
import { setUserRole } from "../../../../reducers/user";
import { ADD_REQUEST_MUTATION } from "../../../../queries/request";
import { normalizePaymentType } from "../../../../utils/image";

const options = [
	{ value: "Holiday", label: "Holiday", image: giftImg },
	{ value: "Birthday", label: "Birthday", image: birthdayImg },
	{ value: "Other", label: "Other", image: otherImg },
];

const payments = {
	visa: visaImg,
	mastercard: mastercardImg,
	paypal: paypalImg,
	applepay: applepayImg,
};

const SubscribedCelebrityInfo = () => {
	const [current, setCurrent] = useState("About");
	const [isExpanded, setIsExpanded] = useState(true);
	const [charCount, setCharCount] = useState(0);
	const [formData, setFormData] = useState({
		videoFrom: {
			name: "",
			gender: "",
		},
		videoFor: {
			name: "",
			gender: "",
		},
		occasion: options[0],
		message: "",
	});
	const [isOpen, setIsOpen] = useState(false);
	const [validationErrors, setValidationErrors] = useState({});
	// const { isAuthenticated } = useSelector((state) => state.auth);
	const { celebrityId } = useParams();
	const navigate = useNavigate();

	const { data: celebrityData } = useQuery(GET_CELEBRITY_QUERY, {
		fetchPolicy: "network-only",
		variables: { celebrityId: celebrityId },
	});

	const [addRequest] = useMutation(ADD_REQUEST_MUTATION, {
		onCompleted: (data) => {
			// if (data.celebritySignIn.success) {
			// 	console.log("data.celebritySignIn", data.celebritySignIn);
			// 	dispatch(setAuthenticated(data.celebritySignIn.tokens));
			// 	dispatch(setUserRole({ type: "celebrity" }));
			// 	navigate("/");
			// }
			console.log("data", data);
			// navigate(`/booking/${bookingId}`);
		},
		onError: (error) => {
			// dispatch(setError(error.message));
			console.log("error", error);
		},
	});

	// for now
	const bookingId = 1;
	const isAuthenticated = true;
	// const customerId = "0ca6a61a-aeab-4454-972e-edbac6276fb0";

	const text = celebrityData?.getCelebrity.about;
	const charLimit = 166;

	const validate = () => {
		let newErrors = {};

		const { videoFrom, videoFor } = formData;

		const alphaExp = /^[a-zA-Z]+$/;

		if (videoFrom.name && !alphaExp.test(videoFrom.name)) {
			newErrors.videoFrom = "Name is not valid";
		}
		if (videoFor.name && !alphaExp.test(videoFor.name)) {
			newErrors.videoFor = "Name is not valid";
		}

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const toggleReadMore = () => {
		setIsExpanded(!isExpanded);
	};

	const handleNavClick = (e) => {
		setCurrent(e.target.name);
	};

	const handleVideoCorrGenderClick = (e, corr) => {
		setFormData((prevState) => ({
			...prevState,
			[corr]: {
				...formData[corr],
				gender: e.target.name,
			},
		}));
	};

	const handleVideoCorrNameClick = (e, corr) => {
		setFormData((prevState) => ({
			...prevState,
			[corr]: {
				...formData[corr],
				name: e.target.value,
			},
		}));
	};

	const handleRequestChange = (e) => {
		setCharCount(e.target.value.length);
		setFormData((prevState) => ({
			...prevState,
			message: e.target.value,
		}));
	};

	const handleOptionClick = (option) => {
		setFormData((prevState) => ({
			...prevState,
			occasion: option,
		}));
		setIsOpen(false);
	};

	const handleBook = async (e) => {
		if (validate()) {
			if (isAuthenticated) {
				try {
					navigate(`/booking/${bookingId}`);
					// await addRequest({
					// 	variables: {
					// 		requestInput: {
					// 			customerId: customerId,
					// 			celebrityId: celebrityId,
					// 			status: "pending",
					// 			details: {
					// 				videoFrom: {
					// 					name: formData.videoFrom.name,
					// 					gender: formData.videoFrom.gender,
					// 				},
					// 				videoFor: {
					// 					name: formData.videoFor.name,
					// 					gender: formData.videoFor.gender,
					// 				},
					// 				occasion: formData.occasion.value,
					// 				message: formData.message,
					// 			},
					// 		},
					// 	},
					// });
				} finally {
					// console.log("Request created!");
				}
			} else {
				navigate("/auth/sign-in");
			}
		}
	};

	console.log("text", text);

	return (
		<Section>
			<SectionNav>
				<NavItem name={"About"} onClick={handleNavClick} current={current === "About"}>
					About
				</NavItem>
				<NavItem name={"Video"} onClick={handleNavClick} current={current === "Video"}>
					Video
				</NavItem>
			</SectionNav>

			<InfoGrid>
				<InfoCard>
					<CelebrityRating>
						<div>
							<h4>Price</h4>
							<span>AMD {Number(celebrityData?.getCelebrity.price).toLocaleString()}+</span>
						</div>
						<div>
							<h4>Reviews</h4>
							<span>
								<img loading="lazy" src={starImg} alt="Rating Stars" />
								&nbsp;{celebrityData?.getCelebrity.rating}
							</span>
						</div>
						<div>
							<h4>Available For</h4>
							<span>24hr delivery</span>
						</div>
					</CelebrityRating>
					<CelebrityAbout>
						<h3>About</h3>
						<div>
							<p>
								{isExpanded ? text : `${text?.slice(0, charLimit)}...`}
								{text?.length > charLimit && (
									<span onClick={toggleReadMore}>{isExpanded ? "Read Less" : "Read More"}</span>
								)}
							</p>
						</div>
					</CelebrityAbout>
				</InfoCard>

				<InfoCard>
					<VideoReasons>
						<h3>Reasons to get a video</h3>
						<div>
							<span>Question</span>
							<span>Say Happy Birthday</span>
							<span>Get advice</span>
							<span>Promote a business</span>
							<span>Holiday</span>
							<span>Other</span>
						</div>
					</VideoReasons>
					<PaymentMethods>
						<h3>Payment Methods</h3>
						<div>
							{celebrityData?.getCelebrity.paymentMethods.map((payment, index) => {
								const paymentType = normalizePaymentType(payment.paymentType); // Normalize payment type
								const imageSrc = paymentType ? payments[paymentType] : null;
								if (!imageSrc) {
									console.warn(`No image found for payment type: ${paymentType}`);
									return null; // Skip rendering if no image is found
								}

								return (
									<Link to={""} key={index}>
										<img loading="lazy" src={imageSrc} alt={paymentType} />
									</Link>
								);
							})}
						</div>
					</PaymentMethods>
				</InfoCard>

				<InfoCard>
					<UserName>
						<h3>Who is the video from?</h3>
						<div>
							<VideoFromInput
								type="text"
								placeholder="Name"
								value={formData.videoFrom.name}
								onChange={(e) => handleVideoCorrNameClick(e, "videoFrom")}
								error={validationErrors?.videoFrom}
							/>
							{validationErrors?.videoFrom && <p>{validationErrors.videoFrom}</p>}
						</div>
						<div>
							<UserNameButton
								name={"He/Him"}
								current={formData.videoFrom.gender === "He/Him" ? 1 : 0}
								onClick={(e) => handleVideoCorrGenderClick(e, "videoFrom")}
							>
								He/Him
							</UserNameButton>
							<UserNameButton
								name={"She/Her"}
								current={formData.videoFrom.gender === "She/Her" ? 1 : 0}
								onClick={(e) => handleVideoCorrGenderClick(e, "videoFrom")}
							>
								She/Her
							</UserNameButton>
							<UserNameButton
								name={"They/Them"}
								current={formData.videoFrom.gender === "They/Them" ? 1 : 0}
								onClick={(e) => handleVideoCorrGenderClick(e, "videoFrom")}
							>
								They/Them
							</UserNameButton>
						</div>
					</UserName>
					<UserName>
						<h3>Who is the video for?</h3>
						<div>
							<VideoForInput
								type="text"
								placeholder="Name"
								value={formData.videoFor.name}
								onChange={(e) => handleVideoCorrNameClick(e, "videoFor")}
								error={validationErrors?.videoFor}
							/>
							{validationErrors?.videoFor && <p>{validationErrors.videoFor}</p>}
						</div>

						<div>
							<UserNameButton
								name={"He/Him"}
								current={formData.videoFor.gender === "He/Him" ? 1 : 0}
								onClick={(e) => handleVideoCorrGenderClick(e, "videoFor")}
							>
								He/Him
							</UserNameButton>
							<UserNameButton
								name={"She/Her"}
								current={formData.videoFor.gender === "She/Her" ? 1 : 0}
								onClick={(e) => handleVideoCorrGenderClick(e, "videoFor")}
							>
								She/Her
							</UserNameButton>
							<UserNameButton
								name={"They/Them"}
								current={formData.videoFor.gender === "They/Them" ? 1 : 0}
								onClick={(e) => handleVideoCorrGenderClick(e, "videoFor")}
							>
								They/Them
							</UserNameButton>
						</div>
					</UserName>
				</InfoCard>

				<InfoCard>
					<OccasionSelect>
						<h3>Select an occasion</h3>
						<Selected isOpen={isOpen} onClick={() => setIsOpen((prev) => !prev)}>
							<img loading="lazy" src={formData.occasion.image} alt={formData.occasion.label} />
							<span>{formData.occasion.label}</span>
							<img loading="lazy" src={arrowDownImg} alt="" />
						</Selected>
						{isOpen && (
							<Options>
								{options.map((option, index) => (
									<Option
										key={index}
										onClick={() => handleOptionClick(option)}
										current={formData.occasion === option}
									>
										<img loading="lazy" src={option.image} alt={option.label} />
										<span>{option.label}</span>
									</Option>
								))}
							</Options>
						)}
					</OccasionSelect>
					<DetailsRequest>
						<h3>Request Details</h3>
						<div>
							<textarea
								name=""
								id=""
								cols="30"
								rows="10"
								maxLength={250}
								placeholder={"Wish my mom a Merry Christmas! It’s her favorite holiday "}
								onChange={handleRequestChange}
							/>
							<span>{charCount}/250 characters</span>
						</div>
					</DetailsRequest>
				</InfoCard>
			</InfoGrid>

			<ActionButtons>
				<CartButton>Add to Cart</CartButton>
				<BookButton onClick={handleBook}>Book Now</BookButton>
			</ActionButtons>
		</Section>
	);
};

export default memo(SubscribedCelebrityInfo);
