import BookingOrder from "../../Components/Organisms/Booking/BookingOrder";

const BookingPage = () => {
	return (
		<div>
			<BookingOrder />
		</div>
	);
};

export default BookingPage;
