import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useMobileDetector, useTabletDetector } from "../../utils/customHooks";
import { OutletContainer } from "./styles";
import Sidebar from "./Sidebar";
import DefaultNavbar from "./Navbar";
import DefaultFooter from "./Footer";

const DefaultLayout = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const isMobile = useMobileDetector();
	const isTablet = useTabletDetector();
	const containerRef = useRef(null);
	const location = useLocation();

	useEffect(() => {
		if (location.hash !== "#HowItWorks") {
			setTimeout(() => {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			}, 100);
		}
	}, [location.hash, location.pathname]);

	useEffect(() => {
		const touchStartListener = () => {};
		document.addEventListener("touchstart", touchStartListener, { passive: true });
		return () => {
			document.removeEventListener("touchstart", touchStartListener);
		};
	}, []);

	const enableScrolling = () => {
		containerRef.current.style.setProperty("overflow-y", "auto", "important");
		containerRef.current.style.setProperty("position", "unset", "important");
		containerRef.current.style.setProperty("height", "auto", "important");
		document.documentElement.style.overflow = "hidden auto";
		document.body.style.overflowY = "auto";
	};

	useEffect(() => {
		if (containerRef.current) {
			// isLoading || isSidebarOpen
			if (isSidebarOpen) {
				containerRef.current.style.setProperty("overflow-y", "hidden", "important");
				containerRef.current.style.setProperty("position", "relative", "important");
				containerRef.current.style.setProperty("height", "100%", "important");
				document.body.style.overflowY = "hidden";
				document.documentElement.style.overflowY = "hidden";
			} else {
				enableScrolling();
			}
		}
	}, [isLoading, isSidebarOpen]);

	return (
		<OutletContainer ref={containerRef}>
			<DefaultNavbar handleOpenMenu={() => setIsSidebarOpen(true)} />
			<Outlet context={[isLoading, setIsLoading]} />
			<DefaultFooter />
			{(isMobile || isTablet) && isSidebarOpen && <Sidebar handleMenuClose={() => setIsSidebarOpen(false)} />}
		</OutletContainer>
	);
};

export default DefaultLayout;
