import styled from "styled-components";
import questionsChevron from "../../../../Assets/Images/Home/questions-chevron.svg";

export const Section = styled.section`
	width: 100%;
	position: relative;
	left: 0;
	right: 0;
	max-width: 1400px;
	margin: 140px auto 0 auto;
	display: flex;
	justify-content: space-between;
	gap: calc(10px + 1.25vw);

	@media (max-width: 996px) {
		flex-direction: column;
	}

	@media (max-width: 768px) {
		margin-top: 60px;
	}

	@media (min-width: 1600px) {
		gap: 30px;
	}
`;

export const SectionHeading = styled.h1`
	position: relative;
	top: 0;
	display: flex;
	flex-direction: column;
	margin-top: 153px;

	@media (max-width: 996px) {
		margin-top: 0;
		align-items: center;
	}
`;

export const TextRow = styled.span`
	font-size: calc(24px + 3vw);
	font-weight: 500;
	line-height: calc(32px + 3.446vw);
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	text-transform: uppercase;

	@media (min-width: 1600px) {
		font-size: 72px;
		line-height: 87.14px;
	}
`;

export const QuestionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: calc(10px + 1.875vw);
	width: 100%;
	max-width: 853px;

	@media (max-width: 1200px) {
		align-items: center;
	}

	@media (min-width: 1600px) {
		gap: 40px;
	}
`;

export const QuestionContainer = styled.details`
	width: 100%;
	padding: calc(18px + 1.125vw) calc(32px + 2vw);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	border-radius: 80px;
	background: #fafafa;
	cursor: pointer;
	transition: background-color 0.3s ease;
	box-sizing: border-box;
	position: relative;

	& > p {
		margin: 12px 0 0 0;
		font-size: 18px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.13px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #6d6d6d;
	}

	&[open] {
		border-radius: 60px;

		& > summary:after {
			transform: rotate(180deg);
		}
	}

	@media (max-width: 1400px) {
		width: 100%;
	}

	@media (min-width: 1600px) {
		padding: 36px 64px;
		font-size: 24px;
		line-height: 29.05px;
	}
`;

export const QuestionTitle = styled.summary`
	font-size: calc(12px + 0.75vw);
	font-weight: 400;
	line-height: calc(12px + 0.75vw);
	letter-spacing: -0.13px;
	text-align: left;
	color: #151515;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	list-style-type: none;
	cursor: pointer;

	&::-webkit-details-marker {
		display: none;
	}

	&::after {
		content: url("${questionsChevron}");
		position: absolute;
		transition: transform 0.2s ease;
		width: 40px;
		height: 40px;
		right: 33px;
	}

	@media (max-width: 480px) {
		&::after {
			width: 32px;
			height: 32px;
			right: 24px;
		}
	}

	@media (min-width: 1600px) {
		font-size: 24px;
		line-height: 24px;
	}
`;
