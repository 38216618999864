import HeroSection from "../../Components/Organisms/Category/HeroSection";
import CategoryCelebrities from "../../Components/Organisms/Category/CategoryCelebrities";

const CategoryPage = () => {
	return (
		<div>
			<HeroSection />
			<CategoryCelebrities />
		</div>
	);
};

export default CategoryPage;
