import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

// Design tokens
const COLORS = {
	primary: "#121212",
	white: "#ffffff",
	pink: "#d3b2e8",
	gray: "#eae9eb",
	blue: "#8aaeff",
};

export const CelebrityColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(10px + 0.625vw);
	width: 100%;

	.swiper {
		overflow: visible;
		height: 745px !important;

		.swiper-wrapper {
			transition-timing-function: linear !important;
			//will-change: transform;
		}

		// .swiper-slide {
		// 	transition: transform 0.3s ease;
		// 	will-change: transform;
		// 	//height: ${(props) => props.slideHeight || "auto"} !important;
		// }
	}

	@media (max-width: 996px) {
		.swiper {
			//height: 645px !important;
			height: 523px !important;
		}
	}

	@media (min-width: 1600px) {
		gap: 20px;
	}
`;

export const CustomSwiperSlide = styled(SwiperSlide)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${(props) => props.height || "auto"} !important; // Fixed height from props
`;

export const CelebrityInfo = styled.div`
	width: 100%;
	//max-width: 242px;
	border-radius: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	//height: 168px;
	height: auto;
	//height: 100%; // Changed from fixed height to 100%
	background: ${({ variant }) =>
		variant === "pink"
			? COLORS.pink
			: variant === "gray"
				? COLORS.gray
				: variant === "blue"
					? COLORS.blue
					: "transparent"};
	padding: 30px;
	box-sizing: border-box;

	div {
		display: flex;
		flex-direction: column;
		text-align: left;
		gap: calc(2px + 0.188vw);
	}

	div > span:first-child {
		font-size: calc(8px + 0.5vw);
		font-weight: 500;
		line-height: calc(12px + 0.75vw);
		letter-spacing: -0.011em;
		text-align: left;
	}

	div > span:nth-child(2) {
		font-size: calc(24px + 1.5vw);
		font-weight: 500;
		line-height: calc(32px + 1.631vw);
		letter-spacing: -0.011em;
		text-align: left;
	}

	div > span:nth-child(3) {
		font-size: calc(8px + 0.5vw);
		font-weight: 500;
		line-height: calc(12px + 0.75vw);
		letter-spacing: -0.011em;
		text-align: left;
	}

	div > button {
		padding: calc(7px + 0.438vw) calc(20.5px + 1.281vw);
		gap: calc(5px + 0.313vw);
		border-radius: 48px;
		background-color: ${COLORS.primary};
		color: ${COLORS.white};
		font-size: calc(8px + 0.5vw);
		font-weight: 600;
		line-height: calc(12px + 0.46vw);
		letter-spacing: -0.011em;
		text-align: left;
		margin-top: 12px;
	}

	@media (max-width: 768px) {
		height: 118px;
		padding: 20px 30px;

		div > span:first-child {
			font-size: 12px;
			line-height: 26px;
			letter-spacing: -1.1px;
		}

		div > button {
			font-size: 12px;
			letter-spacing: -1.1px;
		}
	}

	@media (min-width: 1600px) {
		div {
			gap: 5px;
		}

		div > span:first-child {
			font-size: 16px;
			line-height: 24px;
		}

		div > span:nth-child(2) {
			font-size: 48px;
			line-height: 58.09px;
		}

		div > span:nth-child(3) {
			font-size: 16px;
			line-height: 20px;
		}

		div > button {
			padding: 14px 41px 14px 41px;
			gap: 10px;

			font-size: 16px;
			line-height: 19.36px;
		}
	}
`;

export const CelebrityImage = styled.div`
	width: 100%;
	height: 367px;
	//height: auto;
	//height: 100%; // Changed from fixed height to 100%

	img {
		width: 100%;
		height: auto;
		//height: 100%;
		object-fit: cover; // Added to maintain aspect ratio
	}

	@media (max-width: 768px) {
		height: 258px;
	}
`;
