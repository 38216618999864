import { Link } from "react-router-dom";
import { Section, SectionColumn } from "./styles";

import celebrityGift from "../../../../Assets/Images/Home/celebrityGift.webp";

const CelebrityGift = () => {
	return (
		<Section>
			<SectionColumn>
				<h1>Over 150 Celebrities, One Perfect Gift!</h1>
				<p>
					Surprise your loved ones with a personalized message from over 150 celebrities. Perfect for any
					occasion!
				</p>
				<div>
					<Link to={"/auth/sign-up"}>Get started</Link>
				</div>
			</SectionColumn>

			<SectionColumn>
				<img loading="lazy" src={celebrityGift} alt="" />
			</SectionColumn>
		</Section>
	);
};

export default CelebrityGift;
