import {
	Section,
	CelebrityInfoRow,
	CelebrityCategoryRow,
	CelebrityInfo,
	SectionHeading,
	TextRow,
	CustomSwiperSlide,
	CategoryImage,
	ViewAllButton,
} from "./styles";

import viewAllButton from "../../../../Assets/Images/Home/arrow right.svg";
import celebrityCategory1 from "../../../../Assets/Images/Home/celebrityCategory1.webp";
import celebrityCategory2 from "../../../../Assets/Images/Home/celebrityCategory2.webp";
import celebrityCategory3 from "../../../../Assets/Images/Home/celebrityCategory3.webp";
import celebrityCategory4 from "../../../../Assets/Images/Home/celebrityCategory4.webp";
import celebrityCategory5 from "../../../../Assets/Images/Home/celebrityCategory5.webp";

import { FreeMode, Autoplay } from "swiper/modules";
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { useMobileDetector, useTabletDetector } from "../../../../utils/customHooks";
import { useNavigate } from "react-router-dom";

const CategoriesSwiper = () => {
	const isMobile = useMobileDetector();
	const isTablet = useTabletDetector();

	const navigate = useNavigate();

	return (
		<Section>
			<CelebrityInfoRow>
				<CelebrityInfo>
					<p>Find the perfect star for your occasion—whether it’s an actor, musician, or influencer.</p>
					{!isMobile && !isTablet && (
						<ViewAllButton onClick={() => navigate("/category/all-celebrities")}>
							View all <img loading="lazy" src={viewAllButton} alt="" />
						</ViewAllButton>
					)}
				</CelebrityInfo>

				<SectionHeading>
					<TextRow>Explore Celebrities</TextRow>
					<TextRow>by Category</TextRow>
				</SectionHeading>
			</CelebrityInfoRow>

			<CelebrityCategoryRow>
				<Swiper
					loop={true}
					autoplay={{
						delay: 5000,
						disableOnInteraction: false,
						pauseOnMouseEnter: true,
					}}
					modules={[FreeMode, Autoplay]}
					spaceBetween={20}
					slidesPerView={"auto"}
					breakpoints={{
						0: {
							slidesPerView: 3,
						},
						640: {
							slidesPerView: 4,
						},
						1200: {
							slidesPerView: 5,
						},
						1600: {
							slidesPerView: "auto",
						},
					}}
				>
					<CustomSwiperSlide>
						<div>
							<CategoryImage src={celebrityCategory1} alt="" />
							<span></span>
						</div>
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<div>
							<CategoryImage src={celebrityCategory2} alt="" />
							<span>Reality TV stars</span>
						</div>
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<div>
							<CategoryImage src={celebrityCategory3} alt="" />
							<span>Influencers</span>
						</div>
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<div>
							<CategoryImage src={celebrityCategory4} alt="" />
							<span>Comedians</span>
						</div>
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<div>
							<CategoryImage src={celebrityCategory5} alt="" />
							<span>Athletes</span>
						</div>
					</CustomSwiperSlide>
				</Swiper>
			</CelebrityCategoryRow>

			{(isMobile || isTablet) && (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<ViewAllButton onClick={() => navigate("/category/all-celebrities")}>
						View all <img loading="lazy" src={viewAllButton} alt="" />
					</ViewAllButton>
				</div>
			)}
		</Section>
	);
};

export default CategoriesSwiper;
