import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	max-width: 1400px;
	position: relative;
	left: 0;
	right: 0;
	margin: 0 auto;
	display: flex;
	justify-content: center;

	& > div {
		margin-top: 30px;
		width: 100%;
		max-width: 606px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 30px;
	}

	@media (min-width: 1600px) {
		& > div {
			gap: calc(15px + 0.938vw);
		}
	}
`;

export const SectionHeading = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

export const CustomerImg = styled.div`
	width: 100%;
	max-width: 246px;
	position: relative;

	img:first-child {
		width: 100%;
	}

	img:nth-child(2) {
		position: absolute;
		bottom: 0;
		right: 0;
		cursor: pointer;
	}

	@media (max-width: 480px) {
		max-width: 179px;
	}
`;

export const ProfileForm = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(15px + 0.938vw);

	& > div {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: calc(8px + 0.5vw);

		label {
			font-size: calc(12px + 0.75vw);
			font-weight: 500;
			line-height: calc(14.5px + 0.909vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
		}

		input {
			width: 100%;
			padding: calc(5px + 0.313vw) calc(8px + 0.5vw);
			border-radius: 80px;
			border: 1px solid #a79dad;
			font-size: calc(8px + 0.5vw);
			font-weight: 500;
			line-height: calc(9.5px + 0.616vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
			box-sizing: border-box;

			&::placeholder {
				color: #a4a7ae;
			}
		}

		p {
			margin-bottom: 0;
			color: #d00306;
			font-size: 14px;
			font-weight: 400;
			line-height: 16.94px;
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
		}
	}

	@media (max-width: 480px) {
		& > div {
			label {
				font-size: 16px;
				line-height: 19.36px;
			}

			input {
				padding: 16px;
				font-size: 14px;
				line-height: 16.94px;
			}
		}
	}

	@media (min-width: 1600px) {
		gap: 30px;

		& > div {
			gap: 16px;

			label {
				font-size: 24px;
				line-height: 29.05px;
			}

			input {
				padding: 10px 16px;
				font-size: 16px;
				line-height: 19.36px;
			}

			p {
				font-size: 14px;
				line-height: 16.94px;
			}
		}
	}
`;

export const FirstNameInputContainer = styled.div`
	input {
		border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
	}
`;

export const LastNameInputContainer = styled.div`
	input {
		border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
	}
`;

export const BioInputContainer = styled.div`
	input {
		border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
	}
`;

export const EmailInputContainer = styled.div`
	input {
		border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
	}
`;

export const FormButton = styled.div`
	width: 100%;
	align-items: flex-end;

	button {
		cursor: pointer;
		padding: calc(5.25px + 0.328vw) calc(19.25px + 1.203vw);
		border-radius: 80px;
		border: none;
		background-color: #9e77ed;
		font-size: calc(9px + 0.563vw);
		font-weight: 600;
		line-height: calc(13.5px + 0.844vw);
		letter-spacing: -0.011em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #ffffff;
		box-sizing: border-box;
		margin-top: 15px;
	}

	@media (max-width: 480px) {
		button {
			padding: 12px 40px;
			font-size: 14px;
			line-height: 16.94px;
			letter-spacing: -1.1px;
		}
	}

	@media (min-width: 1600px) {
		button {
			padding: 10.5px 38.5px;
			font-size: 18px;
			line-height: 27px;
		}
	}
`;
