import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_CELEBRITY_QUERY } from "../../../../queries/celebrity";
import { Section, CelebrityHeading, ImageCol, SocialLinks, InfoCol } from "./styles";
import celebrityImg1 from "../../../../Assets/Images/UserCelebrity/celebrity1.webp";
import tiktokImg from "../../../../Assets/Images/UserCelebrity/tiktok-icon.svg";
import instagramImg from "../../../../Assets/Images/UserCelebrity/instagram-icon.svg";
import facebookImg from "../../../../Assets/Images/UserCelebrity/facebook-icon.svg";
import youtubeImg from "../../../../Assets/Images/UserCelebrity/youtube-icon.svg";
import { getPlatformFromUrl } from "../../../../utils/image";

const socialLinks = {
	tiktok: tiktokImg,
	instagram: instagramImg,
	facebook: facebookImg,
	youtube: youtubeImg,
};

const HeroSection = () => {
	const { celebrityId } = useParams();

	const { data: celebrityData } = useQuery(GET_CELEBRITY_QUERY, {
		fetchPolicy: "network-only",
		variables: { celebrityId: celebrityId },
	});

	return (
		<Section>
			<div />
			<CelebrityHeading>
				<ImageCol>
					<img loading="lazy" src={celebrityImg1} alt="" />
					<SocialLinks>
						{celebrityData?.getCelebrity.socialLinks.map((socialLink, index) => {
							const platform = getPlatformFromUrl(socialLink); // e.g., "instagram"
							const imageSrc = platform ? socialLinks[platform] : null;
							if (!imageSrc) {
								console.warn(`No image found for platform: ${platform}`);
								return null; // Skip rendering if no image is found
							}
							return (
								<Link to={socialLink} key={index}>
									<img loading="lazy" src={imageSrc} alt={platform} />
								</Link>
							);
						})}
					</SocialLinks>
				</ImageCol>
				<InfoCol>
					<div>
						<h2>{celebrityData?.getCelebrity.firstName}</h2>
					</div>
					<div>
						<span>{celebrityData?.getCelebrity.bio}</span>
					</div>
				</InfoCol>
			</CelebrityHeading>
		</Section>
	);
};

export default HeroSection;
