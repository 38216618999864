import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { UPLOAD_CELEBRITY_VIDEO_MUTATION } from "../../../../queries/celebrity";
import {
	FileContainer,
	FileName,
	ModalActionButtons,
	ModalContent,
	ModalHeading,
	ModalOverlay,
	OccasionContainer,
	Option,
	Options,
	PlayButtonContainer,
	Selected,
	VideoCard,
	VideoContainer,
	VideoGrid,
	VideoHeading,
	VideoUploadCard,
} from "./styles";
import uploadImg from "../../../../Assets/Images/CelebrityProfile/upload-icon.svg";
import documentImg from "../../../../Assets/Images/CelebrityProfile/document-icon.svg";
import giftImg from "../../../../Assets/Images/UserCelebrity/gift-icon.svg";
import birthdayImg from "../../../../Assets/Images/UserCelebrity/birthday-icon.svg";
import otherImg from "../../../../Assets/Images/UserCelebrity/other-icon.svg";
import arrowDownImg from "../../../../Assets/Images/Layout/Navbar/arrow-down-icon.svg";
import playImg from "../../../../Assets/Images/CelebrityProfile/play-icon.svg";
import { useCelebrity } from "../../../../Layouts/CelebrityProfile";

const options = [
	{ value: "Holiday", label: "Holiday", image: giftImg },
	{ value: "Birthday", label: "Birthday", image: birthdayImg },
	{ value: "Other", label: "Other", image: otherImg },
];

const CelebrityProfileVideo = () => {
	const [showModal, setShowModal] = useState(false);
	const [file, setFile] = useState(null);
	const [progress, setProgress] = useState(0);
	// const [occasion, setOccasion] = useState("Holiday");
	const [selectedOption, setSelectedOption] = useState(options[0]);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const { celebrityId } = useParams();
	const celebrityData = useCelebrity();

	const [uploadCelebrityVideo] = useMutation(UPLOAD_CELEBRITY_VIDEO_MUTATION);

	const handleUpload = (e) => {
		const selectedFile = e.target.files[0];
		if (!selectedFile) return;

		setFile(selectedFile);
		setProgress(0); // Start progress at 0

		setShowModal(true);

		// Simulate upload progress
		let progress = 0;
		const interval = setInterval(() => {
			progress += 10; // Increment progress by 10%
			setProgress(progress);

			if (progress >= 100) {
				clearInterval(interval); // Stop incrementing when it reaches 100%
				// alert("Upload complete!");
			}
		}, 200); // Update every 200ms
	};

	const handleAddToProfile = async () => {
		if (!file) return;

		try {
			console.log("file", file);
			const result = await uploadCelebrityVideo({
				variables: {
					celebrityId: celebrityId,
					video: file,
					occasion: selectedOption.value,
				},
			});

			console.log("Upload successful:", result.data.uploadCelebrityVideo);
			alert(`File "${file.name}" uploaded successfully!`);
			setShowModal(false);
			setFile(null);
			setProgress(0);
		} catch (error) {
			console.error("Upload failed:", error);
			alert("Upload failed!");
		}
	};

	const handleModalClick = () => {
		setShowModal(true);
	};

	const handleModelCancel = () => {
		setShowModal(false);
		setFile(null);
	};

	const handleOptionClick = (option) => {
		setSelectedOption(option);
		setIsOpen(false);
	};

	useEffect(() => {
		if (showModal) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [showModal]);

	return (
		<VideoContainer>
			<VideoHeading>
				<h2>Video Portfolio</h2>
				<span>Upload it, and share it with your audience.</span>
			</VideoHeading>
			<VideoGrid>
				<VideoUploadCard>
					<img loading="lazy" src={uploadImg} alt="Upload" />
					<h3>Upload your video</h3>
					<p>Add a video to show clients what you offer and make booking you easy.</p>
					<label htmlFor="file-upload" onClick={file && handleModalClick}>
						Upload
					</label>
					{!file && (
						<input
							type="file"
							accept="video/*"
							name="file-upload"
							id="file-upload"
							onChange={handleUpload}
						/>
					)}
				</VideoUploadCard>
				{celebrityData?.getCelebrity?.portfolioVideos &&
					celebrityData?.getCelebrity?.portfolioVideos.map((video, index) => (
						<VideoCard key={index} onClick={() => setSelectedVideo(video)}>
							{/*<img loading="lazy" src="" alt="" />*/}
							<div />
							<span>{video.occasion}</span>
							<PlayButtonContainer>
								<img loading="lazy" src={playImg} alt="Play" />
							</PlayButtonContainer>
						</VideoCard>
					))}

				{selectedVideo && (
					<ModalOverlay>
						<ModalContent>
							<ModalHeading>
								<h4>{selectedVideo.occasion}</h4>
								<span onClick={() => setSelectedVideo(null)}>&times;</span>
							</ModalHeading>

							<VideoContainer>
								<video controls>
									<source src={`https://server.fameeo.com${selectedVideo.url}`} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</VideoContainer>
						</ModalContent>
					</ModalOverlay>
				)}
			</VideoGrid>

			{showModal && (
				<ModalOverlay>
					<ModalContent>
						<ModalHeading>
							<h4>Import Video</h4>
							<span onClick={handleModelCancel}>&times;</span>
						</ModalHeading>

						{file && (
							<>
								<FileContainer>
									<img loading="lazy" src={documentImg} alt="Document" />
									<div>
										<FileName>
											<span>
												{file.name} <span>196KB</span>
											</span>
											<span>{progress}%</span>
										</FileName>
										<progress value={progress} max="100">
											{progress}
										</progress>
									</div>
								</FileContainer>
								<OccasionContainer>
									<Selected
										isOpen={isOpen}
										onClick={() => setIsOpen((prev) => !prev)}
										style={{ border: "1px solid #A79DAD" }}
									>
										<img loading="lazy" src={selectedOption.image} alt={selectedOption.label} />
										<span>{selectedOption.label}</span>
										<img loading="lazy" src={arrowDownImg} alt="" />
									</Selected>
									{isOpen && (
										<Options>
											{options.map((option) => (
												<Option key={option.value} onClick={() => handleOptionClick(option)}>
													<img loading="lazy" src={option.image} alt={option.label} />
													<span>{option.label}</span>
												</Option>
											))}
										</Options>
									)}
								</OccasionContainer>
							</>
						)}
						<ModalActionButtons>
							<button className="cancel" onClick={handleModelCancel}>
								Cancel
							</button>
							<button className="add" onClick={handleAddToProfile} disabled={!file}>
								Add To Profile
							</button>
						</ModalActionButtons>
					</ModalContent>
				</ModalOverlay>
			)}
		</VideoContainer>
	);
};

export default memo(CelebrityProfileVideo);
