import { gql } from "@apollo/client";

export const NOTIFICATION_ADDED_SUBSCRIPTION = gql`
	subscription NotificationAdded($userId: String!) {
		notificationAdded(userId: $userId) {
			id
			type
			message
			isRead
			createdAt
			img
		}
	}
`;

export const SEND_NOTIFICATION_MUTATION = gql`
	mutation SendNotification($userType: String!, $userId: String!, $notification: NotificationInput!) {
		addNotification(userType: $userType, userId: $userId, notification: $notification)
	}
`;

export const SET_NOTIFICATION_IS_READ_MUTATION = gql`
	mutation SetNotificationIsRead($userType: String!, $userId: String!) {
		setNotificationIsRead(userType: $userType, userId: $userId)
	}
`;
