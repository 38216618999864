import styled from "styled-components";

export const CelebrityRequests = styled.div`
	width: 100%;
	max-width: 1400px;
	position: relative;
	left: 0;
	right: 0;
	margin: 0 auto;
	display: flex;
	justify-content: center;

	h2 {
		margin: 0;
		font-size: calc(16px + 1vw);
		font-weight: 600;
		line-height: calc(19px + 1.233vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 480px) {
		h2 {
			font-size: 22px;
			line-height: 26.63px;
		}
	}

	@media (min-width: 1600px) {
		h2 {
			font-size: 32px;
			line-height: 38.73px;
		}
	}
`;

export const RequestsContainer = styled.div`
	margin-top: 60px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: calc(15px + 0.938vw);

	@media (max-width: 480px) {
		gap: 24px;
	}

	@media (min-width: 1600px) {
		gap: 30px;
	}
`;

export const RequestsHeading = styled.div`
	width: 100%;
	display: flex;
`;

export const RequestsGrid = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: calc(12px + 0.75vw);

	@media (min-width: 1600px) {
		gap: 24px;
	}
`;

export const RequestCard = styled.div`
	padding: calc(22px + 1.375vw) calc(16px + 1vw) calc(19px + 1.188vw) calc(19.5px + 1.219vw);
	width: 100%;
	max-width: 899px;
	box-sizing: border-box;
	box-shadow: 0 -6px 18px 0 #f6f5f780;
	display: flex;
	flex-direction: column;
	gap: calc(6px + 0.375vw);
	border-radius: 20px;

	& > div {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	@media (max-width: 480px) {
		padding: 20px 16px;
		gap: 18px;
	}

	@media (min-width: 1600px) {
		padding: 44px 32px 38px 39px;
		gap: 12px;
	}
`;

export const CardHeading = styled.h3`
	width: 100%;
	font-size: calc(12px + 0.75vw);
	font-weight: 500;
	line-height: calc(14.5px + 0.909vw);
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #000000;
	margin: 0;

	@media (max-width: 480px) {
		font-size: 16px;
		line-height: 19.36px;
	}

	@media (min-width: 1600px) {
		font-size: 24px;
		line-height: 29.05px;
	}
`;

export const CardCol1 = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(6px + 0.375vw);

	h4 {
		width: 100%;
		margin: 0;
		font-size: calc(9px + 0.563vw);
		font-weight: 400;
		line-height: calc(10.5px + 0.705vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #717680;

		span {
			font-weight: 500;
			color: #000000;
		}

		p {
			width: 100%;
			max-width: 434px;
			padding: calc(6px + 0.375vw) calc(8px + 0.5vw);
			box-sizing: border-box;
			background-color: #fafafa;
			border-radius: 10px;
			font-size: calc(6px + 0.375vw);
			font-weight: 400;
			line-height: calc(12px + 0.782vw);
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
			color: #000000;
		}
	}

	@media (max-width: 480px) {
		gap: 18px;

		h4 {
			font-size: 12px;
			line-height: 14.52px;

			p {
				padding: 12px 16px;
				font-size: 10px;
				line-height: 12.1px;
			}
		}
	}

	@media (min-width: 1600px) {
		gap: 12px;

		h4 {
			font-size: 18px;
			line-height: 21.78px;

			span {
				font-size: 18px;
				line-height: 21.78px;
			}

			p {
				padding: 12px 16px;
				font-size: 12px;
				line-height: 14.52px;
			}
		}
	}
`;

export const RequestMessage = styled.h4`
	width: 100%;
	margin: 0;
	font-size: calc(9px + 0.563vw);
	font-weight: 400;
	line-height: calc(10.5px + 0.705vw);
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #717680;

	p {
		width: 100%;
		max-width: 434px;
		padding: calc(6px + 0.375vw) calc(8px + 0.5vw);
		box-sizing: border-box;
		background-color: #fafafa;
		border-radius: 10px;
		font-size: calc(6px + 0.375vw);
		font-weight: 400;
		line-height: calc(12px + 0.782vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 480px) {
		font-size: 12px;
		line-height: 14.52px;

		p {
			padding: 12px 16px;
			font-size: 10px;
			line-height: 12.1px;
		}
	}

	@media (min-width: 1600px) {
		font-size: 18px;
		line-height: 21.78px;

		p {
			padding: 12px 16px;
			font-size: 12px;
			line-height: 14.52px;
		}
	}
`;

export const CardCol2 = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	div {
		display: flex;
		flex-direction: column;
		gap: calc(5px + 0.313vw);
		align-items: flex-start;

		div {
			display: flex;
			flex-direction: column;
			gap: calc(2px + 0.125vw);

			h4 {
				margin: 0;
				font-size: calc(9px + 0.563vw);
				font-weight: 500;
				line-height: calc(10.5px + 0.705vw);
				text-align: left;
				text-underline-position: from-font;
				text-decoration-skip-ink: none;
				color: #000000;
			}

			span {
				font-size: calc(6px + 0.375vw);
				font-weight: 400;
				line-height: calc(12px + 0.782vw);
				text-align: left;
				text-underline-position: from-font;
				text-decoration-skip-ink: none;
				color: #717680;
			}
		}
	}

	@media (max-width: 480px) {
		div {
			gap: 8px;

			div {
				gap: 4px;

				h4 {
					font-size: 12px;
					line-height: 14.52px;
				}

				span {
					font-size: 8px;
					line-height: 9.68px;
				}
			}
		}
	}

	@media (min-width: 1600px) {
		div {
			gap: 10px;

			div {
				gap: 4px;

				h4 {
					font-size: 18px;
					line-height: 21.78px;
				}

				span {
					font-size: 12px;
					line-height: 14.52px;
				}
			}
		}
	}
`;

export const CardButtonRow = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end !important;
	gap: calc(5px + 0.313vw);

	button {
		cursor: pointer;
		font-size: calc(9px + 0.563vw);
		font-weight: 500;
		line-height: calc(10.5px + 0.705vw);
		letter-spacing: -0.011em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		border-radius: 80px;
		box-sizing: border-box;

		&:first-child {
			padding: calc(6.5px + 0.406vw) calc(34.25px + 2.141vw);
			border: 1px solid #d6cfdb;
			background: transparent;
			color: #000000;
		}

		&:nth-child(2) {
			padding: calc(6.5px + 0.406vw) calc(34.75px + 2.172vw);
			border: none;
			background: #7f56d9;
			color: #ffffff;
		}
	}

	@media (max-width: 768px) {
		gap: 10px;

		button {
			font-size: 16px;
			line-height: 19.36px;
		}
	}

	@media (min-width: 1600px) {
		gap: 10px;

		button {
			font-size: 18px;
			line-height: 21.78px;

			&:first-child {
				padding: 13px 68.5px;
			}

			&:nth-child(2) {
				padding: 13px 69.5px;
			}
		}
	}
`;
