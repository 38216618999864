import { memo, useState } from "react";
import { Link } from "react-router-dom";
import {
	FooterSection,
	FirstColumn,
	MiddleColumn,
	LastColumn,
	SearchIcon,
	FooterLogo,
	SocialLinks,
	SubscribeButton,
	SubscriberInput,
} from "./styles";

import footerSearchArrow from "../../../Assets/Images/Layout/Footer/footerSearchArrow.svg";
import footerSocial1 from "../../../Assets/Images/Layout/Footer/footerSocial1.svg";
import footerSocial2 from "../../../Assets/Images/Layout/Footer/footerSocial2.svg";
import footerSocial3 from "../../../Assets/Images/Layout/Footer/footerSocial3.svg";
import footerSocial4 from "../../../Assets/Images/Layout/Footer/footerSocial4.svg";
import { useMutation } from "@apollo/client";
import { ADD_SUBSCRIBER_MUTATION } from "../../../queries/subscriber";
import { validateEmail } from "../../../utils/email";

const DefaultFooter = () => {
	const [subscriberEmail, setSubscriberEmail] = useState("");
	const [validationErrors, setValidationErrors] = useState({});

	const [addSubscriber] = useMutation(ADD_SUBSCRIBER_MUTATION, {
		onCompleted: (data) => {
			setSubscriberEmail("");
		},
		onError: (error) => {
			console.log("error", error);
		},
	});

	const validate = () => {
		const newErrors = {};

		if (subscriberEmail && !validateEmail(subscriberEmail)) {
			newErrors.email = "Enter a valid email";
		}

		if (!subscriberEmail) {
			newErrors.email = "Email is not provided";
		}

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		setSubscriberEmail(value);

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (validate()) {
			await addSubscriber({
				variables: {
					email: subscriberEmail,
				},
			});
		}
	};

	return (
		<FooterSection>
			<div>
				<FirstColumn>
					<Link
						to={"/"}
						onClick={() =>
							window.scrollTo({
								top: 0,
								behavior: "smooth",
							})
						}
					>
						Fameeo
					</Link>
				</FirstColumn>

				<MiddleColumn>
					<h3>Quick Links</h3>
					<Link to="/category/all-celebrities">Categories</Link>
					<Link to={"/#HowItWorks"}>How it Works</Link>
					<Link to="/become-partner">Become a partner</Link>
					<Link to="/category/all-celebrities">Book a Video</Link>
				</MiddleColumn>

				<MiddleColumn>
					<h3>Help Center</h3>
					<Link to="/">FAQs</Link>
					<Link to="/">Troubleshooting Guides</Link>
					<Link to="/">Terms and Conditions</Link>
					<Link to="/">Privacy Policy</Link>
				</MiddleColumn>

				<LastColumn>
					<h3>Subscribe to our emails</h3>
					<form onSubmit={handleSubmit}>
						<div>
							<SubscriberInput
								type="email"
								placeholder="Email"
								value={subscriberEmail}
								onChange={handleChange}
								error={validationErrors.email}
							/>
							<SubscribeButton type="submit">
								<img loading="lazy" src={footerSearchArrow} alt="" />
							</SubscribeButton>
						</div>
						{validationErrors.email && <p>{validationErrors.email}</p>}
					</form>
					<SocialLinks>
						<Link to="/">
							<img loading="lazy" src={footerSocial1} alt="Facebook" />
						</Link>
						<Link to="/">
							<img loading="lazy" src={footerSocial2} alt="Instagram" />
						</Link>
						<Link to="/">
							<img loading="lazy" src={footerSocial3} alt="Youtube" />
						</Link>
						<Link to="/">
							<img loading="lazy" src={footerSocial4} alt="Tiktok" />
						</Link>
					</SocialLinks>
					<div>
						<span>
							© Powered by{" "}
							<Link to="https://spesna.com" target="_blank">
								Spesna
							</Link>
						</span>
					</div>
				</LastColumn>
			</div>

			<FooterLogo>
				<span>Fameeo</span>
			</FooterLogo>
		</FooterSection>
	);
};

export default memo(DefaultFooter);
