import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: calc(20px + 1.25vw);

	@media (min-width: 1600px) {
		gap: 40px;
	}
`;

export const SectionHeading = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: calc(4px + 0.25vw);

	h2 {
		font-size: calc(16px + 1vw);
		font-weight: 500;
		line-height: calc(18px + 1.171vw);
		text-align: center;
		color: #121212;
		margin: 0;
	}

	span {
		font-size: calc(6px + 0.375vw);
		font-weight: 300;
		line-height: calc(6px + 0.532vw);
		text-align: left;
		color: #000000;
	}

	@media (min-width: 1600px) {
		gap: 8px;

		h2 {
			font-size: 32px;
			line-height: 38.73px;
		}

		span {
			font-size: 12px;
			line-height: 14.52px;
		}
	}
`;

export const ResetPasswordForm = styled.form`
	max-width: 400px;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: calc(6px + 0.375vw);

	input {
		width: 100%;
		box-sizing: border-box;
		position: relative;
		padding: calc(7px + 0.438vw);
		border: 1px solid #d6cfdb;
		border-radius: 80px;

		&::placeholder {
			font-size: calc(7px + 0.438vw);
			font-weight: 300;
			line-height: calc(8px + 0.526vw);
			text-align: left;
			color: #121212;
		}
	}

	button {
		cursor: pointer;
		margin-top: 4px;
		padding: calc(7px + 0.438vw);
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-radius: 80px;
		border: none;
		background-color: #9c4ccf;

		font-size: calc(8px + 0.5vw);
		font-weight: 500;
		line-height: calc(8px + 0.672vw);
		text-align: left;
		color: #ffffff;
	}

	// errors

	p {
		font-size: calc(6px + 0.375vw);
		font-weight: 400;
		line-height: calc(7px + 0.441vw);
		text-align: left;
		color: #d00306;
		margin: 9px 0 0 0;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	@media (min-width: 1600px) {
		input {
			padding: 14px;

			&::placeholder {
				font-size: 14px;
				line-height: 16.41px;
			}
		}

		button {
			margin-top: 4px;
			padding: 14px;

			font-size: 16px;
			line-height: 18.75px;
		}
	}
`;

export const VerificationDigits = styled.div`
	width: 100%;
	height: calc(60px + 1vw);
	padding: 0 calc(6px + 2.813vw);
	box-sizing: border-box;
	display: flex;
	gap: calc(7px + 0.438vw);

	input {
		outline: none;
		appearance: textfield;
		margin: 0;
		border: 1px solid ${(props) => (props.error ? "#d00306 !important" : "#d6cfdb")};
		border-radius: 14px;
		background-color: #f1f1f1;

		font-size: calc(24px + 0.75vw);
		font-weight: 400;
		line-height: calc(30px + 0.762vw);
		text-align: center;

		&:focus {
			background-color: #ffffff;
		}

		&[type="number"] {
			-moz-appearance: textfield;

			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
	}

	@media (max-width: 1200px) {
		padding: 0;
	}

	@media (min-width: 1600px) {
		height: 76px;
		padding: 0 51px;
		gap: 14px;

		input {
			font-size: 36px;
			line-height: 42.19px;
		}
	}
`;

export const VerificationTime = styled.div`
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 12px 0;

	span {
		font-size: calc(10px + 0.125vw);
		font-weight: 300;
		line-height: calc(12px + 0.129vw);
		text-align: center;
		color: #9c4ccf;
	}
`;

export const ReceiveCode = styled.span`
	display: flex;
	gap: 6px;
	font-size: calc(7px + 0.438vw);
	font-weight: 400;
	line-height: calc(10px + 0.625vw);
	letter-spacing: 0.30000001192092896px;
	text-align: center;
	color: #121212;

	a {
		color: #9c4ccf;
	}

	@media (min-width: 1600px) {
		gap: 6px;
		font-size: 14px;
		line-height: 20px;
	}
`;
