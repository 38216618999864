import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { GET_REQUESTS_BY_CELEBRITY_QUERY, UPDATE_REQUEST_MUTATION } from "../../../../queries/request";
import { useMobileDetector } from "../../../../utils/customHooks";
import {
	CardButtonRow,
	CardCol1,
	CardCol2,
	CardHeading,
	CelebrityRequests,
	RequestCard,
	RequestMessage,
	RequestsContainer,
	RequestsGrid,
	RequestsHeading,
} from "./styles";

const formatDate = (isoString) => {
	const date = new Date(isoString);
	const month = String(date.getUTCMonth() + 1).padStart(2, "0");
	const day = String(date.getUTCDate()).padStart(2, "0");
	return `${day}.${month}`;
};

const CelebrityProfileRequests = () => {
	const isMobile = useMobileDetector();
	const { celebrityId } = useParams();

	const { data: requestsData } = useQuery(GET_REQUESTS_BY_CELEBRITY_QUERY, {
		fetchPolicy: "network-only",
		variables: { celebrityId: celebrityId },
	});

	const [updateRequest] = useMutation(UPDATE_REQUEST_MUTATION, {
		onCompleted: (data) => {
			console.log("data", data);
		},
		onError: (error) => {
			// dispatch(setError(error.message));
			console.log("error", error);
		},
	});

	console.log("requestsData", requestsData);

	const handleClick = async (e, requestId, customerId, celebrityId) => {
		const status = e.target.name;

		try {
			await updateRequest({
				variables: {
					requestId,
					requestInput: {
						customerId,
						celebrityId,
						status,
					},
				},
			});
		} catch (error) {
			console.log("Error updating the request", error);
		}
	};

	return (
		<CelebrityRequests>
			<RequestsContainer>
				<RequestsHeading>
					<h2>New Video Request</h2>
				</RequestsHeading>
				<RequestsGrid>
					{requestsData?.getRequestsByCelebrity.map((request, index) => (
						<RequestCard key={index}>
							{!isMobile && <CardHeading>Request Details:</CardHeading>}
							<div>
								<CardCol1>
									{isMobile && <CardHeading>Request Details:</CardHeading>}
									<h4>
										Video From: <span>{request?.details?.videoFrom?.name}</span>
									</h4>
									<h4>
										Video For: <span>{request?.details?.videoFor?.name}</span>
									</h4>
									{!isMobile && (
										<RequestMessage>
											Message:
											<p>{request?.details?.message}</p>
										</RequestMessage>
									)}
								</CardCol1>
								<CardCol2>
									<div>
										<div>
											<h4>Request Date</h4>
											<span>{formatDate(request.createdAt)}</span>
										</div>
										<div>
											<h4>Occasion</h4>
											{/* {request?.details?.occasion} is not working */}
											<span>Birthday</span>
										</div>
									</div>
								</CardCol2>
							</div>
							{isMobile && (
								<RequestMessage>
									Message:
									<p>{request?.details?.message}</p>
								</RequestMessage>
							)}
							<CardButtonRow>
								<button
									name="declined"
									onClick={(e) => handleClick(e, request.id, request.customerId, request.celebrityId)}
								>
									Decline
								</button>
								<button
									name="accepted"
									onClick={(e) => handleClick(e, request.id, request.customerId, request.celebrityId)}
								>
									Accept
								</button>
							</CardButtonRow>
						</RequestCard>
					))}
				</RequestsGrid>
			</RequestsContainer>
		</CelebrityRequests>
	);
};

export default CelebrityProfileRequests;
