import styled from "styled-components";

export const Section = styled.section`
	//width: calc(100% + 30px);
	width: 100%;
	max-width: 1430px;
	position: relative;
	left: 0;
	right: 0;
	margin: calc(40px + 2.5vw) auto 0 auto;
	display: flex;
	align-items: center;

	@media (max-width: 996px) {
		flex-direction: column-reverse;
	}

	@media (max-width: 768px) {
		margin-top: 60px;
	}

	@media (min-width: 1600px) {
		margin-top: 80px;
	}
`;

export const SectionColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	&:first-child {
		width: 44.73%;
		gap: calc(15px + 0.938vw);

		h1 {
			font-size: calc(24px + 3vw);
			font-weight: 500;
			line-height: calc(32px + 3.446vw);
			letter-spacing: -0.011em;
			text-align: left;
			text-transform: uppercase;
			margin: 0;
		}

		p {
			font-size: calc(12px + 0.75vw);
			font-weight: 400;
			line-height: calc(16px + 0.875vw);
			letter-spacing: -0.011em;
			text-align: left;
			margin: 0;
		}

		a {
			text-decoration: navajowhite;
			cursor: pointer;
			padding: calc(9px + 0.563vw) calc(25px + 1.563vw);
			gap: calc(5px + 0.313vw);
			border-radius: 110px;
			background: #121212;

			font-size: calc(12px + 0.75vw);
			font-weight: 600;
			line-height: calc(16px + 1.25vw);
			letter-spacing: -0.011em;
			text-align: left;
			color: #ffffff;
			margin-top: 10px;
		}
	}

	&:nth-child(2) {
		width: 56.09%;
		margin-top: 80px;

		img {
			width: 100%;
		}
	}

	@media (max-width: 996px) {
		&:first-child {
			width: 100%;
		}

		&:nth-child(2) {
			width: 100%;
		}
	}

	@media (max-width: 768px) {
		&:first-child {
			width: 100%;

			a {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}

		&:nth-child(2) {
			margin-top: 0;
		}
	}

	@media (min-width: 1600px) {
		&:first-child {
			gap: 30px;

			h1 {
				font-size: 72px;
				line-height: 87.14px;
			}

			p {
				font-size: 24px;
				line-height: 30px;
			}

			a {
				padding: 18px 50px 18px 50px;
				gap: 10px;

				font-size: 24px;
				line-height: 36px;
			}
		}
	}
`;
