import styled from "styled-components";

export const Section = styled.section`
	max-width: 1500px;
	position: relative;
	left: 0;
	right: 0;
	margin: calc(70px + 4.375vw) auto 0 auto;
	display: flex;
	justify-content: space-between;
	transform: translateX(-100px);
	width: calc(100% + 100px);
	//overflow: hidden;

	@media (max-width: 996px) {
		width: calc(100% + 50px);
		transform: translateX(-50px);
	}

	@media (max-width: 768px) {
		width: 100%;
		transform: translateX(0);
		flex-direction: column-reverse;
		gap: 24px;
	}

	@media (min-width: 1600px) {
		margin-top: 140px;
	}
`;

export const SectionColumn = styled.div`
	display: flex;

	&:first-child {
		width: 55%;
		flex-direction: row;
		gap: calc(6px + 0.375vw);
		max-height: 497.05px;
	}

	&:nth-child(2) {
		width: 38.46%;
		flex-direction: column;
		align-items: flex-end;
		gap: 0.938vw;

		button {
			cursor: pointer;
			padding: calc(9px + 0.563vw) calc(22px + 1.375vw);
			gap: calc(5px + 0.313vw);
			border-radius: 100px;
			background-color: #121212;

			font-size: calc(12px + 0.75vw);
			font-weight: 600;
			line-height: calc(16px + 0.875vw);
			letter-spacing: -0.011em;
			text-align: left;
			color: #ffffff;
			margin-top: calc(5px + 0.313vw);
		}
	}

	@media (max-width: 1200px) {
		button {
			font-size: calc(8px + 0.75vw) !important;
			line-height: calc(12px + 0.875vw) !important;
		}
	}

	@media (max-width: 1500px) {
		&:nth-child(2) {
			width: 50%;
			gap: 24px;

			button {
				margin-top: 0;
			}
		}
	}

	@media (max-width: 768px) {
		width: 100% !important;

		&:first-child {
			width: 100vw !important;
			transform: translateX(-50px);
		}

		&:nth-child(2) {
			& > div:last-child {
				width: 100%;

				button {
					width: 100%;
					display: flex;
					justify-content: center;
				}
			}
		}
	}

	@media (min-width: 1600px) {
		&:first-child {
			gap: 12px;
		}

		&:nth-child(2) {
			gap: 30px;

			button {
				padding: 18px 44px;
				gap: 10px;

				font-size: 24px;
				line-height: 36px;
			}
		}
	}
`;

export const SectionDescription = styled.p`
	display: flex;
	flex-direction: column;
	margin: 0;

	span {
		font-size: calc(12px + 0.75vw);
		font-weight: 400;
		line-height: calc(16px + 0.875vw);
		letter-spacing: -0.011em;
		text-align: right;
	}

	@media (max-width: 1200px) {
		span {
			font-size: calc(8px + 0.75vw) !important;
			line-height: calc(12px + 0.875vw) !important;
		}
	}

	@media (max-width: 768px) {
		width: 100%;
		flex-direction: row;

		span {
			text-align: left;
		}
	}

	@media (min-width: 1600px) {
		span {
			font-size: 24px;
			line-height: 30px;
		}
	}
`;

export const ImageColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: calc(6px + 0.375vw);

	&:nth-child(2) {
		gap: calc(8px + 0.5vw);
	}

	& > div {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: calc(6px + 0.375vw);

		& > img {
			width: 60% !important;
		}

		& > div {
			width: 36.25%;
		}
	}

	img {
		width: 100%;
	}

	@media (min-width: 1600px) {
		gap: 12px;

		&:nth-child(2) {
			gap: 16px;
		}

		& > div {
			gap: 12px;
		}
	}
`;

export const SectionHeading = styled.h1`
	display: flex;
	flex-direction: column;
	margin-bottom: 0;

	span:last-child {
		font-style: italic;
	}

	@media (max-width: 996px) {
		margin-top: 0;
	}

	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const TextRow = styled.span`
	font-size: calc(24px + 3vw);
	font-weight: 500;
	line-height: calc(32px + 3.446vw);
	letter-spacing: -0.011em;
	text-align: right;
	text-transform: uppercase;

	@media (max-width: 1200px) {
		font-size: calc(14px + 3vw) !important;
		line-height: calc(18px + 3.446vw) !important;
	}

	@media (min-width: 1600px) {
		font-size: 72px;
		line-height: 87.14px;
	}

	@media (max-width: 768px) {
		text-align: left;
	}
`;
