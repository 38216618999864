import styled from "styled-components";
import { Link } from "react-router-dom";

export const Section = styled.section`
	width: 100%;
	//height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const SectionHeading = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 370px;
	background: linear-gradient(0deg, #f4e3ff 0%, #ffffff 100%);
	z-index: -1;
`;

export const SectionContainer = styled.div`
	width: 100%;
	max-width: 1400px;
	margin-top: 254px;
	display: flex;
	gap: calc(14px + 0.875vw);
	flex-wrap: wrap;

	// for flex-wrap

	& > div {
		min-width: 474px;
	}

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 30px;

		& > div {
			min-width: unset;
			padding: 20px 24px;
			box-sizing: border-box;
			border-radius: 20px;
		}
	}

	@media (min-width: 1600px) {
		gap: 28px;
	}
`;

export const Payment = styled.div`
	flex: 1;
	padding: calc(22.4px + 1.406vw) calc(41.5px + 2.594vw) 0;
	display: flex;
	flex-direction: column;
	gap: calc(16px + 1vw);
	box-sizing: border-box;

	h2 {
		margin: 0;
		font-size: calc(15px + 0.938vw);
		font-weight: 600;
		line-height: calc(18px + 1.144vw);
		letter-spacing: -0.02em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #0a0d13;
	}

	@media (max-width: 768px) {
		h2 {
			font-size: 18px;
			line-height: 21.78px;
		}
	}

	@media (max-width: 1400px) {
		padding: calc(22.4px + 1.406vw) calc(20px + 1.25vw) 0;
	}

	@media (min-width: 1600px) {
		padding: 45px 83px 0;
		gap: 32px;

		h2 {
			font-size: 30px;
			line-height: 36.31px;
		}
	}
`;

export const PaymentForm = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(12px + 0.75vw);

	@media (max-width: 768px) {
	}

	@media (min-width: 1600px) {
		gap: 24px;
	}
`;

export const FormRow = styled.div`
	width: 100%;
	display: flex;
	gap: calc(9px + 0.563vw);

	& > div {
		display: flex;
		flex-direction: column;
		gap: calc(8px + 0.5vw);

		&:first-child {
			flex: 1;
		}

		p {
			margin-bottom: 0;
			color: #d00306;
			font-size: 14px;
			font-weight: 400;
			line-height: 16.94px;
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;
		}
	}

	label {
		font-size: calc(9px + 0.563vw);
		font-weight: 500;
		line-height: calc(11px + 0.688vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #0a0d13;
	}

	input {
		width: 100%;
		padding: calc(6px + 0.375vw) calc(8px + 0.5vw);
		border: 1.5px solid #acacac;
		font-size: calc(8px + 0.5vw);
		font-weight: 400;
		line-height: calc(11px + 0.688vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
		border-radius: 80px;
		box-sizing: border-box;

		&::placeholder {
			color: #acacac;
		}
	}

	@media (max-width: 768px) {
		//flex-direction: column;
		& > div {
			flex: 1;
		}

		label {
			font-size: 12px;
			line-height: 14.52px;
		}

		input {
			padding: 12px 16px;
			font-size: 12px;
			line-height: 14.06px;
		}
	}

	@media (min-width: 1600px) {
		gap: 18px;

		& > div {
			gap: 16px;
		}

		label {
			font-size: 18px;
			line-height: 22px;
		}

		input {
			padding: 12px 16px;
			font-size: 16px;
			line-height: 22px;
		}
	}
`;

export const CardNumberRow = styled.div`
	input {
		border: 1.5px solid ${(props) => (props.error ? "#D00306" : "#acacac")};
	}
`;

export const ExpirationDateRow = styled.div`
	input {
		border: 1.5px solid ${(props) => (props.error ? "#D00306" : "#acacac")};
	}
`;

export const CVVRow = styled.div`
	input {
		border: 1.5px solid ${(props) => (props.error ? "#D00306" : "#acacac")};
	}
`;

export const SaveCardCheckbox = styled.div`
	margin-top: 24px;

	span {
		display: flex;
		align-items: center;
		gap: calc(4px + 0.25vw);
		font-size: 14px;
		font-weight: 400;
		line-height: 16.94px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;

		span {
			width: 21px;
			height: 20px;
			border: 1px solid #7f56d9;
			border-radius: 4px;
			box-sizing: border-box;
		}

		a {
			color: #9c4ccf;
		}
	}

	@media (max-width: 768px) {
		margin-top: 12.5px;

		span {
			font-size: 12px;
			line-height: 14.52px;

			span,
			img {
				width: 16px;
				height: 16px;
			}
		}
	}

	@media (min-width: 1600px) {
		span {
			gap: 8px;
			font-size: 14px;
			line-height: 16.94px;

			span {
				width: 21px;
				height: 20px;
			}
		}
	}
`;

export const PayButton = styled.button`
	margin-top: 8px;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	background-color: #7f56d9;
	font-size: calc(8px + 0.5vw);
	font-weight: 600;
	line-height: calc(9.5px + 0.616vw);
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #f3f3f3;
	padding: calc(10px + 0.625vw) 0;
	box-sizing: border-box;
	border-radius: 80px;
	cursor: pointer;
	border: none;

	@media (max-width: 768px) {
		width: unset;
		font-size: 14px;
		line-height: 16.94px;
		padding: 12px 44px;
		font-weight: 500;
	}

	@media (min-width: 1600px) {
		font-size: 16px;
		line-height: 19.36px;
		padding: 20px 0;
	}
`;

export const PayButtonMobileContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding: 0 !important;
`;

export const OrderSummary = styled.div`
	flex: 1;
	padding: calc(22.5px + 1.406vw) calc(36px + 2.281vw);
	background-color: #fafafa;
	border-radius: 40px;
	display: flex;
	flex-direction: column;
	gap: calc(16px + 1vw);
	box-sizing: border-box;

	@media (max-width: 1400px) {
		padding: calc(22.5px + 1.406vw) calc(20px + 1.25vw);
	}

	@media (min-width: 1600px) {
		padding: 45px 72.5px;
		gap: 32px;
	}
`;

export const OrderHeading = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(10px + 0.625vw);

	h2 {
		margin: 0;
		font-size: calc(15px + 0.938vw);
		font-weight: 600;
		line-height: calc(14px + 0.875vw);
		letter-spacing: -0.02em;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #0a0d13;
	}

	p {
		margin: 0;
		font-size: calc(9px + 0.563vw);
		font-weight: 400;
		line-height: calc(10.5px + 0.705vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 768px) {
		gap: 14px;

		h2 {
			font-size: 18px;
			line-height: 21.78px;
		}

		p {
			font-size: 10px;
			line-height: 12.1px;
		}
	}

	@media (min-width: 1600px) {
		gap: 20px;

		h2 {
			font-size: 30px;
			line-height: 28px;
		}

		p {
			font-size: 18px;
			line-height: 21.78px;
		}
	}
`;

export const OrderDetails = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: calc(12px + 0.75vw);

	@media (min-width: 1600px) {
		gap: 24px;
	}
`;

export const OrderImage = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > div {
		display: flex;
		align-items: center;
		gap: calc(9px + 0.563vw);

		img {
			width: 92px;
			height: 92px;
			object-fit: cover;
			border-radius: 20px;
		}
	}

	span {
		font-size: calc(9px + 0.563vw);
		font-weight: 500;
		line-height: calc(10.5px + 0.705vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 768px) {
		& > div {
			gap: 10px;

			img {
				width: 50px;
				height: 50px;
				border-radius: 8px;
			}
		}

		span {
			font-size: 12px;
			line-height: 14.52px;
		}
	}

	@media (min-width: 1600px) {
		& > div {
			gap: 18px;

			//

			img {
				width: 92px;
				height: 92px;
			}
		}

		span {
			font-size: 18px;
			line-height: 21.78px;
		}
	}
`;

export const OrderDiscount = styled.div`
	width: 100%;
	display: flex;
	gap: calc(5px + 0.313vw);

	input {
		width: 100%;
		padding: calc(6px + 0.375vw) calc(8px + 0.5vw);
		border: 1.5px solid #acacac;
		border-radius: 80px;
		color: #000000;

		&::placeholder {
			color: #acacac;
		}
	}

	button {
		cursor: pointer;
		background-color: #a4a7ae;
		padding: calc(7px + 0.469vw) calc(24px + 1.531vw);
		border-radius: 80px;
		font-size: calc(8px + 0.5vw);
		font-weight: 500;
		line-height: calc(9.5px + 0.616vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #ffffff;
		border: none;
	}

	@media (max-width: 768px) {
		input {
			padding: 12.5px 16px;
			font-size: 12px;
			line-height: 14.06px;
		}

		button {
			padding: 12px 30.5px;
			font-size: 12px;
			line-height: 14.52px;
		}
	}

	@media (min-width: 1600px) {
		gap: 10px;

		input {
			padding: 12px 16px;
		}

		button {
			padding: 14.5px 48.5px;
			font-size: 16px;
			line-height: 19.36px;
		}
	}
`;

export const OrderTotal = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;

	span {
		font-size: calc(9px + 0.563vw);
		font-weight: 600;
		line-height: calc(10.5px + 0.705vw);
		text-align: right;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 768px) {
		font-size: 12px;
		line-height: 14.52px;
	}

	@media (min-width: 1600px) {
		span {
			font-size: 18px;
			line-height: 21.78px;
		}
	}
`;

export const BookingButton = styled(Link)`
	padding: 16px;
	font-size: 20px;
	font-weight: 600;
	line-height: 23.44px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #ffffff;
	background-color: #7f56d9;
	width: 100%;
	display: flex;
	justify-content: center;
	border-radius: 80px;
	text-decoration: none;
	margin-top: 12px;
`;
