import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	HomePage,
	CategoryPage,
	SubscribedCelebrityPage,
	BookingPage,
	BecomePartnerPage,
	CustomerProfilePage,
} from "./Pages";
import DefaultLayout from "./Layouts/Default";
import AuthLayout from "./Layouts/Auth";
import CelebrityProfileLayout from "./Layouts/CelebrityProfile";
import CelebrityProfileEdit from "./Components/Molecules/CelebrityProfile/CelebrityProfileEdit";
import CelebrityProfileVideo from "./Components/Molecules/CelebrityProfile/CelebrityProfileVideo";
import CelebrityProfileBalance from "./Components/Molecules/CelebrityProfile/CelebrityProfileBalance";
import CelebrityProfileWallet from "./Components/Molecules/CelebrityProfile/CelebrityProfileWallet";
import CelebrityProfileRequests from "./Components/Molecules/CelebrityProfile/CelebrityProfileRequests";
import SignIn from "./Components/Molecules/Auth/SignIn";
import SignUp from "./Components/Molecules/Auth/SignUp";
import Verification from "./Components/Molecules/Auth/Verification";
import CelebritySignIn from "./Components/Organisms/CelebritySignIn";

const Routing = () => {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const userRole = useSelector((state) => state.user.role);

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<DefaultLayout />}>
					<Route path="/" element={<HomePage />} />
					<Route path="/category/:categoryName" element={<CategoryPage />} />
					<Route path="/public/celebrity/:celebrityId" element={<SubscribedCelebrityPage />} />
					<Route path="/become-partner" element={<BecomePartnerPage />} />

					{/*<Route path="/booking/:id" element={<BookingPage />} />*/}
					{isAuthenticated && userRole.type === "customer" && (
						<>
							<Route path="/customer/:id" element={<CustomerProfilePage />} />
							<Route path="/booking/:id" element={<BookingPage />} />
						</>
					)}

					{/* Celebrity Dashboard for Celebrities */}
					{isAuthenticated && userRole.type === "celebrity" && (
						<Route path="/dashboard/celebrity/:celebrityId" element={<CelebrityProfileLayout />}>
							<Route index element={<Navigate to="video" replace />} />

							<Route path="edit" element={<CelebrityProfileEdit />} />
							<Route path="video" element={<CelebrityProfileVideo />} />
							<Route path="balance" element={<CelebrityProfileBalance />} />
							<Route path="wallet" element={<CelebrityProfileWallet />} />
							<Route path="requests" element={<CelebrityProfileRequests />} />

							<Route path="*" element={<Navigate to="video" replace />} />
						</Route>
					)}

					{/* Fallback Route */}
					<Route exact path="*" element={<Navigate to="/" />} />
				</Route>

				{/* Auth Layout */}
				<Route path="/auth" element={<AuthLayout />}>
					<Route index element={<Navigate to="sign-in" replace />} />
					<Route path="sign-in" element={<SignIn />} />
					<Route path="sign-up" element={<SignUp />} />
					<Route path="verification" element={<Verification />} />
					<Route path="celebrity/sign-in" element={<CelebritySignIn />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default Routing;
