import styled from "styled-components";
import filterImg from "../../../../Assets/Images/Category/filter-icon.svg";
import arrowDownImg from "../../../../Assets/Images/Layout/Navbar/arrow-down-icon.svg";

export const BalanceContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 60px;
	margin-top: 45px;

	h2 {
		margin: 0;
		font-size: calc(16px + 1vw);
		font-weight: 600;
		line-height: calc(19px + 1.233vw);
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 768px) {
		font-size: 22px;
		line-height: 26.63px;
	}

	@media (min-width: 1600px) {
		font-size: 32px;
		line-height: 38.73px;
	}
`;

export const BalanceHeading = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;

	& > div {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 30px;
	}

	@media (min-width: 480px) and (max-width: 768px) {
		gap: 16px;

		& > div {
			gap: 20px;
		}
	}

	@media (max-width: 480px) {
		gap: 16px;

		& > div {
			gap: 4px;
		}
	}
`;

export const BalanceCard = styled.div`
	padding: 32px 55px 32px 32px;
	border-radius: 20px;
	background-color: #f9f5ff;
	display: flex;
	flex-direction: column;
	white-space: nowrap;
	width: 100%;
	max-width: 276px;
	box-sizing: border-box;
	gap: 8px;
	overflow: hidden;

	h4 {
		margin: 0;
		font-size: 16px;
		font-weight: 500;
		line-height: 19.36px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
		display: flex;
		align-items: center;
		gap: 8px;

		img {
			width: 24px;
			height: 24px;
		}
	}

	span {
		font-size: 32px;
		font-weight: 400;
		line-height: 38.73px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #000000;
	}

	@media (max-width: 768px) {
		max-width: 166px;
		padding: 16px 32px;
		border-radius: 12px;

		h4 {
			font-size: 10px;
			line-height: 12.1px;
		}

		span {
			font-size: 16px;
			line-height: 19.36px;
		}
	}
`;

export const BalanceTransactions = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;
`;

export const TransactionHeading = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;

	& > div > button {
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 500;
		font-size: calc(9px + 0.563vw);
		line-height: calc(10.5px + 0.705vw);
		color: #000000;
		padding: 13px 33.75px;
		background: transparent;
		border: 1px solid #d6cfdb;
		border-radius: 80px;

		&:after {
			content: "";
			background-image: url(${filterImg});
			background-position: center;
			background-repeat: no-repeat;
			width: calc(16px + 1vw);
			height: calc(16px + 1vw);
			display: inline-block;
			transform: rotate(${(props) => props.isOpen && "180deg"});
			transition: 0.3s;
		}
	}

	& > div {
		position: relative;
	}

	@media (min-width: 1600px) {
		& > div > button {
			font-size: 18px;
			line-height: 21.78px;
		}
	}
`;

export const FilterDropdown = styled.div`
	position: absolute;
	top: 100%;
	left: 0;
	margin-top: 7px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	padding: 16px 32px;
	z-index: 1;
	box-shadow: 0 1px 40px 0 #b8b8b82e;
	background-color: #ffffff;
	border-radius: 12px;
`;

export const FilterDropdownButton = styled.button`
	font-weight: 400;
	font-size: 16px;
	line-height: 19.36px;
	letter-spacing: 0;
	color: ${(props) => (props.current ? "#828282" : "#000000")};
	background: transparent;
	border: none;
`;

export const TransactionTableDesktop = styled.table`
	width: 100%;
	display: block;
	overflow-x: auto;
	background-color: transparent;
`;

export const TransactionRowDesktop = styled.tr`
	display: block;
	background-color: #fafafa;
	border-radius: 20px;
	margin-bottom: 10px;
	overflow: hidden;
	text-align: center;
	width: 100%;
	padding: 0;
	box-sizing: border-box;
	position: relative;

	th,
	td {
		display: inline-block;
		padding: 30px 20px;
		font-size: 14px;
		color: #4b5563;
		vertical-align: middle;
		text-align: left;
		box-sizing: border-box;

		&:nth-of-type(1) {
			width: calc(100% * 0.3);
		}

		&:nth-of-type(2) {
			width: calc(100% * 0.17);
		}

		&:nth-of-type(3) {
			width: calc(100% * 0.17);
		}

		&:nth-of-type(4) {
			width: calc(100% * 0.17);
		}

		&:nth-of-type(5) {
			width: calc(100% * 0.16);
		}
	}

	th {
		padding: 41px 20px;
		font-size: 14px;
		font-weight: 500;
		line-height: 16.94px;
		letter-spacing: 0.3404020071029663px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #374151;
		text-transform: uppercase;
	}

	td {
		padding: 30px 20px;
		font-size: 14px;
		font-weight: 400;
		line-height: 16.94px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		color: #4b5563;
	}
`;

export const TransactionTableMobile = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 6px;
`;

export const TransactionRowMobile = styled.details`
	width: 100%;
	background-color: #fafafa;
	border-radius: 20px;
	//padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	cursor: pointer;
	box-sizing: border-box;
	position: relative;

	&[open] {
		//& > summary:after {
		//	transform: rotate(180deg);
		//}

		& > summary {
			button {
				transform: rotate(180deg);
			}
		}
	}
`;

export const TransactionAmountMobile = styled.summary`
	font-size: 14px;
	font-weight: 400;
	line-height: 16.94px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #4b5563;
	list-style-type: none;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 16px;
	box-sizing: border-box;

	&::-webkit-details-marker {
		display: none;
	}

	// &::after {
	// 	content: url("${arrowDownImg}");
	// 	position: absolute;
	// 	transition: transform 0.2s ease;
	// 	right: 33px;
	// }

	& > div {
		display: flex;
		align-items: center;
	}

	span {
		color: #959ba4;
	}
`;

export const TransactionMobileBody = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	& > div {
		padding: 12px 16px;

		h4 {
			text-transform: uppercase;
			margin: 0;
			font-weight: 400;
			font-size: 8px;
			line-height: 9.68px;
			letter-spacing: 0.34px;
		}
	}
`;

export const MobilePaymentId = styled.div`
	span {
		font-weight: 400;
		font-size: 12px;
		line-height: 14.52px;
		letter-spacing: 0;
		color: #000000;
	}
`;

export const MobileDateCreated = styled.div`
	span {
		font-weight: 400;
		font-size: 10px;
		line-height: 12.1px;
		letter-spacing: 0;
		color: #4b5563;
	}
`;

export const MobilePaymentMethod = styled.div`
	span {
		font-weight: 400;
		font-size: 10px;
		line-height: 12.1px;
		letter-spacing: 0;
		color: #4b5563;
	}
`;

export const TranscationArrowButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
	transition: 0.3s ease-in-out;
	transform: rotate(${(props) => (props.isopen ? "180deg" : "0")});

	img {
		width: 24px;
		height: 24px;
	}
`;

export const TransactionStatus = styled.div`
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	padding: 12px 16px;
	border-radius: 80px;
	box-sizing: border-box;
	width: 100%;
	max-width: 116px;
	font-size: 12px;
	font-weight: 400;
	line-height: 14.52px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: ${(props) =>
		props.status === "succeeded"
			? "#4C9F7E"
			: props.status === "pending"
				? "#7F56D9"
				: props.status === "declined"
					? "#A63F58"
					: "#0033A0"};
	background-color: ${(props) =>
		props.status === "succeeded"
			? "#F7FFFB"
			: props.status === "pending"
				? "#F9F5FF"
				: props.status === "declined"
					? "#F9F4F6"
					: "#F3F5FF"};
`;

export const TransactionAmountDesktop = styled.summary`
	font-size: 14px;
	font-weight: 400;
	line-height: 16.94px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #4b5563;

	span {
		color: #959ba4;
	}
`;

export const TransactionPayment = styled.div`
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	font-size: 14px;
	font-weight: 400;
	line-height: 16.94px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: #4b5563;
`;
