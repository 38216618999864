import {
	Section,
	SectionHeading,
	ResetPasswordForm,
	ReceiveCode,
	VerificationDigits,
	VerificationTime,
} from "./styles";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";

const Verification = () => {
	const inputRefs = useRef([]);
	const [validationErrors, setValidationErrors] = useState({});

	const validate = () => {
		const newErrors = {};
		let hasNull = false;

		inputRefs.current.map((input) => {
			console.log(input.value);
			if (!input.value) hasNull = true;
		});

		// if password is incorrect
		// newErrors.verificationCode = "Verification Code is Incorrect";

		if (hasNull) newErrors.verificationCode = "Verification Code is required";

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleChange = (e, index) => {
		const { value } = e.target;

		// Only allow one digit for all inputs
		if (value.length > 1) {
			e.target.value = value[0]; // Keep only the first digit
		}

		// Move focus to the next input if a digit is entered
		if (value.length === 1 && index < 3) {
			inputRefs.current[index + 1].focus();
		}

		// Move focus back to the previous input if the current one is cleared
		if (value.length === 0 && index > 0) {
			inputRefs.current[index - 1].focus();
		}

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			verificationCode: "",
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (validate()) {
			//
		}

		if (validationErrors) {
			console.log("Errors:", validationErrors);
			return;
		}
	};

	return (
		<Section>
			<SectionHeading>
				<h2>Verification</h2>
				<span>Enter your 4 digits code that you received on your email.</span>
			</SectionHeading>

			<ResetPasswordForm action="" onSubmit={handleSubmit}>
				<div>
					<VerificationDigits error={validationErrors.verificationCode}>
						{[...Array(4)].map((_, index) => (
							<input
								key={index}
								type="number"
								maxLength="1" // Limit to one digit for all inputs
								ref={(el) => (inputRefs.current[index] = el)}
								onChange={(e) => handleChange(e, index)}
								onFocus={(e) => e.target.select()} // Selects the input on focus
							/>
						))}
					</VerificationDigits>
					{validationErrors.verificationCode && <p>{validationErrors.verificationCode}</p>}
				</div>
				<VerificationTime>
					<span>00:30</span>
				</VerificationTime>
				<button type="submit">Continue</button>
				<ReceiveCode>
					Didn’t receive the code?
					<Link to="/">Resend</Link>
				</ReceiveCode>
			</ResetPasswordForm>
		</Section>
	);
};

export default Verification;
