import { gql } from "@apollo/client";

export const GET_REQUESTS_BY_CELEBRITY_QUERY = gql`
	query GetRequestsByCelebrity($celebrityId: String!) {
		getRequestsByCelebrity(celebrityId: $celebrityId) {
			id
			customerId
			celebrityId
			details
			status
			createdAt
		}
	}
`;

export const ADD_REQUEST_MUTATION = gql`
	mutation AddRequest($requestInput: RequestInput!) {
		addRequest(requestInput: $requestInput) {
			id
			customerId
			celebrityId
			status
			details
		}
	}
`;

export const UPDATE_REQUEST_MUTATION = gql`
	mutation UpdateRequest($requestId: String!, $requestInput: RequestInput!) {
		updateRequest(requestId: $requestId, requestInput: $requestInput)
	}
`;
