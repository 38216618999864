import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

export const Section = styled.section`
	width: 100%;
	max-width: 1400px;
	position: relative;
	left: 0;
	right: 0;
	margin: 120px auto 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-width: 768px) {
		margin-top: 60px;
	}
`;

export const SectionHeading = styled.h1`
	font-size: calc(24px + 3vw);
	font-weight: 500;
	line-height: calc(32px + 3.446vw);
	letter-spacing: -0.011em;
	text-align: center;
	text-transform: uppercase;
	margin-top: 0;

	@media (max-width: 480px) {
		font-size: 32px;
		line-height: 38.73px;
		letter-spacing: -1.1px;
	}

	@media (min-width: 1600px) {
		font-size: 72px;
		line-height: 87.14px;
	}
`;

export const WorkContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	flex-wrap: wrap;
	gap: calc(20px + 1.25vw);

	& > div > div {
		margin: 0 20px;
	}

	@media (min-width: 996px) and (max-width: 1400px) {
		gap: 10px;
	}

	@media (min-width: 768px) and (max-width: 996px) {
		gap: 40px;
	}

	@media (max-width: 768px) {
		gap: 10px;
	}

	@media (min-width: 1600px) {
		gap: 40px;
	}
`;

export const StepCard = styled.div`
	width: 100%;
	max-width: 268px;
	padding: calc(15px + 0.938vw);
	border-radius: 30px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: ${(props) =>
		props.type === "purple"
			? "#F4EEFF"
			: props.type === "blue"
				? "#E2EBFF"
				: props.type === "orange"
					? "#FFE2E0"
					: "#F2FCFF"};

	h3 {
		padding: calc(4px + 0.25vw) calc(10.5px + 0.656vw);
		border-radius: 30px;
		box-sizing: border-box;
		margin: 0;
		font-weight: 600;
		font-size: calc(18px + 1.125vw);
		line-height: calc(21.15px + 1.379vw);
		letter-spacing: 0;
		text-align: center;
		color: #7f56d9;
		background-color: ${(props) =>
			props.type === "purple"
				? "#E4DBFB"
				: props.type === "blue"
					? "#D9E2F6"
					: props.type === "orange"
						? "#FFD6D4"
						: "#EAFBFF"};
	}

	span {
		margin-top: 20px;
		display: flex;
		align-items: center;
		gap: calc(5px + 0.313vw);
		font-weight: 600;
		font-size: calc(10px + 0.625vw);
		line-height: calc(12.1px + 0.756vw);
		letter-spacing: 0;
		color: #000000;
	}

	p {
		margin: calc(4px + 0.25vw) 0 0;
		font-weight: 400;
		font-size: calc(8px + 0.5vw);
		line-height: calc(9.5px + 0.616vw);
		letter-spacing: 0;
		color: #717680;
	}

	@media (min-width: 1200px) and (max-width: 1400px) {
		max-width: 250px;
	}

	@media (min-width: 996px) and (max-width: 1200px) {
		max-width: 212px;
	}

	@media (min-width: 768px) and (max-width: 996px) {
		max-width: 300px;
	}

	@media (min-width: 540px) and (max-width: 768px) {
		max-width: 236px;
	}

	@media (min-width: 480px) and (max-width: 540px) {
		max-width: 200px;
	}

	@media (max-width: 480px) {
		padding: 16px;
		border-radius: 14px;
		max-width: 166px;

		h3 {
			padding: 4px 10.5px;
			font-size: 18px;
			line-height: 21.78px;
		}

		span {
			font-size: 12px;
			line-height: 14.52px;

			img {
				width: 14px;
				height: 14px;
			}
		}

		p {
			font-size: 8px;
			line-height: 9.68px;
		}
	}

	@media (min-width: 1600px) {
		max-width: 310px;
		padding: 30px;

		h3 {
			padding: 8px 21px;
			font-size: 36px;
			line-height: 43.57px;
		}

		span {
			margin-top: 20px;
			gap: 10px;
			font-size: 20px;
			line-height: 24.2px;
		}

		p {
			margin: 8px 0 0;
			font-size: 16px;
			line-height: 19.36px;
		}
	}
`;
