import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { setAuthenticated, setError, setLoading } from "../../../reducers/auth";
import { setUserRole } from "../../../reducers/user";
import { CELEBRITY_SIGN_IN_MUTATION } from "../../../queries/celebrity";
import { useDispatch, useSelector } from "react-redux";
import { Section, SectionHeading, SignInButton, SignInForm, SignInInputs, Spinner } from "../../Molecules/Auth/SignIn/styles";
import { EmailInput, PasswordInput } from "./styles";
import { validateEmail } from "../../../utils/email";

const CelebritySignIn = () => {
	const dispatch = useDispatch();
	const { loading, error } = useSelector((state) => state.auth);
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});
	const [validationErrors, setValidationErrors] = useState({});
	const navigate = useNavigate();

	const [celebritySignIn] = useMutation(CELEBRITY_SIGN_IN_MUTATION, {
		onCompleted: (data) => {
			if (data.celebritySignIn.success) {
				dispatch(setAuthenticated(data.celebritySignIn.tokens));
				dispatch(setUserRole({ type: "celebrity" }));
				navigate("/");
			}
		},
		onError: (error) => {
			dispatch(setError(error.message));
			console.log("error", error);
		},
	});

	const validate = () => {
		const newErrors = {};
		const { email, password } = formData;

		if (!email) {
			newErrors.email = "Email is required";
		} else if (!validateEmail(email)) {
			newErrors.email = "Enter a valid email";
		}

		if (!password) {
			newErrors.password = "Password is required";
		} else if (password.length < 8) {
			newErrors.password = "Enter a valid password";
		}

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (validate()) {
			dispatch(setLoading(true));
			try {
				await celebritySignIn({
					variables: { email: formData.email, password: formData.password },
				});
			} finally {
				dispatch(setLoading(false));
			}
		}
	};

	return (
		<Section>
			<SectionHeading>
				<h2>Sign In</h2>
				<span>Keep it all together and you”ll be fine.</span>
			</SectionHeading>

			<SignInForm action="" onSubmit={handleSubmit}>
				<SignInInputs errors={validationErrors}>
					<div>
						<EmailInput
							type="text"
							placeholder={"Enter your email"}
							name="email"
							value={formData.email}
							onChange={handleChange}
							error={validationErrors.email}
						/>
						{validationErrors.email && <p>{validationErrors.email}</p>}
					</div>
					<div>
						<PasswordInput
							type="password"
							placeholder={"Enter your password"}
							name="password"
							value={formData.password}
							onChange={handleChange}
							error={validationErrors.password}
						/>
						{validationErrors.password && <p>{validationErrors.password}</p>}
					</div>
					<SignInButton type="submit" loading={loading}>
						{loading ? <Spinner /> : "Sign In"}
					</SignInButton>
				</SignInInputs>
			</SignInForm>
		</Section>
	);
};

export default CelebritySignIn;
