import { memo, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_CATEGORIES_QUERY } from "../../../queries/category";
import {
	MenuContainer,
	SidebarContainer,
	SidebarBody,
	SectionHeading,
	SocialLinks,
	SidebarLogo,
	SubCategoryArrow,
} from "./styles";
// import logoImg from "../../../Assets/Images/MobileMenu/logo-icon.svg";
// import closeBtn from "../../../Assets/Images/MobileMenu/close-btn.svg";
import arrowDownImg from "../../../Assets/Images/Layout/Navbar/arrow-down-icon.svg";
import facebookImg from "../../../Assets/Images/Layout/Sidebar/sidebar-facebook.svg";
import instagramImg from "../../../Assets/Images/Layout/Sidebar/sidebar-instagram.svg";
import youtubeImg from "../../../Assets/Images/Layout/Sidebar/sidebar-youtube.svg";
import tiktokImg from "../../../Assets/Images/Layout/Sidebar/sidebar-tiktok.svg";

const Sidebar = ({ handleMenuClose }) => {
	const [categorySelected, setCategorySelected] = useState(false);
	let location = useLocation();
	const sectionRef = useRef(null);

	const { data: categoriesData } = useQuery(GET_ALL_CATEGORIES_QUERY, {
		fetchPolicy: "network-only",
	});

	useEffect(() => {
		sectionRef.current.style.transform = "translateX(0)";
		sectionRef.current.style.transition = "0.5s ease-in-out";
	}, []);

	return (
		<SidebarContainer ref={sectionRef}>
			<SidebarBody>
				<SectionHeading>
					{!categorySelected ? (
						<SidebarLogo to={"/"}>Fameeo</SidebarLogo>
					) : (
						<>
							<SubCategoryArrow
								src={arrowDownImg}
								alt="Arrow Left"
								onClick={() => setCategorySelected(false)}
							/>
							<h2>Categories</h2>
						</>
					)}
					<span onClick={handleMenuClose}>&times;</span>
				</SectionHeading>

				<MenuContainer>
					{!categorySelected ? (
						<>
							<Link
								to="/"
								onClick={handleMenuClose}
								loc={location.pathname}
								current={location.pathname === "/" ? 1 : 0}
							>
								Home
							</Link>
							<span onClick={() => setCategorySelected(true)} loc={location.pathname}>
								Categories
								<img loading="lazy" src={arrowDownImg} alt="" />
							</span>
							<Link
								to="/"
								onClick={handleMenuClose}
								loc={location.pathname}
								current={location.pathname === "/" ? 1 : 0}
							>
								How it works
							</Link>
							<Link
								to="/become-partner"
								onClick={handleMenuClose}
								loc={location.pathname}
								current={location.pathname === "/become-partner" ? 1 : 0}
							>
								Become a partner
							</Link>
							<Link
								to="/"
								onClick={handleMenuClose}
								loc={location.pathname}
								current={location.pathname === "/" ? 1 : 0}
							>
								Book a Video
							</Link>
							<Link to="/auth/sign-in" onClick={handleMenuClose} style={{ marginTop: "69px" }}>
								Log in
							</Link>
						</>
					) : (
						<>
							<Link to={`/category/all-celebrities`} onClick={handleMenuClose}>
								All Celebrities
							</Link>
							{categoriesData?.getAllCategories.map((category, index) => (
								<Link
									to={`/category/${category.name.toLowerCase().split(" ").join("-")}`}
									key={index}
									onClick={handleMenuClose}
								>
									{category.name}
								</Link>
							))}
						</>
					)}
				</MenuContainer>

				<SocialLinks>
					<Link to="" target={"_blank"}>
						<img loading="lazy" src={facebookImg} alt="" />
					</Link>
					<Link to="" target={"_blank"}>
						<img loading="lazy" src={instagramImg} alt="" />
					</Link>
					<Link to="" target={"_blank"}>
						<img loading="lazy" src={youtubeImg} alt="" />
					</Link>
					<Link to="" target={"_blank"}>
						<img loading="lazy" src={tiktokImg} alt="" />
					</Link>
				</SocialLinks>
			</SidebarBody>
		</SidebarContainer>
	);
};

export default memo(Sidebar);
