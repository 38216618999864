import { Section, SectionHeading, WorkContainer, StepCard } from "./styles";
import findImg from "../../../../Assets/Images/Home/find-celebrity-icon.svg";
import bookImg from "../../../../Assets/Images/Home/book-celebrity-icon.svg";
import getVideoImg from "../../../../Assets/Images/Home/get-video-icon.svg";
import shareImg from "../../../../Assets/Images/Home/share-video-icon.svg";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const WorkSteps = () => {
	let location = useLocation();

	useEffect(() => {
		if (location.hash === "#HowItWorks") {
			const section = document.getElementById("HowItWorks");
			if (section) {
				section.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [location]);

	return (
		<Section id="HowItWorks">
			<SectionHeading>How It Works</SectionHeading>

			<WorkContainer>
				<StepCard type={"purple"}>
					<h3>1</h3>
					<span>
						<img loading="lazy" src={findImg} alt="" /> Find a celebrity
					</span>
					<p>Browse thousands of stars offering personalized videos.</p>
				</StepCard>
				<StepCard type={"blue"}>
					<h3>2</h3>
					<span>
						<img loading="lazy" src={bookImg} alt="" /> Book them
					</span>
					<p>Describe what you want to include in the video.</p>
				</StepCard>
				<StepCard type={"orange"}>
					<h3>3</h3>
					<span>
						<img loading="lazy" src={getVideoImg} alt="" /> Get your video
					</span>
					<p>The celebrity crafts your video and sends it to you</p>
				</StepCard>
				<StepCard type={"green"}>
					<h3>4</h3>
					<span>
						<img loading="lazy" src={shareImg} alt="" /> Share the joy
					</span>
					<p>Share a video gift and witness a reaction they'll never forget.</p>
				</StepCard>
			</WorkContainer>
		</Section>
	);
};

export default WorkSteps;
