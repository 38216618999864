export const getPlatformFromUrl = (url) => {
	try {
		const { hostname } = new URL(url); // Extract the hostname (e.g., "www.instagram.com")
		const parts = hostname.split("."); // Split by dots
		// Return the platform name (the part before ".com")
		return parts[parts.length - 2]; // e.g., "instagram" from "www.instagram.com"
	} catch (error) {
		console.error("Invalid URL:", url, error);
		return null;
	}
};

// Normalize payment type to match keys in the payments object
export const normalizePaymentType = (paymentType) => {
	if (!paymentType) return null;
	return paymentType.toLowerCase().trim(); // Convert to lowercase and remove whitespace
};
